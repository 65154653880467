import { FaChevronRight, FaQuestionCircle } from "react-icons/fa";
import { useState } from "react";
import { Nav, Button, Collapse, Image } from "react-bootstrap";
// import { useLocation, useHistory } from "react-router-dom";
import { Constants } from "../../utils/constants";
import logo from "../../assets/img/spacia/web/spacia_logo_new.png";
import { useLocation, useHistory, NavLink } from "react-router-dom";

export const SideNav = (props) => {
    //setStates to handle dropdown
    const [open, setOpen] = useState(true);
    const location = useLocation();
    const history = useHistory();

    const gotToHome = () => {
        history.push("/home");
    };

    const renderMenus = () => {
        return props.currentUserRole
            ? Constants.getMenusByRole(props.currentUserRole).map((e, i) => {
                  return e.children ? (
                      <div key={i} className={"parent-menu-item"}>
                          <Button
                              onClick={() => setOpen(!open)}
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                              variant="link"
                              className="p-0 d-flex align-items-center"
                          >
                              <img
                                  src={`../../assets/img/Web/Spacia/${e.icon}`}
                                  alt={e.route}
                              />{" "}
                              &nbsp;&nbsp; {e.name} &nbsp; &nbsp;
                              <FaChevronRight className="float-end" />
                          </Button>
                          <Collapse
                              in={
                                  open ||
                                  e.children.some(
                                      (subMenu) =>
                                          subMenu.route === location.pathname
                                  )
                              }
                          >
                              <Nav
                                  defaultActiveKey={location.pathname}
                                  className="flex-column sidebar-sub-menu pl-4 my-0 pr-0"
                              >
                                  {e.children.map((d, index) => (
                                      <NavLink
                                          to={d.route}
                                          key={index}
                                          className="pl-3 my-0 pr-0"
                                          activeClassName="active"
                                      >
                                          {d.name}
                                      </NavLink>
                                  ))}
                              </Nav>
                          </Collapse>
                      </div>
                  ) : e.route === "/startBooking" ? (
                      <NavLink
                          key={i}
                          to={e.route}
                          onClick={() => setOpen(false)}
						  className="nav-link"
                          activeClassName="active"
                      >
                          <img
                              src={`../../assets/img/Web/Spacia/${e.icon}`}
                              alt={e.route}
                              style={{
                                  marginLeft:
                                      e.route === "/startBooking"
                                          ? "-2px"
                                          : "0",
                              }}
                          />{" "}
                          &nbsp;{e.name}
                      </NavLink>
                  ) : (
                      <NavLink
                          key={i}
                          to={e.route}
                          onClick={() => setOpen(false)}
						  className="nav-link"
                          activeClassName="active"
                      >
                          <img
                              src={`../../assets/img/Web/Spacia/${e.icon}`}
                              alt={e.route}
                          />{" "}
                          &nbsp; {e.name}
                      </NavLink>
                  );
              })
            : "";
    };

    return (
        <div className="sideNav px-4 min-vh-100 position-fixed">
            <figure
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "50px",
                }}
            >
                <Image
                    src={logo}
                    fluid
                    className="mt-5 mb-4"
                    alt="LOGO"
                    width="102"
                    style={{ cursor: "pointer" }}
                    onClick={() => gotToHome()}
                />
            </figure>

            <div
                className="position-relative"
                style={{ height: "40vh", overflowY: "scroll" , paddingRight: "10px"}}
            >
                <Nav
                    defaultActiveKey={location.pathname}
                    className="flex-column"
                >
                    {renderMenus()}
                </Nav>
            </div>

            {/*<div className="navItem fixedBottom">*/}
            {/*	<FaQuestionCircle color="#FFF" />*/}
            {/*</div>*/}
        </div>
    );
};
