import React, { useEffect, useRef, useState } from "react";
import { Constants } from "../../../../../utils/constants";
import axios from "axios";
import SERVICES from "../../../../../services";
import { Modal, Row, Spinner } from "react-bootstrap";
import { PageTitle } from "../../../../../layout/PageData";
import noAvatar from '../../../../../assets/img/png/no-avatar.png';
import { useHistory, useLocation } from "react-router-dom";
import closeButton from "../../../../../assets/img/svg/blue-close-button.svg";
import successImage from "../../../../../assets/img/svg/success-white-background.svg";
import warningImage from "../../../../../assets/img/svg/red-warning.svg";
import apiService from "../../../../../services/apiService";
import { toast } from 'react-toastify';

const ProfileEdit = () => {
  const history = useHistory();

  const input = {
    backgroundColor: "#ECECEC",
    border: "1px solid #DDDDDD",
  };

  //  const roles = [
  //    {
  //      value: 'ROLE_SUBSCRIBER_INDIVIDUAL',
  //      label: 'Employee',
  //      owner: 'ROLE_SUBSCRIBER_OWNER'
  //    },
  //    {
  //      value: 'ROLE_SUBSCRIBER_APPROVER',
  //      label: 'Approver',
  //      owner: 'ROLE_SUBSCRIBER_OWNER'
  //    },
  //    {
  //      value: 'ROLE_HOST_OPERATIONS',
  //      label: 'Operations',
  //      owner: 'ROLE_HOST'
  //    }
  //  ]

  const inputFile = useRef(null);

  const [user, setUser] = useState({});
  const [fullName, setFullName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [url, setUrl] = useState(noAvatar);
  const [loading, setLoading] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState('');

  const location = useLocation();

  const roles = (userRole === 'ROLE_SUBSCRIBER_OWNER' || userRole === 'ROLE_HOST') ?
    [
      {
        value: userRole,
        label: 'ACCOUNT OWNER',
        owner: userRole
      }
    ] :
    [
      {
        value: 'ROLE_EMPLOYEE',
        label: 'Employee',
        owner: 'ROLE_SUBSCRIBER_OWNER'
      },
      {
        value: 'ROLE_APPROVER_EMPLOYEE',
        label: 'Administrator',
        owner: 'ROLE_SUBSCRIBER_OWNER'
      },
      {
        value: 'ROLE_HOST_OPERATIONS',
        label: 'Operations',
        owner: 'ROLE_HOST'
      }
    ];

  useEffect(() => {
    const locationState = location.state;
    console.log('location state:', locationState);

    setUser(locationState);

    const currentUser = SERVICES.getUser();
    const role = currentUser?.role;

    setCurrentUserRole(role);
  }, []);

  useEffect(() => {
    setFullName(extractFullName(user));

    const email = user?.username;
    setEmail(email || '');

    const contacts = user?.contacts;
    // find the first mobile contact type
    let mobile = '';
    if (contacts != null && Array.isArray(contacts)) {
      const firstMobileContactType = contacts.find(contact => contact.type === 'mobile');
      mobile = firstMobileContactType ? firstMobileContactType.value : '';
    }

    setMobile(mobile);

    const avatar = user?.avatar;
    const updatedAvatar = avatar || noAvatar;
    setUrl(updatedAvatar);

    const role = user?.role;
    setUserRole(role);
  }, [user]);

  function extractFullName(user) {
    let fullName = '';

    const firstName = user?.firstName;
    const lastName = user?.lastName;

    if (firstName && lastName) {
      fullName = `${firstName} ${lastName}`;
    } else if (firstName) {
      fullName = firstName;
    } else if (lastName) {
      fullName = lastName;
    }

    return fullName;
  }

  const updateUser = () => {
    setLoading(true);

    const id = user ? user.id : 0;
    const [firstName, ...restOfName] = fullName.split(' ');

    let lastName = undefined;
    if (restOfName && Array.isArray(restOfName)) {
      lastName = restOfName.join(" ");
    } else if (restOfName && typeof restOfName === 'string') {
      lastName = restOfName;
    }

    const userData = {
      avatar: url,
      role: userRole,
      contacts: [
        {
          isContactable: true,
          isPrimary: true,
          type: "mobile",
          value: mobile
        }
      ],
      firstName: (!firstName) ? null : firstName,
      lastName: (!lastName) ? null : lastName
    };

    apiService.updateUser(id, userData)
      .then(res => {
        const response = res.data;
        const [updatedUser, ...rest] = response ? response.data : [];

        // Store updated user data
        SERVICES.saveUser(updatedUser);
        
        toast.success('Profile updated successfully', {
          position: "top-right",
          theme: "colored"
        });

        // Navigate back to users page
        moveToUsersPage();
      })
      .catch(err => {
        const errorResponse = err.response;
        const data = errorResponse?.data;
        const errors = data?.errors;
        
        let errorMessage = 'Failed to update profile. Please try again.';
        if (errors && Array.isArray(errors) && errors.length > 0) {
          errorMessage = errors[0].message;
        }

        // Show toast notification instead of modal
        toast.error(errorMessage, {
          position: "top-right",
          theme: "colored"
        });

        // Keep the existing user data
        const currentUser = SERVICES.getUser();
        if (currentUser) {
          SERVICES.saveUser(currentUser);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileUpload = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const file = event.target.files[0];
    console.log(file);

    if (window.FileReader) {
      let reader = new FileReader();
      reader.onload = function (r) {
        setUrl(r.target.result);
      };
      reader.readAsDataURL(file);
    }
    else {
      console.log(`Browser doesn't support file reader`);
    }
  };

  const moveToUsersPage = () => {
    history.push('/user-management');
  };

  return (
    <div className='mobile-view' style={{ background: "#FCFCFC", color: '#333333' }}>
      <div className="section mt-2 p-3">
        <PageTitle>Users</PageTitle>
      </div>
      <div style={{ textAlign: "center", padding: 30, paddingTop: 10 }}>
        <section className={'text-left'}>
          <p className={'mb-3'} style={{ fontSize: '1.5rem' }}>Edit User</p>
          <figure
            onClick={() => inputFile.current.click()}
            style={{
              width: '141px',
              height: '130px',
              background: '#F7F7F7',
              border: '2px solid #017BEF',
              borderRadius: '5px',
              display: (url) ? 'block' : 'flex',
              justifyContent: (url) ? 'normal' : 'center',
              alignItems: (url) ? 'normal' : 'center'
            }}>
            <img
              src={url}
              alt="profile image"
              className={'img-fluid'}
              style={{ width: (url) ? '100%' : '80%', height: (url) ? '100%' : '85%', objectFit: 'cover' }}
            />
          </figure>
          <input type="file" id='file' ref={inputFile} style={{ display: 'none' }} accept={'image/*'} onChange={handleFileUpload} />
        </section>

        <br />
        <div className="form-group">
          {/* <label for="">Email</label> */}
          <input
            style={input}
            type="name"
            className="form-control"
            name=""
            id=""
            aria-describedby="emailHelpId"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <br />
          <input
            style={input}
            type="email"
            className="form-control"
            readOnly
            aria-describedby="emailHelpId"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* <label for="">Role</label> */}
          <br />
          <input
            style={input}
            type="text"
            className="form-control"
            aria-describedby="emailHelpId"
            placeholder="Phone Number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <br />
          <Row style={{ width: '60%' }}>
            <div className="form-group col-md-12">
              <select
                className="form-select"
                value={userRole} onChange={e => setUserRole(e.target.value)}
                style={{ background: 'rgb(236, 236, 236)', border: '1px solid rgb(221, 221, 221)' }}
              >
                <option selected defaultValue={'' || null || undefined} disabled>Select Role</option>
                {
                  roles.filter(role => role.owner === currentUserRole).map(role => {
                    return (
                      <option value={role.value}>{role.label}</option>
                    );
                  })
                }
              </select>
            </div>
          </Row>
        </div>
        <div className={'text-center'} style={{ marginTop: '4rem' }}>
          <button
            className={'btn text-black text-capitalize'}
            onClick={moveToUsersPage}
            style={{ cursor: 'pointer', marginRight: '25px', width: '110px', background: '#F7F7F7', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px' }}
          >
            cancel
          </button>
          <button
            className={'btn btn-primary text-white text-capitalize'}
            style={{ cursor: 'pointer', width: '110px', boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.4)', borderRadius: '15px' }}
            onClick={updateUser}
          >
            save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;