// @ts-nocheck

/**
 * Project: spaces
 * File: SearchResults
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import React, { useEffect, useState } from 'react';
import Search from "../../components/Search";
import { PageTitle } from "../../layout/PageData";
import { useDispatch, useSelector } from "react-redux";
import SERVICES from "../../services";
import { saveCartDetails, saveSelectedFilters, setHasItems } from "../../redux/actions/dashboard";
import FilterTableRow from "../../components/FilterTableRow";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import {
    FilterOptions,
    Listing,
    Location,
    PropertyType,
    SelectedFilters,
    SubCategoryType,
    Zone
} from "../../utils/interfaces";
import imgplaceholder from "../../assets/img/Web/Spacia/imgplaceholder.png";
import Pagination from "../../components/Pagination";
import { Spinner } from "react-bootstrap";
import classnames from "classnames";
import { SearchOutline } from "react-ionicons";
import Utils from "../../utils";
import Autocomplete from "react-autocomplete";
import apiService from "../../services/apiService";

const SearchResult: React.FC = (props) => {
    // @ts-ignore
    const selectedFilters = useSelector(state => state.dashboard.selectedFilters); // all user selected filters
    // @ts-ignore
    const filters = useSelector(state => state.dashboard.filterOptions); // all filter options received from api
    // @ts-ignore
    const cartDetails = useSelector(state => state.dashboard.cartDetails);

    const [formModal, setformModal] = useState(false);

    const userCurrency = SERVICES.getCurrency();

    /* Define all states for the FilterProperties component
    *  before doing any other business */

    const [beds, setBeds] = useState("");
    const [baths, setBaths] = useState("");
    const [info, setInfo] = useState("");
    const [status, setStatus] = useState("Pending");
    const [price, setPrice] = useState('');

    const [propertyType, setPropertyType] = useState({} as PropertyType); // property type filter
    const [zone, setZone] = useState({} as Zone); // zone filter
    const [location, setLocation] = useState({} as Location); // location filter

    const [allZones, setAllZones] = useState([]); // sets all supported zones received from api
    const [supportedZones, setSupportedZones] = useState([]);
    const [allLocations, setAllLocations] = useState([]); // sets all locations received from api

    const [capacity, setCapacity] = useState(1);
    const [maxPrice, setMaxPrice] = useState(selectedFilters.cost);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [properties, setProperties] = useState([] as Array<Listing>);
    const [startDate, setStartDate] = useState(selectedFilters.from);
    const [endDate, setEndDate] = useState(selectedFilters.to);

    const [optionSelected, setOptionSelected] = useState(null);
    const [workSpaceTypes, setWorkSpaceTypes] = useState([]);
    const [workspaceType, setWorkspaceType] = useState(null);
    const [amenitiesTypes, setAmenitiesTypes] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState(null);
    const [allSelectedFiltersForSession, setAllSelectedFiltersForSession] = useState({} as SelectedFilters);
    const [amenitiesClicked, setAmenitiesClicked] = useState(false);

    let [searchValue, setSearchValue] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [locationAddressList, setLocationAddressList] = useState([]);
    const [locationCityList, setLocationCityList] = useState([]);
    const [locationCountryList, setLocationCountryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [combinedLocationsMatch, setCombinedLocationsMatch] = useState([]);
    const [selectedLocationAndMatch, setSelectedLocationAndMatch] = useState('');

    const [errorMsg, setErrorMsg] = useState('');

    // initialize all filter states
    useEffect(() => {
        // set property type with selected property filter
        setPropertyType(selectedFilters.propertyType);
        // set workspace type with selected workspace filter
        setWorkspaceType(selectedFilters.workspaceType);
        // set maximum price
        setMaxPrice(selectedFilters.cost);
        // set capacity
        setCapacity(selectedFilters.capacity);

        setSearchValue(selectedFilters.locationDisplayValue);

        setSelectedLocationAndMatch(selectedFilters.location);

        // set booking start date
        setStartDate(selectedFilters.from);
        // set booking end date
        setEndDate(selectedFilters.to);

        // populate property type filter
        setPropertyTypes(filters['propertyTypes']);

        // populate location filter
        setAllLocations(filters['location']);

        setAllSelectedFiltersForSession(selectedFilters);

        console.log('selected filters:', selectedFilters);
    }, []);

    const propertySearchFunction = () => {
        console.log('selected filters are:', selectedFilters);
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        let selectedLocationFromFilter = selectedFilters.location;
        console.log('selected location inside property search function:', selectedLocationFromFilter);

        const splitLocationAndMatch = (selectedLocationFromFilter != null) ? selectedLocationFromFilter.split('~') : null;
        let match = null, selectedLocation = null;

        if (splitLocationAndMatch != null && selectedFilters.locationDisplayValue != null && selectedFilters.locationDisplayValue.trim().length > 0) {
            match = splitLocationAndMatch[0];
            selectedLocation = splitLocationAndMatch[1];
        }

        // Build payload with actual selected values
        const payload = {
            cost: selectedFilters.cost || null,
            capacity: selectedFilters.capacity || 1,
            location: (match) ? { match, selectedLocation } : null,
            propertyType: selectedFilters.propertyType || null,
            subCategory: selectedFilters.workspaceType || null,
            amenities: selectedFilters.selectedAmenities || [],
            start: selectedFilters.from || null,
            end: selectedFilters.to || null,
            zone: selectedFilters.zone || null
        };

        apiService.searchListings(payload, { userId })
            .then(res => {
                if (res.status === 200) {
                    setRequestCompleted(true);
                    const searchBasedOnFilters = res.data['data'];

                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);

                    setProperties((searchBasedOnFilters) ? searchBasedOnFilters : []);
                    console.log(res.data.data);
                } else {
                    setRequestCompleted(true);
                    setProperties([]);
                }
            }).catch(err => {
                setRequestCompleted(true);
                setProperties([]);
                console.log(err);
            });
    };

    // make a search with user selected filters immediately the page loads
    useEffect(() => {
        // invoke property search function
        propertySearchFunction();
    }, []);

    // a function that saves all selected filters for use later
    const dispatchFilters = () => {
        const selectedFilters = {
            cost: maxPrice,
            location: selectedLocationAndMatch,
            locationDisplayValue: searchValue,
            propertyType,
            workspaceType,
            selectedAmenities,
            capacity,
            from: startDate,
            to: endDate
        };

        console.log('selected filters here:', selectedFilters);

        // dispatch the selected filters with redux
        dispatch(saveSelectedFilters(selectedFilters));
    };

    useEffect(() => {
        const selectedFilters = {
            cost: maxPrice,
            // location,
            location: selectedLocationAndMatch,
            locationDisplayValue: searchValue,
            propertyType,
            workspaceType,
            capacity,
            selectedAmenities,
            zone,
            from: startDate,
            to: endDate
        };

        // @ts-ignore
        setAllSelectedFiltersForSession(selectedFilters);
    },
        [
            maxPrice,
            selectedLocationAndMatch,
            searchValue,
            propertyType,
            workspaceType,
            selectedAmenities,
            capacity,
            zone,
            startDate,
            endDate]);

    useEffect(() => {
        console.log('the selected filter for property type:', propertyType);
        // setImage(ModalProp.media && ModalProp.media.images[0] ?
        //     ModalProp.media.images[0].resourceUrl :
        //     imgplaceholder )

    }, [propertyType]);

    const changeImage = (e: any) => {
        console.log(e.target.src);
        setImage(e.target.src);
    };
    const [viewModal, setViewModal] = useState(false);

    const [ModalProp, setModalProp] = useState({
        "id": 93,
        "name": "Just added",
        "description": "string",
        "amenities": [
            { "label": "string" }
        ],
        "propertyPrice": {
            "id": 96,
            "price": 3000.0,
            "billingPeriod": "HOURLY",
            "createdOn": "2021-07-08T00:29:24.044Z",
            "updatedOn": "2021-07-08T00:29:24.090Z"
        },
        "location": {
            "id": 95,
            "latitude": "12345",
            "longitude": "-12345",
            "street": "50 3rd Road",
            "address": "50 3rd Road",
            "city": "Accra",
            "country": "gh"
        },
        "tags": [
            "string"
        ],
        "listingDetails": {
            "id": 94,
            "propertySize": 2,
            "floorNumber": "string",
            "capacity": 50,
            "listingId": 93
        },
        "status": {
            "ordinal": 1,
            "value": "unpublished"
        },
        "propertyStatus": {
            "ordinal": 0,
            "value": "FOR_RENT",
            "label": "FOR RENT"
        },
        "createdBy": {
            "id": 2,
            "username": "nicholas.akorful470@gmail.com",
            "firstName": "Nick",
            "lastName": "Lynx",
            "name": null,
            "userRole": "ROLE_CONTENT_PUBLISHER",
            "isEnabled": false,
            "createdOn": "2021-07-05T10:41:30.509Z",
            "updatedOn": "2021-07-08T00:29:24.232Z"
        },
        "createdOn": "2021-07-08T00:29:24.014Z",
        "updatedOn": "2021-07-08T00:29:24.014Z"
    });

    const [image, setImage] = useState(null);

    const [successAlert, setSuccessAlert] = useState(false);
    const [errorAlert, seterrorAlert] = useState(false);

    const addToCart = (listingId: number, amount: number) => {
        setViewModal(false);

        console.log('amount:', amount);
        console.log('listing id:', listingId);

        // calculate sub total
        const subTotal = amount * 1; // 1 because quantity is 1 for now

        const currentUser = SERVICES.getUser();
        const id = currentUser ? currentUser.id : 0;

        // find out if there is already an existing cart
        const cartId = (cartDetails) ? cartDetails['cartId'] : null;

        const from = selectedFilters.from || '';
        const to = selectedFilters.to || '';

        const payload = {
            endOn: to,
            itemPrice: amount,
            listingId,
            quantity: 1,
            startOn: from,
            subTotal,
            userId: id
        };

        apiService.addToCart(payload, { cartId })
            .then((res) => {
                console.log(res.data.data[0]);
                setSuccessAlert(true);

                if (res.status === 200) {
                    console.log('done adding to cart. response:', res.data.data[0]);
                    const cartDetails = res.data.data[0];
                    dispatch(saveCartDetails(cartDetails));
                    dispatch(setHasItems(cartDetails !== null));
                } else {
                    console.warn('an error occurred while adding item to cart');
                    seterrorAlert(true);
                }
            }).catch(err => {
                const statusCode = err.response.status;

                switch (statusCode) {
                    case 400:
                        // the may be an issue with the booking start and end date
                        console.log('status code 400:', err.response);

                        let responseMsg = err.response.data.message;
                        responseMsg = responseMsg.slice(responseMsg.indexOf(':') + 2);

                        console.log(responseMsg);

                        seterrorAlert(true);
                        setErrorMsg(responseMsg);
                        break;
                }
            });
    };

    const closeViewModal = () => {
        setViewModal(false);
    };

    const closeDeleteModal = () => {
        setshowDeleteModal(false);
    };

    const closeFormModal = () => {
        setformModal(false);
    };

    const fit = {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: 20
    };

    const [showDeleteModal, setshowDeleteModal] = useState(false);

    useEffect(() => {
        console.log('Inside filter properties');
        console.log(selectedFilters);
    }, []);

    const formatLocation = (location: Location) => {
        const city = location && location.city;
        const country = location && location.country;

        let label = (country) ? country.label : '';
        label = label.charAt(0).toUpperCase() + label.slice(1);

        return `${city}, ${label.toUpperCase()}`;
    };

    const dispatch = useDispatch();

    const handleStartDateChange = (e: any) => {
        console.log(e.target.value);

        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e: any) => {
        console.log(e.target.value);
        setEndDate(e.target.value);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageSize, setTotalPageSize] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);

    const pageCount = Math.ceil(totalPageSize / itemsPerPage);

    const searchForProperties = () => {
        console.log('selected filters when search button is clicked:', allSelectedFiltersForSession);

        // save all selected filters for later use
        dispatchFilters();

        setRequestCompleted(false); // display spinner
        setProperties([]);

        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        let selectedLocationFromFilter = allSelectedFiltersForSession.location;
        console.log('selected location inside property search function:', selectedLocationFromFilter);

        const splitLocationAndMatch = (selectedLocationFromFilter != null) ? selectedLocationFromFilter.split('~') : null;
        let match = null, selectedLocation = null;

        if (splitLocationAndMatch != null) {
            match = splitLocationAndMatch[0];
            selectedLocation = splitLocationAndMatch[1];
        }

        const propertyType = allSelectedFiltersForSession.propertyType;
        const subCategory = allSelectedFiltersForSession.workspaceType;
        const maxPrice = allSelectedFiltersForSession.cost;
        const amenities = allSelectedFiltersForSession.selectedAmenities;
        const zoneFromSelectedFilters = allSelectedFiltersForSession.zone;
        const start = allSelectedFiltersForSession.from, end = allSelectedFiltersForSession.to;
        const capacity = allSelectedFiltersForSession.capacity;

        let zone = (zoneFromSelectedFilters != null && Object.keys(zoneFromSelectedFilters).length === 0) ? null : zoneFromSelectedFilters;

        const payload = {
            cost: maxPrice,
            capacity,
            location: { match, selectedLocation },
            propertyType,
            subCategory,
            amenities,
            start,
            end,
            zone
        };

        apiService.searchListings(payload, { userId })
            .then(res => {
                if (res.status === 200) {
                    setRequestCompleted(true);
                    const searchBasedOnFilters = res.data['data'];

                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);

                    setProperties((searchBasedOnFilters) ? searchBasedOnFilters : []);
                    console.log(res.data.data);
                } else {
                    setRequestCompleted(true);
                    setProperties([]);
                }
            }).catch(err => {
                setRequestCompleted(true);
                setProperties([]);
                console.log(err);
            });
    };

    const changePage = (selected: number) => {
        setRequestCompleted(false);

        console.log('selected filters when moving through pagination:', allSelectedFiltersForSession);

        setProperties([]);

        console.log('selected page number is', selected);
        setCurrentPage(selected);

        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        const searchUrl = `${Constants.BASE_URL}/booking/api/v1/listings/search`;

        // let selectedLocation = allSelectedFiltersForSession.location;
        //
        // let splitLocation = selectedLocation ? selectedLocation.split(',') : null;
        //
        // let city = null, country = null;
        // if (splitLocation) {
        //     city = splitLocation[0];
        //     country = splitLocation[1];
        // }

        let selectedLocationFromFilter = allSelectedFiltersForSession.location;
        console.log('selected location inside property search function:', selectedLocationFromFilter);

        const splitLocationAndMatch = (selectedLocationFromFilter != null) ? selectedLocationFromFilter.split('~') : null;
        let match = null, selectedLocation = null;

        if (splitLocationAndMatch != null && allSelectedFiltersForSession.locationDisplayValue != null && allSelectedFiltersForSession.locationDisplayValue.trim().length > 0) {
            match = splitLocationAndMatch[0];
            selectedLocation = splitLocationAndMatch[1];
        }

        const propertyType = allSelectedFiltersForSession.propertyType;
        const subCategory = allSelectedFiltersForSession.workspaceType;
        const maxPrice = allSelectedFiltersForSession.cost;
        const amenities = allSelectedFiltersForSession.selectedAmenities;
        const zoneFromSelectedFilters = allSelectedFiltersForSession.zone;
        const start = allSelectedFiltersForSession.from, end = allSelectedFiltersForSession.to;
        const capacity = allSelectedFiltersForSession.capacity;

        let zone = (zoneFromSelectedFilters != null && Object.keys(zoneFromSelectedFilters).length === 0) ? null : zoneFromSelectedFilters;

        const payload = {
            cost: maxPrice,
            capacity,
            location: { match, selectedLocation },
            propertyType,
            subCategory,
            amenities,
            start,
            end,
            zone
        };

        apiService.searchListings(payload, { page: (selected - 1), userId })
            .then(res => {
                if (res.status === 200) {
                    setRequestCompleted(true);
                    const searchBasedOnFilters = res.data['data'];

                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);

                    setProperties((searchBasedOnFilters) ? searchBasedOnFilters : []);
                    console.log(res.data.data);
                } else {
                    setRequestCompleted(true);
                    setProperties([]);
                }
            }).catch(err => (console.log(err)));
    };

    const makeSearchOnKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            searchForProperties();
        }
    };

    // get all supported zones
    useEffect(() => {
        apiService.getSupportedZones()
            .then(res => {
                console.log('supported zones:', res.data);
                const responseData = res.data.data;
                setAllZones(responseData);
            });
    }, []);

    useEffect(() => {
        console.log('option selected:', optionSelected);
    }, [optionSelected]);

    const performSearch = (e: any) => {
        e.preventDefault();
    };

    const [doneCombiningLocations, setDoneCombiningLocations] = useState(false);
    useEffect(() => {
        setDoneCombiningLocations(false);

        const addressesInLocation = locationList.filter(location => location.match === 'ADDRESS')
            .map(location => {
                const { address, city, country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${address}, ${city}, ${countryName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct locations based on the address
        const uniqueAddressesInLocation = [...new Map(addressesInLocation.map((location) => [location["address"], location])).values()];

        const citiesInLocation = locationList.filter(location => location.match === 'CITY')
            .map(location => {
                const { city, country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${city}, ${countryName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct locations based on the city
        const uniqueCitiesInLocation = [...new Map(citiesInLocation.map((location) => [location["city"], location])).values()];

        const countriesInLocation = locationList.filter(location => location.match === 'COUNTRY')
            .map(location => {
                const { country } = location;
                const countryName = country != null ? Utils.convertFirstLetterOfEachWordToUpperCase(country.label) : '';

                const displayValue = `${countryName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct locations based on the country
        const uniqueCountriesInLocation = [...new Map(countriesInLocation.map((location) => {
            const country = location["country"];
            const label = country != null ? country.label : '';

            return [label, location];
        })).values()];

        const companyNames = locationList.filter(location => location.match === 'COMPANY_NAME')
            .map(location => {
                const { name: companyName } = location;

                const displayValue = `${companyName}`;

                return {
                    displayValue,
                    ...location
                };
            });

        // get distinct companies based on the name
        const uniqueCompanyNames = [...new Map(companyNames.map((location) => [location["name"], location])).values()];

        setLocationAddressList(uniqueAddressesInLocation);
        setLocationCityList(uniqueCitiesInLocation);
        setLocationCountryList(uniqueCountriesInLocation);
        setCompanyList(uniqueCompanyNames);

        console.log('location address list:', uniqueAddressesInLocation);
        console.log('location city list:', uniqueCitiesInLocation);
        console.log('location country list:', uniqueCountriesInLocation);
        console.log('company list:', uniqueCompanyNames);

        console.log('did I reach here?');
        setDoneCombiningLocations(true);
    }, [locationList]);

    useEffect(() => {
        console.log('done combining locations:', doneCombiningLocations);
        if (doneCombiningLocations) {
            setCombinedLocationsMatch([...locationAddressList, ...locationCityList, ...locationCountryList, ...companyList]);
        }
    }, [doneCombiningLocations]);

    const buildListOfLocations = (locationSearchResponse) => {
        if (locationSearchResponse != null) {
            let allLocations = [];

            for (const locationSearchResponseKey in locationSearchResponse) {
                console.log('location search key:', locationSearchResponseKey);

                const locations = locationSearchResponse[locationSearchResponseKey]; // array of location objects

                // go through each location and add the match type
                const locationsUpdate = locations.map(location => ({ match: locationSearchResponseKey, ...location }));
                console.log('locations update:', locationsUpdate);

                allLocations.push(...locationsUpdate);
            }

            console.log('all locations sigh:', allLocations);
            setLocationList(allLocations);
        }
    };

    const makeSearchForLocation = () => {
        setDoneCombiningLocations(false);

        if (searchValue) {
            apiService.searchLocations(searchValue)
                .then(res => {
                    const response = res.data;
                    const responseData = response?.data;

                    buildListOfLocations(responseData);
                }).catch(err => {
                    // handle exception
                });
        }
    };

    useEffect(() => {
        makeSearchForLocation();
    }, [searchValue]);

    useEffect(() => {
        if (!searchValue) {
            setCombinedLocationsMatch([]);
        }
    }, [searchValue]);

    const [requestCompleted, setRequestCompleted] = useState(false);

    const componentToRender = () => {
        if (requestCompleted && Array.isArray(properties) && properties.length > 0) {
            return (
                <div>
                    {
                        properties.map(listing => {
                            return (
                                <Search
                                    key={uuidv4()}
                                    listing={listing}
                                />
                            );
                        })
                    }
                </div>
            );
        } else if (requestCompleted && Array.isArray(properties) && properties.length === 0) {
            return (
                <div className='perfect-center'>
                    <p style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>No Items Found</p>
                </div>
            );
        } else if (!requestCompleted) {
            return (
                <div className='perfect-center'>
                    <Spinner size="sm" animation="border" role="status" style={{ width: '50px', height: '50px' }}>
                        <span className="visually-hidden"></span>
                    </Spinner>{" "}
                </div>
            );
        }
    };

    return (
        <div className='mobile-view with-spinner' style={{ background: "#FCFCFC" }}>
            <PageTitle>Search Results</PageTitle>
            <div className='mt-4 mx-auto' style={{ width: '95%' }}>
                <form className="search-form" style={{ height: '60px' }} onSubmit={performSearch}>
                    <Autocomplete
                        getItemValue={(location) => {
                            const { match, address, city, country, name: companyName, displayValue } = location;

                            let value = '';
                            if (match != null) {
                                switch (match) {
                                    case 'ADDRESS':
                                        value = `${match}~${address}~${displayValue}`;
                                        break;

                                    case 'CITY':
                                        value = `${match}~${city}~${displayValue}`;
                                        break;

                                    case 'COUNTRY':
                                        const initial = country != null ? country.value : '';
                                        value = `${match}~${initial}~${displayValue}`;
                                        break;

                                    case 'COMPANY_NAME':
                                        value = `${match}~${companyName}~${displayValue}`;
                                }
                            }

                            return value;
                        }}
                        items={combinedLocationsMatch}
                        renderInput={(props) => (
                            <div className="form-group searchbox h-100">
                                <input
                                    {...props}
                                    type="text"
                                    className="form-control h-100"
                                    placeholder="Search for location and press Enter..."
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyPress={makeSearchOnKeyPress}
                                />
                                <i className="input-icon">
                                    <SearchOutline
                                    />
                                </i>
                            </div>
                        )}
                        renderItem={(location, isHighlighted) =>
                            <div style={{ borderBottom: '1px solid lightgray', padding: '15px', cursor: 'pointer', background: isHighlighted ? 'lightgray' : 'white' }}>
                                {location.displayValue}
                            </div>
                        }
                        menuStyle={{
                            borderRadius: '3px',
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                            background: 'rgba(255, 255, 255, 0.9)',
                            padding: '2px 0',
                            fontSize: '90%',
                            position: 'fixed',
                            zIndex: '3',
                            width: '30%',
                            overflow: 'auto',
                            maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                        }}
                        wrapperStyle={{ height: '100%' }}
                        value={searchValue}
                        onSelect={(value) => {
                            const firstIndex = value.indexOf('~');
                            const lastIndex = value.indexOf('~', firstIndex + 1);
                            const displayValue = value.slice(lastIndex + 1);

                            console.log('selected value:', displayValue);

                            const selected = value.slice(0, lastIndex);
                            console.log('full string value:', selected);

                            const initialportion = value.slice(firstIndex + 1, lastIndex);
                            console.log('initial portion:', initialportion);
                            //setSearchValue(displayValue);
                            setSearchValue(initialportion);
                            setSelectedLocationAndMatch(selected);
                        }}
                    />


                    {/*<div className="form-group searchbox">*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        className="form-control"*/}
                    {/*        placeholder={'Search Properties'}*/}
                    {/*        value={searchValue}*/}
                    {/*        onChange={e => setSearchValue(e.target.value)}*/}
                    {/*    />*/}
                    {/*    <i className="input-icon">*/}
                    {/*        <SearchOutline*/}
                    {/*        />*/}
                    {/*    </i>*/}
                    {/*</div>*/}
                </form>
            </div>
            <div className={classnames('section p-3', { 'mt-5': (requestCompleted && Array.isArray(properties) && properties.length > 0) })}>
                {/*<div className="section mt-5 p-3">*/}
                {
                    componentToRender()
                }
                <div style={{ paddingBottom: '10px' }}>
                    {
                        Array.isArray(properties) && properties.length > 0 &&
                        <div className='mobile-pagination'>
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={totalPageSize}
                                pageSize={itemsPerPage}
                                onPageChange={changePage}
                            />
                        </div>
                    }
                </div>
                {/*{Items.map(({name, location, image, amount},key)=><Search key={key.toString()} name={name} location={location} amount={amount} image={image} />)}*/}

            </div>
        </div>
    );
};
export default SearchResult;
