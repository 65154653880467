// @ts-nocheck

/**
 * Project: spaces
 * File: Header
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import React from "react";
import { ChevronBackOutline, MenuOutline } from "react-ionicons";
import { usePageData } from "../PageData";
import { useHistory } from 'react-router-dom';
import * as bootstrap from 'bootstrap';

export function Header() {
    const history = useHistory();
    const { pageTitle, leftComponent, rightComponent } = usePageData();

    function goBack() {
        history.go(-1);
    }

    function onHomePage() {
        const location = history.location;
        const pathname = location?.pathname;

        return pathname === '/home';
    }

    function shouldShowBackButton() {
        const location = history.location;
        const pathname = location?.pathname;
        // Show back button on detail pages and search results
        const backButtonPaths = [
            '/details',
            '/search-details',
            '/booking-order-summary',
            '/search',  // search results page
            '/booking-order-summary' // booking order summary page
        ];
        return backButtonPaths.includes(pathname);
    }

    return (
        <>
            {(pageTitle || leftComponent || rightComponent) &&
                <div className="appHeader text-dark" style={{ display: (onHomePage()) ? 'none' : 'flex' }}>
                    <div className="left">
                        {shouldShowBackButton() ? (
                            <a href="#!" onClick={goBack} className="headerButton">
                                <ChevronBackOutline />
                            </a>
                        ) : (
                            <a href="#!" className="headerButton" onClick={() => {
                                const sidebarPanel = document.getElementById('sidebarPanel');
                                if (sidebarPanel) {
                                    const bsModal = new bootstrap.Modal(sidebarPanel);
                                    bsModal.show();
                                }
                            }}>
                                <MenuOutline />
                            </a>
                        )}
                    </div>
                    <div className="pageTitle">
                        {pageTitle}
                    </div>
                    <div className="right">
                        {rightComponent || null}
                    </div>
                </div>
            }
        </>
    );
}
