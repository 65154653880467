import React from "react";
import ReactDOM from "react-dom";
import { usePromiseTracker } from "react-promise-tracker";
import App from "./App";
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { Constants } from './utils/constants';
import { Buffer } from 'buffer';
window.Buffer = Buffer;

const LoadingIndicator = () => {
	const { promiseInProgress } = usePromiseTracker();
	return promiseInProgress && <h1>Hey some async call in progress!</h1>;
};

const app = initializeApp(Constants.firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

// Function to display FCM token on the page
// const displayFCMToken = (token) => {
// 	const tokenDisplay = document.createElement('div');
// 	tokenDisplay.style.position = 'fixed';
// 	tokenDisplay.style.top = '10px';
// 	tokenDisplay.style.left = '10px';
// 	tokenDisplay.style.padding = '10px';
// 	tokenDisplay.style.background = 'rgba(0,0,0,0.8)';
// 	tokenDisplay.style.color = 'white';
// 	tokenDisplay.style.zIndex = '9999';
// 	tokenDisplay.style.maxWidth = '80%';
// 	tokenDisplay.style.wordBreak = 'break-all';
// 	tokenDisplay.innerHTML = `<strong>FCM Token:</strong><br>${token}<br><button onclick="navigator.clipboard.writeText('${token}')">Copy Token</button>`;
// 	document.body.appendChild(tokenDisplay);
// };

// Request permission and get token
const requestPermissionAndGetToken = async () => {
	try {
		const permission = await Notification.requestPermission();
		if (permission === 'granted') {
			const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
			const token = await getToken(messaging, {
				vapidKey: 'BIm2bfND4GK7_4_BorI_nwdz_ivc00n7_nom7Tn3YSid7THcokJAB81-n8xoqOBgjMz7WZ1J7Guk6wR3saZiWgs',
				serviceWorkerRegistration: registration
			});
			console.log('FCM Token generated:', token); // Add this line
			// displayFCMToken(token); // Display token on the page
			// You can send this token to your server here
		} else {
			console.log('Notification permission denied');
		}
	} catch (error) {
		console.error('Error in requestPermissionAndGetToken:', error);
	}
};

requestPermissionAndGetToken();

// Add this function to handle foreground messages
const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			console.log("Received foreground message ", payload);
			resolve(payload);
		});
	});

// Use the onMessageListener
onMessageListener().then(payload => {
	console.log(payload);
	// Show notification
	new Notification(payload.notification.title, {
		body: payload.notification.body,
		icon: '/logo192.png'
	});
});

ReactDOM.render(
	<React.StrictMode>
		<App />
		<LoadingIndicator />
	</React.StrictMode>,
	document.getElementById("root"),
);
