import React, { useState, useEffect } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { TextField } from '@mui/material';
import moment from 'moment';

function EditAnnouncementModal({
    isOpen,
    isClose,
    onSubmit,
    announcement,
}) {
    const [formData, setFormData] = useState({
        id: '',
        title: '',
        message: '',
        datetimeToSend: new Date()
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (announcement) {
            setFormData({
                id: announcement.id,
                title: announcement.title || '',
                message: announcement.body || '',
                datetimeToSend: announcement.datetimeToSend ? new Date(announcement.datetimeToSend) : new Date()
            });
        }
    }, [announcement]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDateTimeChange = (newValue) => {
        setFormData(prev => ({
            ...prev,
            datetimeToSend: newValue
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        return onSubmit({  // Add return here
            id: formData.id,
            title: formData.title,
            message: formData.message,
            datetimeToSend: moment(formData.datetimeToSend).format('YYYY-MM-DDTHH:mm:ss')
        })
        .then(response => {
            if (response?.status === 200) {
                isClose();
            }
        })
        .catch(error => {
            console.error("Error in submit:", error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const input = {
        backgroundColor: "#ECECEC",
        border: "1px solid #DDDDDD",
    };

    return (
        <Modal show={isOpen} onHide={isClose}>
            <Modal.Header
                style={{
                    borderBottom: "none",
                    padding: "20px 30px 0px",
                    fontFamily: "Montserrat",
                    display: "flex",
                    justifyContent: "center", // Center the header content
                    position: "relative" // For close button positioning
                }}
                closeButton
            >
                <div className="text-primary" style={{ 
                    textAlign: "center",
                    width: "100%", // Ensure title takes full width
                    // fontWeight: "bold"
                }}>Edit Announcement</div>
            </Modal.Header>

            <div style={{ padding: 30, paddingTop: 10 }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Control
                            style={input}
                            type="text"
                            name="title"
                            placeholder="Title"
                            value={formData.title}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            style={input}
                            as="textarea"
                            name="message"
                            rows={3}
                            placeholder="Message"
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        {/* <Form.Label style={{ textAlign: "left", display: "block" }}>
                            Date and Time to Send
                        </Form.Label> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDateTimePicker
                            label="Date and Time to Send"
                                value={formData.datetimeToSend}
                                onChange={handleDateTimeChange}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        fullWidth
                                        style={{
                                            backgroundColor: "#ECECEC",
                                            borderRadius: "4px",
                                            textAlign: "left"
                                        }}
                                    />
                                }
                                minDateTime={new Date()}
                            />
                        </LocalizationProvider>
                    </Form.Group>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: 10,
                            paddingBottom: 30
                        }}
                    >
                        <button onClick={isClose} className="btn btn-outline-primary"
                            style={{
                                borderRadius: "20px",
                                marginLeft: "0px",
                                width: "8rem"
                            }}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-outline-primary"
                            style={{
                                borderRadius: "20px",
                                marginLeft: "20px",
                                width: "8rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <span>Save</span>
                            {loading && (
                                <Spinner 
                                    animation="border" 
                                    role="status" 
                                    style={{ 
                                        width: '1rem', 
                                        height: '1rem', 
                                        borderWidth: '0.1em',  // Make spinner thinner
                                        marginLeft: '10px'
                                    }}>
                                    <span className="visually-hidden"></span>
                                </Spinner>
                            )}
                        </button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
}

export default EditAnnouncementModal;
