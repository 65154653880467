/**
 * Project: spaces
 * File: Login
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import React, { FormEvent, useEffect, useLayoutEffect, useState } from "react";
import { EyeOutline } from "react-ionicons";
import { BiHelpCircle } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../layout/helpers";
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/plain.css'
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import SERVICES from "../../../../services";
import apiService from "../../../../services/apiService";
import type { GoogleAuthResponse } from "../../../../services/apiService";
import { LogoFacebook, LogoInstagram, LogoTwitter } from "react-ionicons";
import { FaRegEye as FaEye, FaRegEyeSlash as FaEyeSlash } from "react-icons/fa";
import leftArrow from '../../../../assets/img/svg/arrow-left.svg';
import { ErrorAlert } from "../../../../components/Settings/alerts/ErrorAlert";

// Import Firebase
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { getApp } from "firebase/app";

// Types
interface ApiResponse {
    data: {
        data: any[];
        message?: string;
    };
    status: number;
}

const SignUp = () => {
    const history = useHistory();

    const [errorModal, setErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [phoneValue, setPhoneValue] = useState("");
    // form
    const [firstName, setFirstName] = useState("");

    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("ROLE_HOST");
    const [confirmPasswordValidationError, setConfirmPasswordValidationError] = useState('');
    const [isEmptyFirstname, setIsEmptyFirstname] = useState(false);
    const [isEmptyLastname, setIsEmptyLastname] = useState(false);
    const [isEmptyEmail, setIsEmptyEmail] = useState(false);
    const [isEmptyPassword, setIsEmptyPassword] = useState(false);
    const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);
    const [isRoleNotSelected, setIsRoleNotSelected] = useState(false);
    const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);
    const [phoneNumberValidationError, setPhoneNumberValidationError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useLayoutEffect(() => {
        const reactPhoneInput = document.querySelector('input.PhoneInputInput') as HTMLInputElement;

        if (reactPhoneInput != null) {
            reactPhoneInput.classList.add('form-control');

            reactPhoneInput.style.fontSize = "16px";
            reactPhoneInput.style.lineHeight = "24px";
            reactPhoneInput.style.textAlign = "start";
            reactPhoneInput.style.color = "#18345E";
            reactPhoneInput.style.width = "min(464px,34vw)";
            reactPhoneInput.style.height = "50.6px";
            reactPhoneInput.style.borderRadius = "100px";
            reactPhoneInput.style.paddingLeft = "20px";
        }
    }, []);

    function hasText(text: string) {
        return text != null && text !== '';
    }

    useEffect(() => {
        if (hasText(firstName)) {
            setIsEmptyFirstname(false);
        }
        if (hasText(lastName)) {
            setIsEmptyLastname(false);
        }
        if (hasText(email)) {
            setIsEmptyEmail(false);
        }
        if (hasText(password)) {
            setIsEmptyPassword(false);
        }
        if (hasText(confirmPassword)) {
            setIsInvalidConfirmPassword(false);
        }
        if (password && !confirmPassword) {
            setIsInvalidConfirmPassword(false);
        }
        if (role) {
            setIsRoleNotSelected(false);
        }
        if (phoneValue) {
            setIsInvalidPhoneNumber(false);
        }
    }, [firstName, lastName, email, password, confirmPassword, phoneValue, role]);

    const areFieldsOkay = () => {
        const requiredFields = [firstName, lastName, email, password, confirmPassword, role];
        // is any of the fields empty
        const anyEmpty = requiredFields.some(field => !field);
        if (anyEmpty) {
            if (!hasText(firstName)) {
                setIsEmptyFirstname(true);
            }
            if (!hasText(lastName)) {
                setIsEmptyLastname(true);
            }
            if (!hasText(email)) {
                setIsEmptyEmail(true);
            }
            if (!hasText(password)) {
                setIsEmptyPassword(true);
            }
            if (!hasText(confirmPassword)) {
                setIsInvalidConfirmPassword(true);
                setConfirmPasswordValidationError('Confirm password is required');
            } else if (password && confirmPassword && confirmPassword !== password) {
                setIsInvalidConfirmPassword(true);
                setConfirmPasswordValidationError('Passwords do not match');
            }
            if (!role) {
                setIsRoleNotSelected(true);
            }
            if (!phoneValue) {
                setIsInvalidPhoneNumber(true);
                setPhoneNumberValidationError('Please enter mobile number');
            }
            // else if (phoneValue && phoneValue.length < 10) {
            // 	setIsInvalidPhoneNumber(true);
            // 	setPhoneNumberValidationError('This field must have 10 characters');
            // }

            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (password && confirmPassword && confirmPassword !== password) {
            setIsInvalidConfirmPassword(true);
            setConfirmPasswordValidationError('Passwords do not match');
        }
    }, [confirmPassword]);

    const submitSignup = async (e: FormEvent) => {
        e.preventDefault();
        if (!areFieldsOkay()) return;

        const userData = {
            avatar: "https://storage.googleapis.com/maestro-8beff.appspot.com/user-543-_1626834155761_69ec79f5_1b40_4716_9958_1a6f557f6581.jpeg",
            companyId: 1,
            confirmPassword,
            contacts: [
                {
                    isContactable: true,
                    isPrimary: true,
                    type: "mobile",
                    value: phoneValue,
                },
            ],
            firstName,
            lastName,
            password,
            username: email,
            role
        };

        apiService.signup(userData)
            .then(response => {
                if (response.status === 201) {
                    history.push("/confirm");
                    localStorage.setItem("currentUser", response.data.data[0].username);
                }
            })
            .catch(err => {
                const errors = err.response?.data?.errors || [];
                console.error(errors);
                setErrorModal(true);

                if (errors && Array.isArray(errors) && errors.length > 0) {
                    const { message } = errors[0];
                    setErrorMsg(message);
                }
            });
    };

    const getFCMToken = async () => {
        try {
            const messaging = getMessaging(getApp());
            const token = await getToken(messaging);
            console.log("FCM Token:", token);
            return token;
        } catch (error) {
            console.error("Error getting FCM token:", error);
            return null;
        }
    };

    const onLoginSuccessful = (res: GoogleAuthResponse) => {
        console.log(res.data);
        SERVICES.saveUser(res.data.data[0]);
        const role = res.data.data[0].role;

        // Redirect based on role
        role === "ROLE_ADMINISTRATOR"
            ? history.push("/")
            : history.push("/startBooking");
    };

    const onLoginError = (error: any) => {
        console.error('Login error:', error);
        setErrorModal(true);
        setErrorMsg(error.response?.data?.errors?.[0]?.message || 'An error occurred during login');
    };

    const googleSignUp = async () => {
        try {
            const auth = getAuth();
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: "select_account"
            });

            const result = await signInWithPopup(auth, provider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const accessToken = credential?.accessToken;

            if (!accessToken) {
                throw new Error("No access token received from Google");
            }

            const response = await apiService.googleAuth({
                accessToken,
                companyId: 1,
                provider: "GOOGLE"
            });

            const user = response.data.data[0];
            const fcmToken = await getFCMToken();

            if (fcmToken) {
                await apiService.updateFCMToken(user.id, fcmToken);
            }

            onLoginSuccessful(response);
        } catch (error) {
            console.error("Google sign up error:", error);
            onLoginError(error);
        }
    };

    const togglePasswordVisibility = (e: any) => {
        e.preventDefault();

        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = (e: any) => {
        e.preventDefault();

        setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        const capsule = document.querySelector("#appCapsule");

        capsule && capsule.classList.remove("add-safe-space");
    }, []);

    // @ts-ignore
    // @ts-ignore
    return (
        <div
            id="appCapsule"
            className="position-relative"
            style={
                {
                    // background: `url(${toAbsoluteUrl('/img/background.png')})`,
                    // backgroundPosition: "center",
                    // backgroundSize: "cover"
                }
            }
        >
            <div
                className="row flex-fill m-0"
                style={{ width: "100vw", height: "100vh" }}
            >
                <div
                    className="d-flex flex-column col-lg-5 col-md-6 col-sm-12 align-items-start justify-content-center align-items-end"
                    style={{ paddingRight: "min(60px,4vw)" }}
                >
                    <header className={'ms-6 mb-4'} style={{ alignSelf: 'flex-start' }}>
                        <div className={"imaged "}>
                            <img
                                alt={"back button"}
                                decoding={"async"}
                                src={leftArrow}
                                style={{ width: '30px', cursor: 'pointer' }}
                                onClick={() => history.go(-1)}
                            />
                            <img
                                alt={"logo"}
                                decoding={"async"}
                                src={toAbsoluteUrl("/assets/img/spacia/web/spacia_logo.png")}
                                style={{ width: '150px', cursor: 'pointer' }}
                                onClick={() => history.push('/home')}
                            />
                        </div>
                    </header>


                    <form onSubmit={submitSignup} className="d-flex flex-column align-items-center ">
                        <div className={'w-100'}>
                            <ErrorAlert show={errorModal} message={errorMsg || "Please try again"} close={() => setErrorModal(false)} />
                        </div>
                        <h1 className="mt-0 align-self-start mt-2">Welcome to SPACIA!</h1>
                        <div className="form-group basic py-lg-0 my-md-2 mb-lg-1">
                            <div
                                className="input-wrapper d-flex flex-column"
                                style={{
                                    width: "min(464px,34vw)",
                                    // height: "48px",
                                }}
                            >
                                <label
                                    className=""
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        letterSpacing: "0.02em",
                                        marginBottom: "8px",
                                        color: "#444150",
                                    }}
                                    htmlFor="firstName"
                                >
                                    <span> First name</span><span style={{ color: "#017BEF" }}>*</span>
                                </label>
                                <div>
                                    <input
                                        type="text"
                                        className={isEmptyFirstname ? "form-control mb-1 floating-input is-invalid" : "form-control mb-1 floating-input"}
                                        id="firstName"
                                        placeholder="First name"
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                        style={{
                                            // backgroundColor: "#F4F2F2",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            textAlign: "start",
                                            color: "#18345E",
                                            width: "min(464px,34vw)",
                                            height: "50.6px",
                                            borderRadius: "100px",
                                        }}
                                    />
                                    <div className="invalid-feedback">This field can't be empty</div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group basic mt-lg-0 py-lg-0 my-md-2 mb-lg-1">
                            <div
                                className="input-wrapper d-flex flex-column"
                                style={{
                                    width: "min(464px,34vw)",
                                    // height: "48px",
                                }}
                            >
                                <label
                                    className=""
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        letterSpacing: "0.02em",
                                        marginBottom: "8px",
                                        color: "#444150",
                                    }}
                                    htmlFor="lastName"
                                >
                                    <span> Last name</span><span style={{ color: "#017BEF" }}>*</span>
                                </label>
                                <div>
                                    <input
                                        type="text"
                                        className={isEmptyLastname ? "form-control mb-1 floating-input is-invalid" : "form-control mb-1 floating-input"}
                                        id="lastName"
                                        placeholder="Last name"
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                        style={{
                                            // backgroundColor: "#F4F2F2",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            textAlign: "start",
                                            color: "#18345E",
                                            width: "min(464px,34vw)",
                                            height: "50.6px",
                                            borderRadius: "100px",
                                        }}
                                    />
                                    <div className="invalid-feedback">This field can't be empty</div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group basic mt-lg-0 py-lg-0 my-md-2 mb-lg-1">
                            <div
                                className="input-wrapper d-flex flex-column"
                                style={{
                                    width: "min(464px,34vw)",
                                    // height: "48px",
                                }}
                            >
                                <label
                                    className=""
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        letterSpacing: "0.02em",
                                        marginBottom: "8px",
                                        color: "#444150",
                                    }}
                                    htmlFor="email"
                                >
                                    <span> Email</span><span style={{ color: "#017BEF" }}>*</span>
                                </label>
                                <div>
                                    <input
                                        type="text"
                                        className={isEmptyEmail ? "form-control mb-1 floating-input is-invalid" : "form-control mb-1 floating-input"}
                                        id="email"
                                        placeholder="Email address"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        style={{
                                            // backgroundColor: "#F4F2F2",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            textAlign: "start",
                                            color: "#18345E",
                                            width: "min(464px,34vw)",
                                            height: "50.6px",
                                            borderRadius: "100px",
                                        }}
                                    />
                                    <div className="invalid-feedback">This field can't be empty</div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group basic py-lg-0 mb-lg-1">
                            <div className="input-wrapper d-flex flex-column" style={{ width: '93%' }}>
                                <label
                                    className=""
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        letterSpacing: "0.02em",
                                        color: "#444150",
                                        marginBottom: "8px",
                                    }}
                                    htmlFor="msisdn"
                                >
                                    <span>Phone Number</span><span style={{ color: "#017BEF" }}>*</span>
                                </label>
                                {/*<div className="phone-wrapper">*/}
                                {/*<PhoneInput country={'ke'} />*/}
                                {/*</div>*/}
                                {/*@ts-ignore*/}
                                <PhoneInput
                                    defaultCountry="KE"
                                    placeholder="Enter phone number"
                                    value={phoneValue}
                                    onChange={(e) => {
                                        console.log(e);
                                        if (e != undefined) {
                                            setPhoneValue(e);
                                        }
                                    }}
                                />
                                <div style={{ display: isInvalidPhoneNumber ? 'block' : 'none', fontSize: '0.875em', marginTop: '0.25rem', color: '#dc3545' }}>{phoneNumberValidationError}</div>
                            </div>

                        </div>
                        <div className="form-group basic my-md-2 mb-lg-1 py-lg-0">
                            <div
                                className="input-wrapper"
                                style={{
                                    width: "min(464px,34vw)",
                                    // height: "48px",
                                }}
                            >
                                <label
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        letterSpacing: "0.02em",
                                        color: "#444150",
                                        marginBottom: "8px",
                                    }}
                                    className="d-flex flex-row align-items-center"
                                    htmlFor="password"
                                >
                                    <span>Password</span><span style={{ color: "#017BEF" }}>*</span>
                                    {/*<BiHelpCircle className="ms-1" style={{width:"14.76px",height:"14.76px",color:"rgba(0, 0, 0, 0.6)"}}/>*/}
                                </label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className={isEmptyPassword ? "form-control is-invalid floating-input" : "form-control floating-input"}
                                    id="password"
                                    autoComplete="off"
                                    placeholder="**************"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    style={{
                                        // backgroundColor: "#F4F2F2",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        textAlign: "start",
                                        color: "#18345E",
                                        borderRadius: "100px",
                                    }}
                                />

                                <a onClick={togglePasswordVisibility}
                                    style={{
                                        position: "absolute",
                                        top: "46px",
                                        right: "20px",
                                        color: "#18345E",
                                        zIndex: 2,
                                    }}>
                                    <i>{showPassword ? <FaEye /> : <FaEyeSlash />}</i>
                                </a>
                                <div className="invalid-feedback">Password is required</div>
                            </div>
                        </div>
                        <div className="form-group basic my-md-2 py-lg-0 mb-lg-1">
                            <div
                                className="input-wrapper"
                                style={{
                                    width: "min(464px,34vw)",
                                    // height: "48px",
                                }}
                            >
                                <label
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        letterSpacing: "0.02em",
                                        color: "#444150",
                                        marginBottom: "8px",
                                    }}
                                    className=""
                                    htmlFor="confirmPassword"
                                >
                                    <span>Confirm Password</span><span style={{ color: "#017BEF" }}>*</span>
                                </label>
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    className={isInvalidConfirmPassword ? "form-control is-invalid mb-1 floating-input" : "form-control mb-1 floating-input"}
                                    id="confirmPassword"
                                    autoComplete="off"
                                    placeholder=""
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    style={{
                                        // backgroundColor: "#F4F2F2",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        textAlign: "start",
                                        color: "#18345E",
                                        borderRadius: "100px",
                                        marginBottom: "8px",
                                    }}
                                />

                                <a onClick={toggleConfirmPasswordVisibility}
                                    style={{
                                        position: "absolute",
                                        top: "46px",
                                        right: "20px",
                                        color: "#18345E",
                                        zIndex: 2,
                                    }}>
                                    <i>{showConfirmPassword ? <FaEye /> : <FaEyeSlash />}</i>
                                </a>
                                <div className="invalid-feedback">{confirmPasswordValidationError}</div>
                            </div>
                        </div>
                        {/*	<div className="form-group basic" >*/}
                        {/*    <div className="input-wrapper">*/}
                        {/*	<label htmlFor="role" style={{*/}
                        {/*				fontStyle: "normal",*/}
                        {/*				fontWeight: "600",*/}
                        {/*				fontSize: "16px",*/}
                        {/*				lineHeight: "24px",*/}
                        {/*				letterSpacing: "0.02em",*/}
                        {/*				color: "#444150",*/}
                        {/*			}}>Select Role</label>*/}
                        {/*        <select className={'form-control floating-select'} id={'role'} onClick={handleClick.bind(this)} onChange={handleChange.bind(this)}*/}
                        {/*		style={{*/}
                        {/*			// backgroundColor: "#F4F2F2",*/}
                        {/*			fontSize: "16px",*/}
                        {/*			lineHeight: "24px",*/}
                        {/*			textAlign: "start",*/}
                        {/*			color: "#18345E",*/}
                        {/*			width:"min(10vw,200px)",*/}
                        {/*			height:"48px"*/}
                        {/*		}}*/}
                        {/*		>*/}
                        {/*            /!* <option value=""></option> *!/*/}
                        {/*            <option value="enterprise">Enterprise User</option>*/}
                        {/*            <option value="host">Host</option>*/}
                        {/*        </select>*/}

                        {/*       */}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/* </div> */}
                        <input
                            type="submit"
                            value="Register"
                            className="btn btn-primary btn-block btn-lg mt-2 mb-2 me-md-auto"
                            style={{
                                // borderRadius: "25px",
                                height: "50.6px",
                                width: "min(464px,34vw)",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "18px",
                                lineHeight: "22px",
                                color: "#white",
                                borderRadius: "100px",
                            }}
                        />
                        <div className="mt-1 text-center w-75 mx-auto">
                            <p
                                className={"text-dark p-0 mb-0"}
                                style={{ fontSize: "min(1vw,12px)" }}
                            >
                                By signing up, you are agreeing to our{" "}
                                <Link to={"/signup"}>Terms & Conditions</Link>
                                &nbsp; and &nbsp;
                                <Link to={"/signup"}>Privacy Policy.</Link>
                            </p>
                        </div>
                        <div className="d-flex flex-row justify-content-center align-items-center mt-2">
                            <button
                                onClick={googleSignUp}
                                className=" btn btn-outline-secondary d-flex flex-row align-items-center justify-content-center me-2 px-0"
                                style={{
                                    width: "min(209.89px,16vw)",
                                    height: "44.72px",
                                    borderRadius: "20px",
                                }}
                            >
                                <img
                                    src={toAbsoluteUrl("/img/google.png")}
                                    alt={"Google Sign In"}
                                    className="me-2"
                                    style={{
                                        width: "36.05px",
                                        height: "34.71px"
                                    }}
                                />
                                <span
                                    className="text-muted text-capitalize font"
                                    style={{ fontSize: "min(1vw,13px)" }}
                                >
                                    sign in with google
                                </span>
                            </button>

                        </div>
                        <div className="mt-1 text-center w-75 mx-auto">
                            <p
                                className={"text-dark text-center p-0 mb-0"}
                                style={{ fontSize: "min(1vw,14px)" }}
                            >
                                Already have an account?&nbsp;
                                <Link to={"/signin"} style={{ textDecoration: "underline" }}>
                                    Sign in
                                </Link>
                            </p>
                        </div>
                        <div className="mt-2 text-center w-75 mx-auto">
                            <p className={"text-muted text-capitalize text-center mb-auto"}>
                                &copy;2022 - 2024 SPACIA All rights reserved
                            </p>
                        </div>
                    </form>

                </div>
                <div
                    className="d-flex flex-column justify-content-center col-lg-7 col-md-6 px-0 col-sm-12 background-size-position"
                    style={{
                        background: `url(${toAbsoluteUrl("/img/Slider.png")})`,
                        backgroundSize: "100%"
                    }}
                >
                    {/* <div className="carousel-full splide">
                <div className="splide__track">
                    <ul className="splide__list">

                        <li className="splide__slide">
                            <div className="card rounded-0">
                                <div className="card-body">
                                    <h5 className="card-title">Swipe me</h5>
                                    <p className="card-text">
                                        Some quick example text to build on the card title and make up the bulk
                                        of the card's content.
                                    </p>
                                </div>
                            </div>
                        </li>

                        <li className="splide__slide">
						<img
									src={toAbsoluteUrl("/img/Slider.png")}
									alt={"Google Sign In"}
									className="me-2"
								/>
                        </li>

                    </ul>
                </div>
            </div> */}
                    {/* <img
						src={toAbsoluteUrl("/img/Slider.png")}
						alt={"Google Sign In"}
						className="img-fluid"
						style={{
							width: "100vw",
							height: "100vh",
							objectFit: "cover",
							objectPosition: "center right",
						}} */}
                </div>
            </div>
        </div>
    );
};
export default SignUp;
