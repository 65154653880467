import React, { useRef, useState, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import { Container, Row, Col, Card, Nav } from "react-bootstrap";
import { FaPencilAlt, FaBell, FaCreditCard, FaShieldAlt, FaIndustry } from "react-icons/fa";
import { Constants } from "../../utils/constants";

import CardPayment from "../../components/Settings/CardPayment";
import MomoPayment from "../../components/Settings/MomoPayment";
import Notifications from "../../components/Settings/Notifications";
import Profile from "../../components/Settings/Profile";
import CompanyProfile from "../../components/Settings/CompanyProfile";
import Password from "../../components/Settings/Password";

import * as SERVICES from "../../services/localService";

const Settings = () => {
	const [currentUserRole, setCurrentUserRole] = useState('');
	const imageInput = useRef(null);
	const [currentRoute, setCurrentRoute] = useState();

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const userRole = currentUser && currentUser.role;

		console.log('current user role:', userRole);

		setCurrentUserRole(userRole);
	}, []);

	const triggerImage = () => {
		console.log(imageInput.current);
		imageInput.current.click();
	};

	const handleSelect = (eventKey) => setCurrentRoute(eventKey);

	return (
		<Container fluid>
			<Row>
				<Col><h4 className="mb-4">Settings</h4></Col>
			</Row>
			<Row>
				<Col>
					<Card className="border-0 min-vh-70">
						<Card.Body>
							<Row className="min-vh-70">
								<Col md="auto" className="pr-5 border-right">
									<Nav activeKey={currentRoute} className="flex-column settings-side" onSelect={handleSelect}>
										<Nav.Link to="/settings" as={Link}>
											<FaPencilAlt className="mr-2" onClick={triggerImage} /> Edit Profile
										</Nav.Link>
										{/* <Nav.Link to="/settings/notification" as={Link}>
											<FaBell className="mr-2" onClick={triggerImage} /> Notification
										</Nav.Link> */}
										{/* {
											currentUserRole && currentUserRole === 'ROLE_SUBSCRIBER_OWNER'
											&& <Nav.Link to="/settings/momopayment" as={Link}>
												<FaCreditCard className="mr-2" onClick={triggerImage} /> Payment
											</Nav.Link>
										} */}
										<Nav.Link to="/settings/security" as={Link}>
											<FaShieldAlt className="mr-2" onClick={triggerImage} /> Password & Security
										</Nav.Link>
										{
											currentUserRole && (currentUserRole === 'ROLE_SUBSCRIBER_OWNER' || currentUserRole === 'ROLE_HOST' || currentUserRole === 'ROLE_ADMINISTRATOR')
											&&
											<Nav.Link to="/settings/company/profile" as={Link}>
												<FaIndustry className="mr-2" onClick={triggerImage} /> Company Profile
											</Nav.Link>
										}
									</Nav>
								</Col>
								<Col className="pl-4">
									<Route
										path="/settings"
										render={({ match: { url } }) => (
											<>
												<Route exact path={`${url}/`} component={Profile} />
												{/* <Route path={`${url}/notification`} component={Notifications} /> */}
												{/*{*/}
												{/*	currentUserRole && currentUserRole === 'ROLE_SUBSCRIBER_OWNER'*/}
												{/*	&& <Route path={`${url}/payment`} component={CardPayment} />*/}
												{/*}*/}
												{
													currentUserRole && currentUserRole === 'ROLE_SUBSCRIBER_OWNER'
													&& <Route path={`${url}/momopayment`} component={MomoPayment} />
												}
												<Route path={`${url}/security`} component={Password} />
												{
													(currentUserRole && (currentUserRole === 'ROLE_SUBSCRIBER_OWNER' || currentUserRole === 'ROLE_HOST' || currentUserRole === 'ROLE_ADMINISTRATOR'))
													&& <Route path={`${url}/company/profile`} component={CompanyProfile} />
												}
											</>
										)}
									/>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Settings;
