import orderSummaryImage from '../../../../assets/img/png/order-summary-image.png';
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Constants } from '../../../../utils/constants';
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import { toAbsoluteUrl } from "../../../../layout/helpers";
import closeButton from "../../../../assets/img/svg/blue-close-button.svg";
import { Modal } from "react-bootstrap";
import successImage from '../../../../assets/img/png/success-with-bubbles.png';
import rejectedImage from '../../../../assets/img/png/rejected-image.png';
import finishSetupIllustration from "../../../../assets/img/png/finish-setup-illustration.png";
import SERVICES from '../../../../services';
import apiService from '../../../../services/apiService';
import aesEcb from "aes-ecb";
import { toast } from 'react-toastify';

const SSE_STORAGE_KEY = 'activeSSEUrl';

function roundToTwo(num: number): number {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}

function generateKey(keyLength: number) {
    const randomChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@()+$";
    let result = "";
    for (let i = 0; i < keyLength; i++) {
        result += randomChars.charAt(
            Math.floor(Math.random() * randomChars.length)
        );
    }
    return result;
}

const isInvoiceAlreadyPaid = (error: any) => {
    const errorResponse = error?.response?.data;
    const errors = errorResponse?.errors;

    if (errors && Array.isArray(errors)) {
        return errors.some(err =>
            err.field?.toLowerCase().includes('already paid')
        );
    }
    return false;
};

const BookingOrderSummary = () => {
    const [currency, setCurrency] = useState('');
    const [orderSummary, setOrderSummary] = useState<any>(null);
    const [summaryList, setSummaryList] = useState<any[]>([]);
    const [order, setOrder] = useState<any>({});
    const [listing, setListing] = useState<any>();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [referer, setReferer] = useState();
    const [spaceType, setSpaceType] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [total, setTotal] = useState(0);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorModal, setErrorModal] = useState(false);
    const [companyRSAPublicKey, setCompanyRSAPublicKey] = useState("");
    const [sseInstance, setSseInstance] = useState<EventSource | null>(null);

    const location = useLocation();
    const history: any = useHistory();

    useEffect(() => {
        const handlePaymentMessage = (event: MessageEvent) => {
            if (event.data?.type === 'PAYMENT_COMPLETE') {
                const { redirectPath } = event.data;

                // Close SSE connection
                cleanupSSE();

                // Redirect to appropriate page
                history.push(redirectPath);
            }
        };

        window.addEventListener('message', handlePaymentMessage);
        return () => window.removeEventListener('message', handlePaymentMessage);
    }, [history]);

    useEffect(() => {
        console.log("location:", location);
        const locationState = location.state;
        
        // Get currency from user in localStorage
        const currentUser = SERVICES.getUser();
        const userCurrency = currentUser?.country?.currency || 'KES';
        setCurrency(userCurrency);

        // Set order from location state
        const orderData = locationState?.order;
        setOrder(orderData);
        setOrderSummary(orderData);

        // Set listing from location state
        const listingData = locationState?.listing;
        setListing(listingData);

        // Set dates from location state
        const startDateValue = locationState?.startDate || locationState?.state?.startDate;
        const endDateValue = locationState?.endDate || locationState?.state?.endDate;
        setStartDate(startDateValue);
        setEndDate(endDateValue);

        // Set referer
        const refererValue = locationState?.referer;
        setReferer(refererValue);
    }, [location]);

    // Add cleanup on mount to handle any lingering connections
    useEffect(() => {
        // Clean up any existing SSE connections from previous sessions
        const existingSSEUrl = localStorage.getItem(SSE_STORAGE_KEY);
        if (existingSSEUrl) {
            const oldSSE = new EventSource(existingSSEUrl);
            oldSSE.close();
            localStorage.removeItem(SSE_STORAGE_KEY);
        }

        // Cleanup on unmount
        return () => {
            cleanupSSE();
            localStorage.removeItem(SSE_STORAGE_KEY);
        };
    }, []);

    const cleanupSSE = () => {
        if (sseInstance) {
            console.log('Closing SSE connection');
            sseInstance.close();
            setSseInstance(null);
            localStorage.removeItem(SSE_STORAGE_KEY);
        }
    };

    const observePaymentStatusCallBack = (
        invoiceToken: any,
        callBackurl: any
    ) => {
        cleanupSSE();

        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;
        const modifiedCallBackUrl = `${callBackurl}%2B&userId=${userId}`;

        console.log('1 Setting up SSE with URL:', modifiedCallBackUrl);
        console.log('PPPPPPPP Setting up new EventSource');
        const sse = new EventSource(modifiedCallBackUrl);
        console.log('PPPPPPPP Setting SSE instance');
        setSseInstance(sse);
        console.log('PPPPPPPP Saving SSE URL to localStorage');
        localStorage.setItem(SSE_STORAGE_KEY, modifiedCallBackUrl);

        console.log('PPPPPPPP Initializing payment tracking variables');
        let hasProcessedPayment = false;
        let retryCount = 0;
        const MAX_RETRIES = 15;

        console.log('PPPPPPPP Adding event listener');

        // Add a message event listener to see all events
        sse.onmessage = (e: MessageEvent) => {
            console.log('PPPPPPPP Generic message event received:', e.type, e.data);
        };

        // Listen for all events
        sse.onopen = (e) => {
            console.log('PPPPPPPP SSE Connection opened:', e);
        };

        // Listen for the specific event type from the server
        sse.addEventListener(order.referenceCode + '+', (e: MessageEvent) => {
            console.log('PPPPPPPP1 Payment event received:', e.data);
            try {
                const data = JSON.parse(e.data);
                console.log('PPPPPPPP2 Parsed payment data:', data);

                if (Array.isArray(data) && data.length > 0) {
                    const paymentInfo = data[0];
                    console.log('PPPPPPPP3 Payment info:', paymentInfo);

                    if (paymentInfo.isPaid === true && !hasProcessedPayment) {
                        console.log('PPPPPPPP4 Payment confirmed as paid');
                        hasProcessedPayment = true;
                        proceedToCreateBookingFromOrder();
                        cleanupSSE();
                        setShowSuccessModal(true);
                    }
                }
            } catch (err) {
                console.error('PPPPPPPP Error parsing payment event:', err);
            }
        });

        // Keep the error handler
        sse.onerror = (e) => {
            console.error('PPPPPPPP SSE Error occurred:', e);
        };
    };

    // Update window unload handler
    useEffect(() => {
        const handleUnload = () => {
            cleanupSSE();
        };

        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    const isAllOfTheQuantityZero = () => {
        if (summaryList && Array.isArray(summaryList) && summaryList.length > 0) {
            return summaryList.every(e => e.quantity === 0);
        }

        return true;
    };

    const handleStuffOnModalHide = (type: string) => {
        switch (type) {
            case 'success':
                setShowSuccessModal(false);
                cleanupSSE();

                // Check if user is still logged in before redirecting
                const currentUser = SERVICES.getUser();
                if (!currentUser) {
                    // If user session is lost, redirect to login
                    history.push('/signin');
                    return;
                }

                // Redirect to reservations
                history.push('/reservations');
                break;

            case 'failure':
                setShowFailureModal(false);
                break;
        }
    };

    const displayModal = (type: string) => {
        switch (type) {
            case 'success':
                setShowSuccessModal(true);
                break;

            case 'failure':
                setShowFailureModal(true);
                break;
        }

        const orderSummaryContainer = document.querySelector('.pkg-order-summary');
        if (orderSummaryContainer != null) {
            orderSummaryContainer.classList.add('blur-background');
        }
    };

    //     const orderId = order ? order.id : 0;
    //     const listingId = ModalProp.id;

    //     const payload2 = {
    //         userId,
    //         listingId,
    //         startOn: startDate,
    //         endOn: endDate,
    //         orderId
    //     };

    //     axios.post(`${Constants.BASE_URL}/booking/api/v1/booking/make/immediate/payment`, payload2).catch(err => console.log('Error creating booking:', err));
    // }


    const proceedToCreateBookingFromOrder = () => {
        console.log('15 proceedToCreateBookingFromOrder called');
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        // Get values from location state
        const listingId = listing?.id;  // listing ID is 19224 from your state
        const orderId = order?.id;      // order ID is 28870 from your state
        const startOn = startDate;      // "2024-12-04T04:07"
        const endOn = endDate;          // "2024-12-05T02:07"

        // Debug logs to see what values we have
        console.log('Making booking call with:', {
            userId,
            listingId,
            startOn,
            endOn,
            orderId
        });

        // Only proceed if we have all required values
        if (!userId || !listingId || !startOn || !endOn || !orderId) {
            console.error('Missing required booking parameters:', {
                userId,
                listingId,
                startOn,
                endOn,
                orderId
            });
            return;
        }

        const payload = {
            userId,
            listingId,
            startOn,
            endOn,
            orderId
        };

        console.log('Sending booking payload:', payload);

        apiService.makeImmediatePaymentBooking(payload)
            .then(res => {
                console.log('Booking API Response:', res);
                if (res.status === 200) {
                    const response = res.data;
                    const bookingResponse = response?.data;
                    const bookingIdsArray: number[] = [];

                    bookingResponse && bookingResponse.forEach((booking: any) => {
                        bookingIdsArray.push(booking.id);
                    });

                    if (bookingIdsArray.length > 0) {
                        // trigger booking confirmation email
                        console.log('Sending email confirmation for bookings:', bookingIdsArray);
                        apiService.sendBookingConfirmationEmail(bookingIdsArray.join(','))
                            .then(res => {
                                console.log('Email confirmation response:', res);
                                displayModal('success');
                            })
                            .catch(err => {
                                console.error('Error sending confirmation email:', err);
                            });
                    }
                }
            }).catch(err => {
                console.error('Error creating booking:', err);
                const errorMessage = err.response?.data?.message || 'Error creating booking';
                setErrorMsg(errorMessage);
                setErrorModal(true);
                setShowFailureModal(true);
            });
    };

    const makeRequestToProcessPayment = (
        bit256: string,
        payload: { key?: any; data: any; },
        processPaymentUrl: string,
        userId: any,
        isMultiple: boolean
    ) => {
        try {
            const encrypted = aesEcb.encrypt(bit256, JSON.stringify(payload.data));
            payload.data = encrypted;

            apiService.processPayment(processPaymentUrl, payload, userId)
                .then((res) => {
                    if (res.status === 200) {
                        const data = res.data.data;
                        const index0 = data && data[0];
                        const authorizationUrl = index0.paymentRequestResponse.data.authorizationUrl;
                        const sseCallbackUrl = index0 && index0["subscriptionCallbackUrl"];

                        // Open Paystack window
                        window.open(authorizationUrl, '_blank');

                        // Start SSE listener
                        observePaymentStatusCallBack(order.referenceCode, sseCallbackUrl);
                    }
                })
                .catch((error) => {
                    console.log("Payment Error Response:", error.response?.data);
                    setIsLoading(false);

                    if (isInvoiceAlreadyPaid(error)) {
                        // Clear any existing error states
                        setErrorModal(false);
                        setErrorMsg("");

                        // Show friendly message
                        toast.info("This payment has already been processed successfully", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                        // Since payment was already successful, proceed with booking creation
                        // proceedToCreateBookingFromOrder();
                        setShowSuccessModal(true);
                    } else {
                        // Handle other errors
                        const errorMessage = error.response?.data?.message ||
                            "An error occurred while processing the payment";
                        setErrorMsg(errorMessage);
                        setErrorModal(true);
                        setShowFailureModal(true);
                    }
                });
        } catch (error) {
            console.error("Encryption error:", error);
            setIsLoading(false);
            setErrorMsg("An error occurred during encryption");
            setErrorModal(true);
            setShowFailureModal(true);
        }
    };

    const processPayment = () => {
        const errors = [];

        if (errors.length === 0) {
            const requestId = uuid();
            const currentUser = SERVICES.getUser();
            const userId = currentUser?.id || order?.billingAccount?.id || 0;
            const companyId = currentUser?.companyId || order?.billingAccount?.company?.id || 0;

            setIsLoading(true);

            // Get currency from user profile in localStorage
            const paymentCurrency = currentUser?.country?.currency || 'KES';
            console.log("Using currency from user profile:", paymentCurrency);

            var invoiceTokens: any[] = [];
            if (order?.referenceCode) {
                invoiceTokens.push(order.referenceCode);
            }

            const resourceUrl = `${Constants.BASE_URL}/platform/company/v1/company/${companyId}/rsa/key/public`;

            console.log(
                "[ " + requestId + " ] about to make request to fetch company RSA public key"
            );

            apiService.getCompanyRsaKey(companyId, userId)
                .then((res) => {
                    if (res.status === 200) {
                        let responseData = res.data.data[0];
                        const publicKey = responseData.rsaPublicKey;

                        // generate 256 bit key
                        const bit256 = generateKey(32);

                        // encrypt 256 bit key with RSA Public Key
                        const url = `${Constants.BASE_URL}/users/v1/generate/base64/encrypted/key`;
                        apiService.generateEncryptedKey({
                            bitKey: bit256,
                            rsaPublicKey: publicKey
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    const requestKey = res.data;

                                    // process payment
                                    const processPaymentUrl = `${Constants.BASE_URL}/users/v1.1/process/payment`;

                                    const payload = {
                                        key: requestKey,  // Now requestKey is defined
                                        data: {
                                            requestId,
                                            invoiceIds: [],
                                            invoiceTokens,
                                            currency: paymentCurrency,
                                            paymentMethodCode: null,
                                            userId,
                                            paymentMethod: "webCheckout",
                                            amount: order.total,
                                            mobilewallet: null,
                                            electronicCardRequest: null,
                                            bankTransferRequest: null,
                                            isCashOnDelivery: false,
                                        },
                                    };

                                    makeRequestToProcessPayment(
                                        bit256,
                                        payload,
                                        processPaymentUrl,
                                        userId,
                                        false
                                    );
                                } else {
                                    setIsLoading(false);
                                }
                            })
                            .catch((err) => {
                                console.log({ err });

                                // Safely extract error message with fallbacks
                                const errorMsg = err?.response?.data?.message ||
                                    err?.message ||
                                    'An error occurred while processing the payment';

                                setErrorModal(true);
                                setErrorMsg(errorMsg);
                                setShowFailureModal(true); // Show the failure modal
                                setErrorMsg("Error processing payment"); // Set the error message
                            });
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);

                    const {
                        response: {
                            data: { message },
                        },
                    } = err;
                    const errorMsg = message;

                    setErrorModal(true);
                    setErrorMsg(errorMsg);
                    setShowFailureModal(true); // Show the failure modal
                    setErrorMsg("Error processing payment"); // Set the error 

                });

        }
    };

    // Add this cleanup for service workers on component unmount
    useEffect(() => {
        return () => {
            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
                navigator.serviceWorker.getRegistrations().then(function (registrations) {
                    for (let registration of registrations) {
                        if (registration.scope.includes('response?references=D')) {
                            registration.unregister();
                        }
                    }
                });
            }
        };
    }, []);

    useEffect(() => {
        localStorage.setItem("paymentSource", "booking");
    }, []);

    return (
        <section className={'pkg-order-summary'} style={{ padding: '0 70px 70px 70px' }}>
            <header style={{ paddingTop: '30px' }}>
                <figure style={{ width: '150px' }}>
                    <img
                        alt={"logo"}
                        decoding={"async"}
                        className={"w100 img-fluid"}
                        src={toAbsoluteUrl("/img/logo-black.png")}
                    />
                </figure>
            </header>
            <div className="row">
                <div className="col-7">
                    <h4 style={{ marginTop: '30px', fontSize: '2rem' }}>Order Summary</h4>
                    <div style={{ fontSize: '1.17rem', marginTop: '50px' }}>
                        <h5>Order No: <span style={{ fontWeight: 'normal' }}>{order ? order.id : ''}</span></h5>
                        <table style={{ tableLayout: 'fixed', width: '100%' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th scope={'col'}>Quantity</th>
                                    <th scope={'col'}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{spaceType}</td>
                                    <td style={{ borderBottom: '1px dotted black' }} />
                                    <td>x{quantity}</td>
                                    <td>{total}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ marginTop: '50px' }}>
                            <p style={{ fontSize: '.93rem', color: '#262626' }}>Total + VAT 16%</p>
                            <p className={'text-primary mb-3'} style={{ fontSize: '2rem', fontWeight: 700, marginTop: '15px' }}>
                                {currency}.<span>{order && order.total ? order.total.toLocaleString() : 0}</span>
                            </p>
                            <p>
                                By Clicking “Pay” I agree to the <br />
                                company's <span style={{ textDecoration: 'underline' }}><a className='text-black' href={process.env.PUBLIC_URL + '/assets/terms_conditions.pdf'} target="_blank" rel="noopener noreferrer">terms & conditions</a></span> for the service
                            </p>
                        </div>

                        <div className={'d-flex justify-content-between'} style={{ width: '35%', marginTop: '100px' }}>
                            <button
                                className="btn btn-outline-info" style={{ width: '100px', borderRadius: '100px', height: '40px' }}
                                onClick={() => { history.go(-1); }}
                            >
                                Back
                            </button>
                            <div style={{ display: (!order) ? 'none' : 'block' }}>
                                <button
                                    className="btn btn-outline-info"
                                    style={{
                                        width: "160px",
                                        borderRadius: "100px",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        setShowConfirmModal(true);
                                    }}
                                >
                                    Pay
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-5 order-summary-bg">
                    <figure>
                        <img className={'order-summary'} src={orderSummaryImage} alt="Order Summary" />
                    </figure>
                </div>
            </div>

            {/* payment successful modal */}
            <Modal show={showSuccessModal} onHide={() => handleStuffOnModalHide('success')}>
                <header className={'position-relative'} style={{ zIndex: 3 }}>
                    <figure className="text-right" style={{ padding: '15px' }}>
                        <img onClick={() => handleStuffOnModalHide('success')} src={closeButton} alt="close button" style={{ width: '25px' }} />
                    </figure>
                </header>
                <Modal.Body style={{ height: '350px' }}>
                    <div className="text-center position-relative" style={{ bottom: '25px' }}>
                        <figure>
                            <img
                                className={'position-relative'}
                                src={successImage}
                                alt="Success"
                                style={{ width: '40%' }}
                            />
                        </figure>
                        <p className={'text-primary font-weight-bolder mt-5'} style={{ fontSize: '1.3rem' }}>
                            Payment Successful!
                        </p>
                        <p>Please check your email for booking confirmation</p>
                        <div className={'mt-4'}>
                            <button
                                className="btn btn-primary"
                                style={{ borderRadius: '30px' }}
                                onClick={() => handleStuffOnModalHide('success')}
                            >
                                Go Back
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* payment failed modal */}
            <Modal show={showFailureModal} onHide={() => handleStuffOnModalHide('failure')}>

                <Modal.Body style={{ height: '230px' }}>
                    <div className="text-center position-relative" style={{ bottom: '0px' }}>
                        <p className={'text-primary font-weight-bolder mt-5'} style={{ fontSize: '1.3rem' }}>
                            Payment Failed!
                        </p>
                        <p className={'text-danger font-weight-bolder mt-5'} style={{ fontSize: '1.3rem' }}>
                            {errorMsg}
                        </p>
                    </div>
                </Modal.Body>
            </Modal>

            {/* confirm payment modal */}
            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                contentClassName="padding-40px"
                style={{
                    overflow: "hidden",
                    borderRadius: "30px",
                }}
            // size="lg"
            >
                <Modal.Header
                    style={{
                        borderBottom: "none",
                        paddingLeft: "30px",
                        paddingBottom: 0,

                        paddingRight: "30px",
                        margin: "0px",
                    }}
                    closeButton
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ width: "100%", textAlign: "center" }}
                    >
                        {/* <h6 className="text-primary">Confirm Payment</h6> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{}}>
                    <div
                        className="text-center position-relative"
                        style={{ bottom: "15px" }}
                    >
                        <span
                            className={"text-primary mt-5"}
                            style={{ fontSize: "1.3rem", fontWeight: 200 }}
                        >
                            You are about to be redirected to checkout
                        </span>
                        <div
                            className={"mt-4"}
                            style={
                                {
                                    // display:
                                    //     referer && referer === "package-signup"
                                    //         ? "display"
                                    //         : "none",
                                }
                            }
                        >
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    borderRadius: "20px",
                                    marginLeft: "0px",
                                    width: "8rem"
                                }}
                                onClick={() => setShowConfirmModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    borderRadius: "20px",
                                    marginLeft: "20px",
                                    width: "8rem"
                                }}
                                onClick={processPayment}
                            >
                                Confirm
                            </button>
                        </div>

                    </div>
                </Modal.Body>
                {/* <ModalFooter */}
            </Modal>
        </section>
    );
};

export default BookingOrderSummary;

