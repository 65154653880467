import React, { useState, useEffect } from "react";
import {Form, Row} from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { Constants} from "../utils/constants";
import SERVICES from "../services";
import apiService from "../services/apiService";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { PhoneInputStyle } from "./PhoneInputStyle";

const MomoPaymentTab = ({setSuccessAlert, setErrorAlert, setSuccessMsg, setErrorMsg}) => {
	const [countryCode, setCountryCode] = useState('');
	const [supportedMobileWalletOperators, setSupportedMobileWalletOperators] = useState([]);
	const [selectedOperator, setSelectedOperator] = useState('');
	const [msisdn, setMsisdn] = useState(undefined);
	const [showNew, setShowNew] = useState(true);
	const [mobileNumber, setMobileNumber] = useState('');
	const [networkOperator, setNetworkOperator] = useState('');
	const [accountId, setAccountId] = useState('');
	const [removeRequestCompleted, setRemoveRequestCompleted] = useState(false);
	const [registeredMobileMoneyAccounts, setRegisteredMobileMoneyAccounts] = useState([]);

	const [isEmptyOperator, setIsEmptyOperator] = useState(false);
	const [isEmptyMsisdn, setIsEmptyMsisdn] = useState(false);
	const [phoneNumberValidationError, setPhoneNumberValidationError] = useState('');

	useEffect(() => {
		if (selectedOperator) setIsEmptyOperator(false);
		if (msisdn) setIsEmptyMsisdn(false);
	}, [selectedOperator, msisdn]);

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;

		apiService.getMobileWalletAccounts(userId)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.data;
					const allMobileWallets = responseData.filter(response => 
						response.paymentMethod === 'mobileWallet'
					);
					setRegisteredMobileMoneyAccounts(allMobileWallets);
				}
			});
	}, [showNew, removeRequestCompleted]);

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;
		const companyId = currentUser ? currentUser.companyId : 0;

		apiService.getCompanyDetails(companyId, userId)
			.then(res => {
				const {data: {data}} = res;
				const country = data[0].country;
				const countryName = country ? country.name.toUpperCase() : null;
				const countryCode = country?.initial;

				setCountryCode(countryCode.toUpperCase());

				apiService.getMobileWalletOperators(countryName)
					.then(res => {
						if (res.status === 200) {
							setSupportedMobileWalletOperators(res.data.data);
						}
					});
			});
	}, []);

	const handleChange = (e) => {
		const selectedValue = e.target.value;
		setSelectedOperator(selectedValue);
		setIsEmptyOperator(false);
	};

	const changeSavedAccount = (e) => {
		const selectedValue = e.target.value;
		if (selectedValue) {
			const [msisdn, operator, id] = selectedValue.split(',');
			setMobileNumber(msisdn);
			setNetworkOperator(operator);
			setAccountId(id);
		} else {
			setMobileNumber('');
			setNetworkOperator('');
			setAccountId('');
		}
	};

	const areNewCardFieldsOkay = () => {
		const requiredFields = [selectedOperator, msisdn];
		const anyEmpty = requiredFields.some(field => !field);
		
		if (anyEmpty) {
			if (!selectedOperator) setIsEmptyOperator(true);
			if (!msisdn) {
				setIsEmptyMsisdn(true);
				setPhoneNumberValidationError('Please enter mobile number');
			}
			return false;
		}
		return true;
	}

	const addMomoPaymentWallet = () => {
		if (!areNewCardFieldsOkay()) return;

		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;
		let formattedMsisdn = msisdn;
		
		if (formattedMsisdn.charAt(0) === '+') {
			formattedMsisdn = formattedMsisdn.slice(1);
		}

		apiService.addMobileWallet(userId, {
			paymentMethod: "mobileWallet",
			mobileWallet: {
				msisdn: formattedMsisdn,
				operator: selectedOperator
			}
		})
		.then(res => {
			if (res.status === 200) {
				setMsisdn(undefined);
				setSelectedOperator('');
				setSuccessAlert(true);
				setSuccessMsg('Mobile Money Account added successfully');
			} else {
				setErrorAlert(true);
				setErrorMsg('There was a problem adding mobile money account');
			}
		})
		.catch(err => {
			console.error('Error adding mobile money account:', err);
			setErrorAlert(true);
			setErrorMsg('There was a problem adding mobile money account');
		});
	}

	const deleteRegisteredMobileWalletAccount = () => {
		const currentUser = SERVICES.getUser();
		const userId = currentUser ? currentUser.id : 0;
		
		setRemoveRequestCompleted(false);

		apiService.deleteMobileWallet(userId, accountId)
			.then(res => {
				if (res.status === 200) {
					setRemoveRequestCompleted(true);
					setSuccessAlert(true);
					setSuccessMsg('Mobile money account has been successfully removed');
					setMobileNumber('');
					setNetworkOperator('');
					setAccountId('');
				} else {
					handleDeleteError();
				}
			})
			.catch(() => handleDeleteError());
	}

	const handleDeleteError = () => {
		setRemoveRequestCompleted(true);
		setErrorAlert(true);
		setErrorMsg('There was a problem removing mobile money account');
		setMobileNumber('');
		setNetworkOperator('');
		setAccountId('');
	}

	return (
		<>
			<div style={{width: '60%'}}>
				<Row>
					<div className="col" onClick={() => setShowNew(true)} style={{cursor: 'pointer'}}>
						<h6>New Wallet</h6>
						<hr style={{ width: showNew && "35%", color: showNew ? "#017BEF" : 'white'}} />
					</div>
					<div className="col" onClick={() => setShowNew(false)} style={{cursor: 'pointer'}}>
						<h6>Saved Wallet</h6>
						<hr style={{ width: !showNew && "35%", color: !showNew ? "#017BEF" : "white"}} />
					</div>
				</Row>
				<div style={{display: showNew ? 'block' : 'none', marginTop: '20px'}}>
					<div className="form-group">
						<label htmlFor="">Mobile Operator</label>
						<select className={ isEmptyOperator ? "form-control is-invalid" : "form-control" } name="mobile_operator" value={selectedOperator} onChange={ handleChange } >
							<option selected={!selectedOperator} disabled value={ "" }>Select mobile wallet operator</option>
							{
								supportedMobileWalletOperators.length > 0 && supportedMobileWalletOperators.map(supportedOperator => {

									const label = supportedOperator?.label;

									return <option value={ supportedOperator?.operator }>{ label }</option>
								})
							}
						</select>
						<div className="invalid-feedback">Please select mobile wallet operator</div>
					</div>
					<br />
					<Form.Group controlId="formGroupEmail">
						<Form.Label>Phone Number</Form.Label>
						<PhoneInputStyle>
							<PhoneInput
								defaultCountry={countryCode}
								inputComponent={Form.Control}
								value={msisdn}
								onChange={(e) => {
									console.log(e);
									setMsisdn(e);
								}}
							/>
						</PhoneInputStyle>
						<div style={{display: isEmptyMsisdn ? 'block' : 'none', fontSize: '0.875em', marginTop: '0.25rem', color: '#dc3545'}}>{phoneNumberValidationError}</div>
					</Form.Group>
					{/*<div className="form-group">
						<label htmlFor="">Phone Number</label>
						<input
							type="text"
							className={ isEmptyMsisdn ? "form-control is-invalid" : "form-control" }
							onChange={e => setMsisdn(e.target.value)}
							value={msisdn}
						/>
						<div className="invalid-feedback">Please enter mobile number</div>
					</div>*/}
					<br />

					<button type='button' className="button" onClick={addMomoPaymentWallet}>Save</button>
				</div>
				<div style={{display: showNew ? 'none' : 'block', marginTop: '20px'}}>
					<div className="form-group">
						<label htmlFor="">Saved Accounts</label>
						<select className={ "form-control" } name="mobile_operator" onChange={changeSavedAccount}>
							<option selected={!mobileNumber && !networkOperator} disabled>Select already registered Mobile Money Account</option>
							{
								registeredMobileMoneyAccounts.length > 0 && registeredMobileMoneyAccounts.map(account => {

									const mobileWallet = account?.mobileWallet;
									const msisdn = mobileWallet?.msisdn;

									const accountId = account?.id;

									const operator = mobileWallet?.operator;
									const operatorLabel = operator?.label;

									return <option value={ `${msisdn},${operatorLabel},${accountId}` }>{ `${msisdn} - ${operatorLabel}` }</option>
								})
							}
						</select>
					</div>
					<br />
					<div className="form-group">
						<label htmlFor="">Mobile Operator</label>
						<input
							type="text"
							className="form-control"
							readOnly
							value={networkOperator}
						/>
					</div>
					<br />
					<div className="form-group">
						<label htmlFor="">Phone Number</label>
						<input
							type="text"
							className="form-control"
							readOnly
							value={mobileNumber}
						/>
					</div>
					<br/>
					<button type='button' className="button" disabled={!mobileNumber && !networkOperator} onClick={deleteRegisteredMobileWalletAccount}>Delete</button>
				</div>
			</div>
		</>
	);
};

export default MomoPaymentTab;
