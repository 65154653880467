import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import Footer from "../../../footer/footer";

export default function PrivacyPolicy() {
    return (
        <div style={{ fontFamily: "Montserrat, sans-serif" }}>
            {/* Header */}
            <Navbar bg="white" expand="lg" className="py-3">
                <Container>
                    <Navbar.Brand as={Link} to="/home">
                        <img
                            src="/assets/img/new-logo.svg"
                            alt="Spacia"
                            width={150}
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginLeft: "50px",
                            marginRight: "50px"
                        }}>
                            <nav style={{ fontSize: "16px", display: "flex", gap: "30px", color: "black" }}>
                                <NavLink to="/host" style={{ color: "black" }} activeClassName="active-link">Become A Host</NavLink>
                                <NavLink to="/corporate-page" style={{ color: "black" }} activeClassName="active-link">Enterprise Solutions</NavLink>
                                <NavLink to="/signin" style={{ color: "black" }} activeClassName="active-link">Find A Space</NavLink>
                                <a href="/corporate-page#faqSection" style={{ color: "black" }}>FAQ</a>
                            </nav>
                            {/* <Link to="/signin" className="btn btn-primary" style={{ borderRadius: "100rem", fontSize: "clamp(14px,1.7vw,15px)" }}>
                                Sign In
                            </Link> */}
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* Main Content */}
            <Container className="py-5">
                <Row className="justify-content-center">
                    <Col md={10} lg={8}>
                        <div className="mb-5">
                            <h1 className="display-4 mb-3">Privacy Policy</h1>
                            <p className="text-muted">Last Updated: 13th Dec. 2024</p>
                        </div>

                        <div className="bg-white p-4 rounded shadow-sm">
                            <section className="mb-5">
                                <h2 className="h3 mb-4">1. Introduction</h2>
                                <p className="text-secondary">
                                    At Spacia, we highly value your privacy and are committed to safeguarding it while providing a personalized and valuable service. This Privacy Policy Statement outlines our data processing practices and the types of data collected by Spacia. While no website can guarantee absolute security, we have implemented appropriate administrative, technical, and physical security measures to protect the personal information you entrust to us. For instance, only authorized personnel can access personal information, and they do so solely for legitimate business purposes. Additionally, we employ encryption when transmitting your personal information between your system and ours, as well as between our system and third parties with whom we share sensitive information. We also utilize firewalls and intrusion detection systems to prevent unauthorized access to your data.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h3 mb-4">2. Your Consent</h2>
                                <p className="text-secondary">
                                    By using this website, you provide your consent to us for the collection and utilization of the information described herein. In the event that we make changes to this policy for any reason, we will notify you of these changes on this page so that you remain informed about how we collect, use, and disclose information, and when we may disclose it. Please note: This policy exclusively pertains to the Spacia website and does not apply to other websites. Our website may contain links to external websites; however, we are not responsible for their policies or procedures.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h3 mb-4">3. Information We Collect</h2>
                                <p className="text-secondary">
                                    We collect and store information that you provide on our website or through other means. This information may include personally identifiable details, such as your first and last name, telephone number, postal and email addresses, username and password, and billing information (such as your credit card number, cardholder name, and card expiration date). We may also request information about your location and preferences. While providing information is generally optional, certain information is necessary for you to receive relevant offerings from Spacia, enjoy the benefits of being a registered member, and engage in specific transactions on our site. For example, only registered members can post space reviews and access members-only newsletters. Moreover, certain details are required for you to submit inquiries or initiate other transactions through our site.
                                </p>
                                <ul className="text-secondary">
                                    <li className="mb-2">Personal Information</li>
                                    <li className="mb-2">Usage Data</li>
                                    <li className="mb-2">Technical Data</li>
                                </ul>
                            </section>

                            <section className="mb-5">
                                <h2 className="h3 mb-4">4. Cookies Policy</h2>
                                <p className="text-secondary">
                                    Cookies are small text files stored on your computer, tablet, or mobile phone. They are not harmful to your device or security and are unrelated to viruses like 'Trojans.' When you visit a website, cookies enable the site to 'remember' you and your usage history each time you return, thus enhancing your online experience. You have the option to configure your browser settings to receive notifications when cookies are sent to your device or to decline cookies altogether. Additionally, you can delete previously set cookies.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h3 mb-4">5. Incognito Visitors</h2>
                                <p className="text-secondary">
                                    We employ cookies to compile visitor statistics, including the number of website visits, the technology used (e.g., Mac or Windows), session duration, and viewed pages. This information assists us in continuous website improvement. Analytics programs like Google Analytics provide insights into visitor sources (e.g., search engines) and whether visitors have previously accessed the site, enabling us to allocate resources to service development rather than marketing. We use Google Analytics, and their Privacy Policy is available for reference. Options to opt out of Google Analytics are also available.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h3 mb-4">6. User Agreement</h2>
                                <p className="text-secondary">
                                    By continuing to use our site, you consent to the placement of cookies on your device. Choosing not to accept our cookies may impact the overall quality of your experience.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h3 mb-4">7. Email Communications</h2>
                                <p className="text-secondary">
                                    If you have registered as a vendor or listed your space in our vendor section, we may send email messages to the address you provided. If you decide to deactivate your Spacia account, we will disable your account and remove your profile information from active view. Please note that we may retain some information associated with your account and past transactions for purposes such as backups, fraud prevention, dispute resolution, investigations, and compliance with legal obligations. Registered Spacia users can modify email subscription preferences at any time on the user's dashboard page. Additionally, whether you are a registered Spacia user or not, you will have the opportunity to unsubscribe from promotional messages in any such email we send.
                                </p>
                            </section>

                            <section className="mb-5">
                                <h2 className="h3 mb-4">8. Contact Us</h2>
                                <p className="text-secondary">
                                    Should you have any questions about this Privacy Policy, please reach out to us at info@spaciaafrica.com. Please be aware that calls to and from Spacia may be recorded. We may use these call recordings to monitor customer service quality and compliance, verify information accuracy, prevent fraud, provide staff training, and ensure legal compliance. We will retain call recordings for a reasonable duration necessary to perform these activities before deleting them. Any personal information acquired during a call will be treated in accordance with the provisions outlined in this Privacy Policy.
                                </p>
                            </section>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Footer */}
            <Footer />
        </div>
    );
}