import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SERVICES from '../../services';

declare global {
    interface Window {
        flutter_inappwebview?: {
            callHandler(handlerName: string, ...args: any[]): void;
        };
    }
}

const PaymentRedirect = () => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const reference = queryParams.get("reference");
        const trxref = queryParams.get("trxref");

        const source = localStorage.getItem("paymentSource");
        const paymentType = localStorage.getItem("paymentType");
        const currentUser = SERVICES.getUser();

        // If no user is logged in, just close the window
        if (!currentUser) {
            if (window.opener) {
                window.close();
                return;
            }
        }

        let redirectPath;

        // Handle both mobile web and desktop redirects
        if (source === 'booking-mobile') {
            redirectPath = '/reservation';
        } else if (source === 'booking') {
            redirectPath = '/reservations';
        } else if (source === 'membership-plan') {
            redirectPath = '/membership-plan';
        } else if (source === 'package-signup') {
            redirectPath = '/signin';
        } else {
            console.log("Unknown payment source:", source);
            // Handle mobile fallback
            if (window.location.pathname.includes('/mobile')) {
                redirectPath = currentUser ? '/mobile/reservations' : '/mobile/signin';
            } else {
                redirectPath = currentUser ? '/reservations' : '/signin';
            }
        }

        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('PaymentComplete', {
                reference,
                trxref,
                redirectPath,
                paymentSource: source
            });
        }
        else if (window.opener) {
            window.opener.postMessage({
                type: 'PAYMENT_COMPLETE',
                redirectPath,
                paymentSource: source,
                isAlreadyPaid: false
            }, '*');

            setTimeout(() => {
                window.close();
            }, 1000);
        }
        else {
            window.dispatchEvent(new CustomEvent('message', {
                detail: {
                    type: 'PAYMENT_COMPLETE',
                    redirectPath,
                    paymentSource: source,
                    isAlreadyPaid: false
                }
            }));

            history.push(redirectPath);
        }
    }, [history, location]);

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-3">Payment successful! Redirecting...</p>
            </div>
        </div>
    );
};

export default PaymentRedirect;