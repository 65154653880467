import React, { useEffect, useState } from "react";
import SERVICES from '../../services';
import apiService from '../../services/apiService';
import { Form } from "react-bootstrap";
import { FaRegEye as FaEye, FaRegEyeSlash as FaEyeSlash } from "react-icons/fa";
import SuccessAlert from "./alerts/SuccessAlert";
import { ErrorAlert } from "./alerts/ErrorAlert";

const Password = () => {
	const [user, setuser] = useState('');
	const [password, setPassword] = useState('');
	const [newpassword, setNewpassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [isEmptyPassword, setIsEmptyPassword] = useState(false);
	const [isEmptyNewPassword, setIsEmptyNewPassword] = useState(false);
	const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const [invalidCredentials, setInvalidCredentials] = useState(false);
	const [validCredentials, setValidCredentials] = useState(false);

	const errorUpdatePassword = (error) => {
		if (error === 401) {
			setInvalidCredentials(true);
		}
	}

	const successfullyUpdatePassword = (res) => {
		setValidCredentials(true);
		setPassword('');
		setNewpassword('');
		setConfirmPassword('');
	}

	useEffect(() => {
		const currentUser = SERVICES.getUser();
		setuser(currentUser);
	}, []);

	const updatePassword = async () => {
		try {
			const payload = {
				confirmPassword,
				existingPassword: password,
				password: newpassword,
				username: user.username
			};

			const res = await apiService.updatePassword(payload);
			successfullyUpdatePassword(res);
		} catch (error) {
			errorUpdatePassword(error.response?.status);
		}
	};

	const togglePasswordVisibility = e => {
		e.preventDefault();
		setShowPassword(!showPassword);
	}

	const toggleNewPasswordVisibility = e => {
		e.preventDefault();
		setShowNewPassword(!showNewPassword);
	}

	const toggleConfirmPasswordVisibility = e => {
		e.preventDefault();
		setShowConfirmPassword(!showConfirmPassword);
	}

	return (
		<div>
			<SuccessAlert 
				show={validCredentials} 
				message={"Successfully updated password"} 
				close={() => setValidCredentials(false)} 
			/>
			<ErrorAlert 
				show={invalidCredentials} 
				message={"The password you have entered is invalid"} 
				close={() => setInvalidCredentials(false)} 
			/>
			<h4>Manage your password</h4>
			<br/>

			<div className="form-group">
				<label htmlFor="currentPassword">Current Password</label>
				<div className="input-group">
					<input
						id="currentPassword"
						type={showPassword ? "text" : "password"}
						className={isEmptyPassword ? "form-control is-invalid" : "form-control"}
						aria-describedby="emailHelpId"
						placeholder=""
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<div className="input-group-addon">
						<a onClick={togglePasswordVisibility}>
							<i>{showPassword ? <FaEye /> : <FaEyeSlash />}</i>
						</a>
					</div>
					<div className="invalid-feedback">Password is required</div>
				</div>
			</div>
			<br/>

			<div className="form-group">
				<label htmlFor="newPassword">New Password</label>

				<div className="input-group">
					<input
						id="newPassword"
						type={showNewPassword ? "text" : "password"}
						className={ isEmptyNewPassword ? "form-control is-invalid" : "form-control" }
						aria-describedby="emailHelpId"
						placeholder="Type your password"
						value={newpassword}
						onChange={(e)=>setNewpassword(e.target.value)}
					/>
					<div className="input-group-addon">
						<a onClick={toggleNewPasswordVisibility}><i>{showNewPassword ? <FaEye /> : <FaEyeSlash />}</i></a>
						{/*<a><i><FaEye/></i></a>*/}
					</div>
					<div className="invalid-feedback">New Password is required</div>
				</div>
			</div>
			<br/>

			<div className="form-group">
				<label htmlFor="">Confirm Password</label>

				<div className="input-group">
					<input
						id="confirmPassword"
						type={showConfirmPassword ? "text" : "password"}
						className={isInvalidConfirmPassword ? "form-control is-invalid" : "form-control"}
						aria-describedby="emailHelpId"
						placeholder="Type your password"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					/>
					<div className="input-group-addon">
						<a onClick={toggleConfirmPasswordVisibility}><i>{showConfirmPassword ? <FaEye/> : <FaEyeSlash/>}</i></a>
						{/*<a><i><FaEye/></i></a>*/}
					</div>
					<div className="invalid-feedback">Confirm Password is required</div>
				</div>
			</div>

			<br />
			<button className="button" style={{width:'auto'}} onClick={updatePassword}>Update Password</button>
		
		{/* <FeedbackModal isOpen={invalidCredentials} title="Invalid Credentials" isClose={()=>setInvalidCredentials(false)} doneButton="Okay">
			<h6>The password you have entered is invalid.</h6>
		</FeedbackModal>
		<FeedbackModal isOpen={validCredentials} title="Success" isClose={()=>setValidCredentials(false)} doneButton="Okay">
			<h6>Your password has been successfully updated.</h6>
		</FeedbackModal> */}

		</div>
	);
};

export default Password;
