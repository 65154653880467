import React, { Suspense, lazy, useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect, useHistory
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../layout/assets/sass/style.scss';
import { FallbackView } from "../../layout/partials";
import MasterLayout from "../../layout/MasterLayout";
import { LayoutInit } from "../../layout/LayoutInit";
import { useDispatch, useSelector } from "react-redux";
import SERVICES from "../../services";
import apiService from "../../services/apiService";
import { saveFilterOptions } from "../../redux/actions/dashboard";
import * as bootstrap from 'bootstrap';
import SearchPage from "./SearchPage";
import Login from "./Login";
import SignUp from "./SignUp";
import SignInForCheckIn from "./auth/SignInForCheckIn";
import SearchResult from "./SearchResults";
import SearchDetails from "./SearchDetails";
import SavePasswordMobile from "./SavePasswordMobile";
import Properties from './Properties';
import Reservation from "./Reservation";
import Pending from "./Pending";
import Cart from "./Cart";
import Profile from "./Profile";
import HostLanding from "./host-landing";
import Landing from "./landing";
import FAQs from "../MobileLayout/FAQs";
import UserManagement from './userManagement';
import UserProfile from "./userManagement/userProfile";
import ProfileEdit from "./userManagement/userProfile/profileEdit";
import InviteUser from "./userManagement/addUser";
import BookingOrderSummary from './booking-order-summary';
import Notifications from './Notifications';
import PaymentRedirect from '../PaymentRedirect';

function MobileLayout() {
    const [cart, setCart] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const dispatch = useDispatch();
    const allEntries = useSelector((state) => state.dashboard.entries);
    const hasItems = useSelector((state) => state.dashboard.hasItems);
    const [redirectDestination, setRedirectDestination] = useState(<div />);
    const history = useHistory();

    const storageKeys = ['companyId', 'currentUser', 'persist:root', 'userCurrency'];

    // const SearchPage = lazy(() => import('../MobileLayout/SearchPage'))
    // const Login = lazy(() => import('../MobileLayout/login'))
    // const SignUp = lazy(() => import('../MobileLayout/SignUp'))
    // const SignInForCheckIn = lazy(() => import('../MobileLayout/auth/SignInForCheckIn'))
    // const SearchResult = lazy(() => import('../MobileLayout/SearchResults'))
    // const SearchDetails = lazy(() => import('../MobileLayout/SearchDetails'))
    // const Reservation = lazy(() => import('../MobileLayout/Reservation'))
    // const Pending = lazy(() => import('../MobileLayout/Pending'))
    // const Profile = lazy(() => import('../MobileLayout/Profile'))
    // const Cart = lazy(() => import('../MobileLayout/Cart'))

    useEffect(() => {
        setCart(hasItems);

        const redirectDestination = ((currentUser == null) || (Object.keys(currentUser).length === 0)) ? <Redirect to="/signin" /> :
            <SearchPage />;

        setRedirectDestination(redirectDestination);

        apiService.getFilterOptions()
            .then((res) => {
                const resData = res.data ? res.data.data : {};
                dispatch(saveFilterOptions(resData));
                console.log(res.data.data);
            })
            .catch(error => {
                console.error('Error fetching filter options:', error);
            });
    }, [hasItems, dispatch, history, currentUser]);

    useEffect(() => {
        console.log('Here is where the current user is set...');
        const currentUser = SERVICES.getUser();

        console.log('current user is:', currentUser);
        setCurrentUser(currentUser);
    }, []);

    return (
        <Router>
            <MasterLayout>
                <Switch>
                    <Route exact path='/home' component={Landing} />
                    <Route exact path='/searchPage' component={SearchPage} />
                    <Route exact path='/properties' component={Properties} />
                    <Route exact path='/signin' component={Login} />
                    <Route exact path='/signup' component={SignUp} />
                    <Route exact path="/login/before/check-in" component={SignInForCheckIn} />
                    <Route exact path="/frequently-asked-questions" component={FAQs} />
                    <Route exact path='/search' component={SearchResult} />
                    <Route exact path='/details' component={SearchDetails} />
                    <Route exact path='/reservation' component={Reservation} />
                    <Route exact path='/pending' component={Pending} />
                    <Route exact path='/profile' component={Profile} />
                    <Route exact path='/cart' component={Cart} />
                    <Route exact path='/host' component={HostLanding} />
                    <Route exact path='/user-management' component={UserManagement} />
                    <Route exact path='/user-profile' component={UserProfile} />
                    <Route exact path='/profile-edit' component={ProfileEdit} />
                    <Route exact path='/add-user' component={InviteUser} />
                    <Route exact path='/savepassword' component={SavePasswordMobile} />
                    <Route exact path='/booking-order-summary' component={BookingOrderSummary} />
                    <Route exact path='/payment-redirect' component={PaymentRedirect} />
                    <Route exact path='/notifications' component={Notifications} />
                    <Route exact path="/">{redirectDestination}</Route>
                </Switch>

                {/* <Suspense fallback={<FallbackView />}>
                </Suspense> */}
                <LayoutInit />
            </MasterLayout>
        </Router>
    );
}

export default MobileLayout;
