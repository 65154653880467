import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import Footer from "../../../footer/footer";

export default function TermsofUse() {
  return (
    <div style={{ fontFamily: "Montserrat, sans-serif" }}>
      {/* Header */}
      <Navbar bg="white" expand="lg" className="py-3">
        <Container>
          <Navbar.Brand as={Link} to="/home">
            <img
              src="/assets/img/new-logo.svg"
              alt="Spacia"
              width={150}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginLeft: "50px",
              marginRight: "50px"
            }}>
              <nav style={{ fontSize: "16px", display: "flex", gap: "30px", color: "black" }}>
                <NavLink to="/host" style={{ color: "black" }} activeClassName="active-link">Become A Host</NavLink>
                <NavLink to="/corporate-page" style={{ color: "black" }} activeClassName="active-link">Enterprise Solutions</NavLink>
                <NavLink to="/signin" style={{ color: "black" }} activeClassName="active-link">Find A Space</NavLink>
                <a href="/corporate-page#faqSection" style={{ color: "black" }}>FAQ</a>
              </nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main Content */}
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col md={10} lg={8}>
            <div className="mb-5">
              <h1 className="display-4 mb-3">Terms & Conditions</h1>
              <p className="text-muted">Last Updated: 13th Dec. 2024</p>
            </div>

            <div className="bg-white p-4 rounded shadow-sm">
              <section className="mb-5">
                <h2 className="h3 mb-4">1. Introduction</h2>
                <p className="text-secondary">
                  Welcome to the Spacia Africa website and its related services, including but not limited to <strong>www.spacia.africa</strong> (the "Website"). These Terms of Use ("Terms") govern your use of our services (the "Service"). By accessing or using the Service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the Service.
                </p>

              </section>

              <section className="mb-5">
                <h2 className="h3 mb-4">2. Our Services</h2>
                <p className="text-secondary">
                  We are dedicated to helping businesses find their ideal workspace in Africa. Our services go beyond traditional brokers; we provide workspace specialists who offer the following:
                </p>
                <ul className="text-secondary">
                  <li className="mb-2">1.1. Workspace Advisory: We advise on office suitability right from the start of your search.</li>
                  <li className="mb-2">1.2. Continuous Support: We ensure that the selected workspace meets your unique requirements.</li>
                  <li className="mb-2">1.3. Market Insights: We provide updates to help you get the best value for your investment.</li>
                  <li className="mb-2">1.4. Flexibility: We assist in adjusting your workspace requirements as needed during your term.</li>
                  <li className="mb-2">1.5. Renewals and Relocations: We check in before your renewal/end date to confirm your plans, whether to extend, vacate, or relocate.</li>
                </ul>
                <p className="text-secondary">All these services are provided to you at no cost.</p>
              </section>

              <section className="mb-5">
                <h2 className="h3 mb-4">3. How We Work</h2>
                <p className="text-secondary">
                  Spacia Africa is your go-to office broker in Africa. We conduct extensive searches with a network of trusted office providers, including landlords and commercial property agents, to secure the ideal space based on your inquiry information. Please refer to our Privacy and Cookies policy for more information on how we handle your data.
                </p>
              </section>

              <section className="mb-5">
                <h2 className="h3 mb-4">4. Additional Terms</h2>
                <p className="text-secondary">
                  These Terms reference the following additional terms, which also apply to your use of our website:
                </p>
                <ul className="text-secondary">
                  <li className="mb-2">● Privacy and Cookies Policy: Describes how we process your personal data.</li>
                  <li className="mb-2">● Acceptable Use Policy: Sets out permissible and prohibited uses of our website.</li>
                </ul>
              </section>

              <section className="mb-5">
                <h2 className="h3 mb-4">5. Accessing Our Website</h2>
                <p className="text-secondary">
                  5.1. Temporary Access: Access to our website is temporary and may be withdrawn or amended without notice. We are not liable if our website is unavailable for any reason.
                </p>
                <p className="text-secondary">
                  5.2. User Identification: If you receive a user identification code or password, keep it confidential and do not disclose it to third parties. We may disable user identification codes or passwords at our discretion if there is a violation of these Terms.
                </p>
                <p className="text-secondary">
                  5.3. Content Accuracy: Although we make reasonable efforts to update our website's content, we do not guarantee its accuracy, completeness, or timeliness.
                </p>
              </section>

              <section className="mb-5">
                <h2 className="h3 mb-4">6. Intellectual Property Rights</h2>
                <p className="text-secondary">
                  6.1. Ownership: We own or have licenses for all intellectual property rights on our website and its content.
                </p>
                <p className="text-secondary">
                  6.2. Usage: You may print or download materials from our website for personal reference within your organization. Do not modify or use materials for commercial purposes without obtaining a license from us.
                </p>
                <p className="text-secondary">
                  6.3. Submitted Content: By submitting content to our website, you grant us a royalty-free, perpetual, irrevocable right to use, reproduce, and distribute that content in connection with our services.
                </p>
              </section>

              <section className="mb-5">
                <h2 className="h3 mb-4">7. Liability</h2>
                <p className="text-secondary">
                  We provide website content "as is" and disclaim all warranties. We are not liable for any loss or damage incurred by users in connection with our website. Our liability is limited as permitted by law.
                </p>
              </section>

              <section className="mb-5">
                <h2 className="h3 mb-4">8. Your Indemnity</h2>
                <p className="text-secondary">
                  You agree to indemnify and hold us harmless from claims arising from your use of our website, your violation of these Terms, or infringement of third-party rights.
                </p>
              </section>

              <section className="mb-5">
                <h2 className="h3 mb-4">9. Information We Collect About You</h2>
                <p className="text-secondary">
                  We process information about you according to our Privacy and Cookies Policy. By using our website, you consent to this processing.
                </p>
              </section>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Footer */}
      <Footer />
    </div>
  );
}