import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import moment from "moment";
import apiService from '../../../services/apiService';
import { Constants } from '../../../utils/constants';


function HostsRow({companyName, emails, telephones, signUpDate, companyAddress, isApprovedHostPage, togglePendingHosts, ownerName, ownerId, companyId}) {

    const [isHostSelected, setIsHostSelected] = useState(false);

const handleCheckboxChange = (event) => {
    setIsHostSelected(event.target.checked);
};
    const [one, setOne] = useState(null);
    const [two, setTwo] = useState(null);

    useEffect(() => {
        console.log('page re-rendered');
    });

    useEffect(() => {
        console.log('about to send request to get regulatory documents');
        apiService.getCompanyRegulatoryDocuments(companyId, ownerId)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    if (res.data.data[0] && res.data.data[0].regulatoryDocuments[0]) {
                        setOne(res.data.data[0].regulatoryDocuments[0].url)
                    }
                    if (res.data.data[0] && res.data.data[0].regulatoryDocuments[1]) {
                        setTwo(res.data.data[0].regulatoryDocuments[1].url)
                    }
                }
            })
    }, [companyId, ownerId]);


    const link = {
        fontWeight: 300,
        color: 'blue'
    }

    const missing = {
        fontWeight: 300,
        color: 'red'
    }


    return (
        <tbody>
        <tr>
            <td>
                <div className="d-flex align-items-start flex-column" style={{ height: '80px' }}>
                    <div className="mb-auto"><h6>{companyName}</h6></div>
                    <div><h6>{companyAddress ? companyAddress : 'N/A'}</h6></div>
                </div>
            </td>
            <td>
                <div>
                    <h6>{ownerName ? ownerName : 'N/A'}</h6>
                    <h6>{emails && Array.isArray(emails) && emails.length > 0 && emails[0]}</h6>
                    <h6>{(telephones && Array.isArray(telephones) && telephones.length > 0) ? telephones[0] : 'N/A'}</h6>
                </div>
            </td>
            <td>
                <div>
                    {one ? <h6><a style={link} href={one}>View document</a></h6> : <h6 style={missing}>Documents missing</h6>}
                    {two ? <a style={link} href={two}>View document</a> : <h6 style={missing}>&nbsp;</h6>}
                    <h6 style={missing}>&nbsp;</h6>
                </div>
            </td>
            <td className="align-top">
                {signUpDate && moment(signUpDate).format('DD-MMM-YYYY')}
            </td>
            {
                !isApprovedHostPage && <td className="align-top">
                    <Form.Check type="checkbox" onChange={togglePendingHosts} />
                </td>
            }
        </tr>
        </tbody>
    )
}

export default HostsRow
