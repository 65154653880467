import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Constants } from '../utils/constants';
import { Enquiry } from '../types';
import { Bundle } from '../data/package-select-data';

// Types
interface ApiResponse<T = any> {
  data: T;
  status: number;
  message?: string;
}

interface LoginCredentials {
  username: string;
  password: string;
  companyId: number;
}

interface SignupData {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  confirmPassword: string;
  avatar: string;
  companyId: number;
  contacts: Array<{
    isContactable: boolean;
    isPrimary: boolean;
    type: string;
    value: string;
  }>;
  role?: string;
}

interface OTPVerification {
  otp: string;
  username: string;
}

interface GoogleAuthData {
  accessToken: string;
  companyId: number;
  provider: string;
}

interface UserProfileUpdate {
  firstName: string;
  lastName: string;
  contacts: Array<{
    deviceTokens: string[];
    isContactable: boolean;
    isPrimary: boolean;
    type: string;
    value: string;
  }>;
  avatar?: string | null;
}

interface BookingPayload {
  userId: number;
  listingId: number;
  startOn: string;
  endOn: string;
  orderId: number;
}

interface LoginResponse {
  data: {
    data: [{
      id: number;
      token: string;
      firstName: string;
      lastName: string;
      role: string;
      packagePlan?: any;
      country: {
        currency: string;
      };
      companyId: number;
    }];
    message?: string;
  };
  status: number;
}

interface UpdatePasswordPayload {
  confirmPassword: string;
  existingPassword: string;
  password: string;
  username: string;
}

interface RegulatoryDocument {
  url: string;
}

interface CompanyData {
  regulatoryDocuments: RegulatoryDocument[];
}

interface CompanyResponse {
  data: CompanyData[];
  status: number;
}

interface UserProfileData {
  firstName: string;
  lastName: string;
  contacts: Array<{
    deviceTokens: string[];
    isContactable: boolean;
    isPrimary: boolean;
    type: string;
    value: string;
  }>;
  avatar?: string | null;
}

interface BundleMetricsResponse {
  data: Array<{
    bundle: {
      id: string;
      bundleType: {
        value: string;
        label: string;
      };
      description: string;
      pricing: {
        cost: number;
        currency: string;
      };
      offers: string[];
      minUsers: number;
      maxUsers: number;
    };
    company: any;
    totalAvailable: number;
    totalPurchased: number;
    totalUsedUp: number;
  }>;
}

interface InviteUserPayload {
  emailsOfInvitees: string[];
  inviterId: number;
  role: string;
  bundleType?: string;
}

interface BulkInvitePayload {
  inviterId: number;
  role: string;
  bundleType?: string;
  file: File;
}

interface SavePasswordPayload {
  confirmPassword: string;
  password: string;
  token: string;
}

interface LocationSearchResponse {
  ADDRESS?: Array<{
    address: string;
    city: string;
    country: {
      label: string;
      value: string;
    };
  }>;
  CITY?: Array<{
    city: string;
    country: {
      label: string;
      value: string;
    };
  }>;
  COUNTRY?: Array<{
    country: {
      label: string;
      value: string;
    };
  }>;
  COMPANY_NAME?: Array<{
    name: string;
  }>;
}

interface BundleAssignPayload {
  ownerId: number;
  userId: number;
  bundleType: string;
  operation: "ASSIGN" | "REMOVE";
}

interface AnnouncementPayload {
  title: string;
  message: string;
  datetimeToSend: string;
  actionType: string;
  data: Record<string, any>;
  hasCTA: boolean;
  token: string;
  topic: string;
  roles?: string[];
  userIds?: number[];
}

interface AnnouncementUpdatePayload {
  id: number;
  title: string;
  message: string;
  datetimeToSend: string;
}

interface Role {
  value: string;
  name: string;
  description: string;
}

interface UserProfileUpdateWithFCM {
  firstName: string;
  lastName: string;
  contacts: Array<{
    deviceTokens: string[];
    isContactable: boolean;
    isPrimary: boolean;
    type: string;
    value: string;
  }>;
  avatar?: string | null;
}

interface CheckInOutResponse {
  data: any;
  status: number;
}

export interface GoogleAuthResponse {
  data: {
    data: [{
      id: number;
      token: string;
      firstName: string;
      lastName: string;
      role: string;
      username: string;
      packagePlan?: any;
      country: {
        currency: string;
      };
      companyId: number;
    }];
    message?: string;
  };
  status: number;
}

// Add interface for bundle response
interface BundleResponse {
  data: {
    data: Bundle[];
    message?: string;
  };
  status: number;
}

// Add interfaces for notifications
interface NotificationResponse {
  privateNotifications: Array<NotificationData>;
  publicNotifications: Array<NotificationData>;
  roleBasedNotifications: Array<NotificationData>;
}

interface NotificationData {
  id: number | string;
  title: string;
  body: string;
  createdAt: {
    year: number;
    monthValue: number;
    dayOfMonth: number;
    hour: number;
    minute: number;
  };
  readStatus: boolean;
}

// Add interface for check-in activities
interface CheckInActivity {
  location: {
    address: string;
  };
  checkInAt: string;
  checkOutAt: string;
  firstName: string;
  lastName: string;
}

// Add interface for bundle purchase request
interface BundlePurchaseRequest {
  ownerId: number;
  bundleTypeAndQuantityList: Array<{
    bundleType: string;
    quantity: number;
  }>;
}

// Add interface for issue report
interface IssueDetails {
  name: string;
  email: string;
  phoneNumber: string;
  problemDescription: string;
}

// Add interface for filter options response
interface FilterOptionsResponse {
  data: {
    amenities: any[];
    propertyTypes: any[];
    subCategories: any[];
    zones: any[];
  };
}

class ApiService {
  private api: AxiosInstance;
  private publicApi: AxiosInstance;

  constructor() {
    // Create authenticated API instance
    this.api = axios.create({
      baseURL: Constants.BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Create public API instance for non-authenticated calls
    this.publicApi = axios.create({
      baseURL: Constants.BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add auth token interceptor
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem('authToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    // Add response interceptor for both instances
    const responseInterceptor = (response: any) => {
      return {
        data: response.data,
        status: response.status,
        message: response.data?.message,
      };
    };

    this.api.interceptors.response.use(responseInterceptor);
    this.publicApi.interceptors.response.use(responseInterceptor);
  }

  // Auth endpoints
  public async login(credentials: LoginCredentials): Promise<ApiResponse> {
    return this.publicApi.post('/users/api/v1/login', credentials);
  }

  public async signup(userData: SignupData): Promise<ApiResponse> {
    return this.publicApi.post('/users/api/v1/users', userData);
  }

  public async verifyOTP(data: OTPVerification): Promise<ApiResponse> {
    return this.publicApi.post('/users/api/v1/verify-otp', data);
  }

  public async googleAuth(payload: {
    accessToken: string;
    companyId: number;
    provider: string;
  }): Promise<GoogleAuthResponse> {
    return this.publicApi.post('/users/api/v1/utils/exchange-token', payload);
  }

  public async resetPassword(username: string): Promise<ApiResponse> {
    const referer = Constants.BASE_URL || '';
    return this.publicApi.post(`/users/api/v1/users/reset-password?username=${encodeURIComponent(username)}&referer=${encodeURIComponent(referer)}`);
  }

  public async updateUserProfile(userId: string, data: UserProfileUpdate): Promise<ApiResponse> {
    return this.api.put(`/users/api/v1/users/${userId}`, data);
  }

  // FCM Token
  public async updateFCMToken(userId: number, fcmToken: string): Promise<ApiResponse> {
    return this.api.put(`/users/api/v1/users/${userId}`, {
      contacts: [{
        deviceTokens: [fcmToken],
        isContactable: true,
        isPrimary: false,
        type: "deviceToken",
        value: fcmToken
      }]
    });
  }

  // Generic methods
  public async get<T>(endpoint: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.api.get(endpoint, config);
  }

  public async post<T>(endpoint: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.api.post(endpoint, data, config);
  }

  public async put<T>(endpoint: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.api.put(endpoint, data, config);
  }

  public async delete<T>(endpoint: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.api.delete(endpoint, config);
  }

  // Public methods for non-authenticated endpoints
  public async publicGet<T>(endpoint: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.publicApi.get(endpoint, config);
  }

  public async publicPost<T>(endpoint: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return this.publicApi.post(endpoint, data, config);
  }

  // Package Order Summary APIs
  public async getCompanyRsaKey(companyId: number, userId: number): Promise<ApiResponse> {
    return this.api.get(`/platform/company/v1/company/${companyId}/rsa/key/public`, { params: { userId } });
  }

  /**
   * Process payment for bookings and packages
   * Used by:
   * - booking-order-summary/index.tsx
   * - package-order-summary/index.tsx
   */
  public async processPayment(url: string, payload: any, userId: number): Promise<ApiResponse> {
    return this.api.post(url, payload, { params: { userId } });
  }

  public async getPaystackData(userId: number): Promise<ApiResponse> {
    return this.publicApi.get(`/booking/api/v1/order/invoices`, { params: { userId, isPaged: false } });
  }

  /**
   * Generate encrypted key for payment processing
   * Used by:
   * - booking-order-summary/index.tsx
   * - package-order-summary/index.tsx
   */
  public async generateEncryptedKey(payload: {
    bitKey: string;
    rsaPublicKey: string;
  }): Promise<ApiResponse> {
    return this.api.post('/users/v1/generate/base64/encrypted/key', payload);
  }

  // Booking Methods
  public async createBookingFromOrder(payload: BookingPayload): Promise<ApiResponse> {
    return this.publicApi.post('/booking/api/v1/booking/make/immediate/payment', payload);
  }

  public async sendBookingConfirmationEmail(bookingIds: string): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/booking/email', {
      params: { bookingIds }
    });
  }

  // SSE Helper Methods
  public createEventSource(callbackUrl: string, userId: number): EventSource {
    const modifiedCallBackUrl = `${callbackUrl}%2B&userId=${userId}`;
    return new EventSource(modifiedCallBackUrl);
  }

  // Package Management Methods
  public async getBundlePricings(userId: number): Promise<ApiResponse> {
    return this.publicApi.get('/users/v1/bundle/pricings', {
      params: { userId },
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    });
  }

  public async updateBundlePricing(bundleId: number, payload: any, userId: number): Promise<ApiResponse> {
    return this.api.put(`/users/v1/bundle/pricings/${bundleId}`, payload, {
      params: { userId },
      withCredentials: true
    });
  }

  // Add these methods to ApiService class if not already present
  public async getOrders(userId: number, page: number = 0, search: string = ''): Promise<ApiResponse> {
    return this.publicApi.get('/booking/api/v1/order/invoices', {
      params: { userId, page, search }
    });
  }

  // public async downloadInvoice(userId: number, token: string): Promise<Blob> {
  //   return this.publicApi.get(`/users/invoice/${userId}/${token}.pdf`, {
  //     responseType: 'blob'
  //   });
  // }

  // Add these methods to ApiService class
  public async getMobileWalletAccounts(userId: number): Promise<ApiResponse> {
    return this.api.get(`/users/v1/user/${userId}/payment/methods`);
  }

  public async getUnpaidInvoices(userId: number, searchValue: string = ''): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/order/invoices', {
      params: { userId, isPaged: false, search: searchValue }
    });
  }

  public async downloadStatement(userId: number, startAt: string, endAt: string): Promise<Blob> {
    return this.api.get(`/users/v1/customer/account/transactions/statement.pdf`, {
      params: { customerId: userId, userId, startAt, endAt },
      responseType: 'blob'
    });
  }

  // Add this method to ApiService class
  public async createBookingFromOrderPayment(payload: any): Promise<ApiResponse> {
    return this.api.post('/booking/api/v1/booking/make/payment', payload);
  }

  public async sendBookingConfirmationEmails(bookingIds: string[]): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/booking/email', {
      params: { bookingIds: bookingIds.join(',') }
    });
  }

  // Add this method to ApiService class if not already present
  public async updateUser(id: string, data: any): Promise<ApiResponse> {
    return this.api.put(`/users/api/v1/users/${id}`, data);
  }

  // Add these methods to ApiService class
  public async getMobileWalletOperators(country: string): Promise<ApiResponse> {
    return this.api.get('/users/v1/user/payment/method/mobile/wallet/operators', {
      params: { country }
    });
  }

  public async getCompanyDetailsById(id: string, userId: string): Promise<ApiResponse> {
    return this.api.get(`${Constants.BASE_URL}/platform/company/v1/company/` + id + "?userId=" + userId);
  }

  public async getCompanyDetailsByNumber(companyId: number, userId: number): Promise<ApiResponse> {
    return this.api.get(`/platform/company/v1/company/${companyId}`, {
      params: { userId }
    });
  }

  public async addMobileWallet(userId: number, payload: any): Promise<ApiResponse> {
    return this.api.post(`/users/v1/user/${userId}/payment/method`, payload);
  }

  public async deleteMobileWallet(userId: number, accountId: string): Promise<ApiResponse> {
    return this.publicApi.delete(`/users/v1/user/${userId}/payment/method/${accountId}`);
  }

  // Add these methods to ApiService class

  public async getUserInvoices(userId: number, statusNotIncluded: string = 'INCOMPLETE'): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/order/invoices', {
      params: { userId, statusNotIncluded }
    });
  }

  public async getUserListings(userId: number, params: {
    status?: string;
    page?: number;
    search?: string;
  }): Promise<ApiResponse> {
    return this.api.get(`/booking/api/v1/listings/${userId}`, { params });
  }

  public async getUserMobileWalletAccounts(userId: number): Promise<ApiResponse> {
    return this.api.get(`/users/v1/user/${userId}/payment/methods`);
  }

  public async downloadUserStatement(userId: number, startAt: string, endAt: string): Promise<Blob> {
    return this.api.get(`/users/v1/customer/account/transactions/statement.pdf`, {
      params: { customerId: userId, userId, startAt, endAt },
      responseType: 'blob'
    });
  }

  public async downloadInvoice(userId: number, token: string): Promise<Blob> {
    return this.api.get(`/users/invoice/${userId}/${token}.pdf`, {
      responseType: 'blob'
    });
  }

  public async getUnpaidInvoices2(userId: number, isPaged: boolean = false): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/order/invoices', {
      params: { userId, isPaged }
    });
  }

  // Add this new method for getting listing tags
  public async getListingTags(): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/listings/tags');
  }

  // Add this method for getting company regulatory documents
  public async getCompanyRegulatoryDocuments(companyId: number, userId: number): Promise<ApiResponse<CompanyResponse>> {
    return this.api.get(`/platform/company/v1/company/${companyId}`, {
      params: { userId }
    });
  }

  public async updatePassword(payload: UpdatePasswordPayload): Promise<ApiResponse> {
    return this.api.put('/users/api/v1/users/update-password', payload);
  }

  public async getUserProfile(userId: number): Promise<ApiResponse> {
    return this.api.get(`/users/api/v1/users/${userId}`);
  }

  public async getUserDetails(userId: string): Promise<ApiResponse> {
    return this.api.get(`/users/api/v1/users/${userId}`);
  }
  public async updateUserDetails(userId: string, payload: {
    firstName: string;
    lastName: string;
    contacts: Array<{
      deviceTokens: string[];
      isContactable: boolean;
      isPrimary: boolean;
      type: string;
      value: string;
    }>;
    avatar?: string | null;
  }): Promise<ApiResponse> {
    return this.api.put(`/users/api/v1/users/${userId}`, payload);
  }

  public async deleteUserAccount(userId:  string | number): Promise<ApiResponse> {
    return this.api.delete(`/users/api/v1/user/${userId}`);
  }

  public async updateProfile(userId: string, payload: any): Promise<ApiResponse> {
    return this.api.put(`/users/api/v1/users/${userId}`, payload);
  }

  public async updateCompanyProfile(companyId: string, payload: any, headers: any): Promise<ApiResponse> {
    return this.api.put(`${Constants.BASE_URL}/platform/company/v1/company/` + companyId, payload, {
        headers: {
            'Content-Type': 'application/json',
            ...headers
        }
    });
  }

  public async getSupportedCountries(headers: any): Promise<ApiResponse> {
    return this.api.get(`${Constants.BASE_URL}/platform/company/v1/supported/countries`, {
        headers: {
            'Content-Type': 'application/json',
            ...headers
        }
    });
  }

  public async getSupportedBusinessDocuments(isRequired: boolean, userId: string, headers: any): Promise<ApiResponse> {
    return this.api.get(
        `${Constants.BASE_URL}/platform/company/v1/supported/regulatory/documents?isRequired=${isRequired}&userId=${userId}`,
        {
            headers: {
                'Content-Type': 'application/json',
                ...headers
            }
        }
    );
  }

  public async getRegulatoryDocumentCompliance(companyId: string, userId: string, headers: any): Promise<ApiResponse> {
    return this.api.get(
        `${Constants.BASE_URL}/platform/company/v1/company/${companyId}/regulatory/compliance?userId=${userId}`,
        {
            headers: {
                'Content-Type': 'application/json',
                ...headers
            }
        }
    );
  }

  public async deleteCompanyRegulatoryDocument(id: string, userId: string, headers: any): Promise<ApiResponse> {
    return this.api.delete(
        `${Constants.BASE_URL}/platform/company/v1/company/regulatory/documents/${id}?userId=${userId}`,
        {
            headers: {
                'Content-Type': 'application/json',
                ...headers
            }
        }
    );
  }

  public async uploadCompanyRegulatoryDocument(userId: string, supportRegDocId: string, formData: FormData, headers: any): Promise<ApiResponse> {
    return this.api.post(
        `${Constants.BASE_URL}/platform/company/v1/company/regulatory/documents?userId=${userId}&supportedRegulatoryDocumentId=${supportRegDocId}`,
        formData,
        {
            headers: {
                'content-type': 'multipart/form-data; boundary=' + headers.token,
                ...headers
            }
        }
    );
  }

  public async fetchNotifications(page: number, size: number, userId: string | number): Promise<ApiResponse> {
    return this.api.get(`/messaging/api/v1/push-notifications?page=${page + 1}&size=${size}&userId=${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
  }

  public async markAllNotificationsAsRead(userId: string | number): Promise<ApiResponse> {
    return this.api.patch(
      `/messaging/api/v1/push-notifications/users/${userId}/mark-all-read`,
      {},  // empty body
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }

  public async updateNotificationReadStatus(notificationId: string | number, userId: string | number, readStatus: boolean): Promise<ApiResponse> {
    return this.api.patch(
      `/messaging/api/v1/push-notifications/${notificationId}/users/${userId}/read-status?readStatus=${readStatus}`,
      {},  // empty body
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }

  public async getFilterOptions(): Promise<ApiResponse<FilterOptionsResponse>> {
    return this.api.get('/booking/api/v1/listings/filter/options');
  }

  public async getInvoices(userId: number, params: { 
    statusNotIncluded?: string;
    page?: number;
    isPaged?: boolean;
  } = {}): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/order/invoices', { params: { userId, ...params } });
  }

  public async downloadInvoicePdf(userId: number, referenceCode: string): Promise<Blob> {
    return this.api.get(`/users/invoice/${userId}/${referenceCode}.pdf`, {
      responseType: 'blob'
    });
  }

  public async downloadStatementPdf(params: {
    customerId: number;
    userId: number;
    startAt: string;
    endAt: string;
  }): Promise<Blob> {
    return this.api.get('/users/v1/customer/account/transactions/statement.pdf', {
      params,
      responseType: 'blob'
    });
  }

  public async getUserPaymentMethods(userId: number): Promise<ApiResponse> {
    return this.api.get(`/users/v1/user/${userId}/payment/methods`);
  }

  public async getBookings(params: {
    userId?: number;
    sortBy?: string;
    status?: string;
    page?: number;
    search?: string;
  }): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/booking', { params });
  }

  public async getUserBookings(userId: number, params: {
    sortBy?: string;
    status?: string;
    page?: number;
    search?: string;
  }): Promise<ApiResponse> {
    return this.api.get(`/booking/api/v1/booking/${userId}`, { params });
  }

  public async cancelBooking(bookingId: number, userId: number): Promise<ApiResponse> {
    return this.api.patch(`/booking/api/v1/booking/${bookingId}/cancel`, null, {
      params: { cancel: true, userId }
    });
  }

  public async getPendingApprovals(params: {
    ownerId: number;
    page?: number;
    search?: string;
  }): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/order/pending/approvals', { params });
  }

  public async getUserPendingApprovals(params: {
    ownerId: number;
    page?: number;
    search?: string;
  }): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/order/user/pending/approvals', { params });
  }

  public async confirmApprovals(approverId: number, orderIds: string): Promise<ApiResponse> {
    return this.api.post(`/booking/api/v1/order/confirm/approvals`, {}, {
      params: { approverId, orderIds }
    });
  }

  public async declineApprovals(approverId: number, orderIds: string): Promise<ApiResponse> {
    return this.api.post(`/booking/api/v1/order/decline/approvals`, {}, {
      params: { approverId, orderIds }
    });
  }

  public async deleteListingImage(imageId: number): Promise<ApiResponse> {
    return this.api.delete(`/booking/api/v1/listings/media/delete/image/${imageId}`);
  }

  public async updateListingMedia(mediaId: number, formData: FormData): Promise<ApiResponse> {
    return this.api.put(`/booking/api/v1/listings/media/${mediaId}`, formData, {
      headers: { "content-type": "multipart/form-data" }
    });
  }

  public async updateListing(listingId: number, payload: {
    additionalSpec: string;
    amenities: string[];
    description: string;
    details: {
      capacity: number;
      floorNumber: string;
      id: number;
      propertySize: number;
      maxCapacity: number;
    };
    id: number;
    location: {
      address: string;
      city: string;
      country: string;
      id: number;
      latitude: string;
      longitude: string;
      streetName: string;
    };
    mediaId: number;
    propertyPrice: {
      billingPeriod: string;
      id: number;
      price: number;
    };
    propertyStatus: string;
    listingType?: string;
    propertyTitle: string;
    propertyType: string;
    tags: any[];
    typeOfWorkspace: string;
    userId: number;
    zone: string;
  }): Promise<ApiResponse> {
    return this.api.put(`/booking/api/v1/listings/${listingId}`, payload);
  }

  public async getListingById(id: number): Promise<ApiResponse> {
    return this.api.get(`/booking/api/v1/listings/by/id/${id}`);
  }

  // Add these methods inside the ApiService class

  public async searchListings(payload: {
    cost?: number;
    capacity?: number;
    location?: { match?: string; selectedLocation?: string };
    propertyType?: string;
    subCategory?: string;
    amenities?: string[];
    start?: string;
    end?: string;
    zone?: string;
    userId?: number;
  }, params?: { page?: number; userId?: number }): Promise<ApiResponse> {
    return this.api.post('/booking/api/v1/listings/search', payload, { params });
  }

  public async getSupportedZones(): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/listings/supported/zones');
  }

  public async searchLocations(search: string): Promise<ApiResponse<LocationSearchResponse>> {
    return this.api.get('/booking/api/v1/location/search', {
      params: { search }
    });
  }

  public async createBooking(payload: {
    userId: number;
    listingId: number;
    startOn: string;
    endOn: string;
  }): Promise<ApiResponse> {
    return this.api.post('/booking/api/v1/booking', payload);
  }

  public async addToCart(payload: {
    endOn: string;
    itemPrice: number;
    listingId: number;
    quantity: number;
    startOn: string;
    subTotal: number;
    userId: number;
  }, params: { cartId?: string }): Promise<ApiResponse> {
    return this.api.post('/booking/api/v1/order/add-to-cart', payload, { params });
  }

  public async createOrderForListingPayment(payload: {
    listingId: number;
    startDate: string;
    endDate: string;
    userId: number;
  }): Promise<ApiResponse> {
    return this.api.post('/booking/api/v1/order/create/for/listing/payment', payload);
  }

  public async getCustomerAccounts(params: {
    userId: number;
    customerId: number;
  }): Promise<ApiResponse> {
    return this.api.get('/users/v1/customer/accounts', { params });
  }

  public async getCustomerAccountTransactions(accountId: number, params: {
    userId: number;
    customerId: number;
    startAt: string;
    endAt: string;
  }): Promise<ApiResponse> {
    return this.api.get(`/users/v1/customer/account/${accountId}/transactions`, { params });
  }

  public async createListingMedia(payload: {
    description: string;
    files: File;
    title: string;
    userId: number;
  }): Promise<ApiResponse> {
    return this.api.post('/api/v1/listings/media', payload);
  }

  public async createListingMediaWithFormData(formData: FormData, userId: number): Promise<ApiResponse> {
    return this.api.post('/booking/api/v1/listings/media', formData, {
      headers: { "content-type": "multipart/form-data" },
      params: { userId }
    });
  }

  public async updateListingMediaWithFormData(mediaId: number, formData: FormData, userId: number): Promise<ApiResponse> {
    return this.api.put(`/booking/api/v1/listings/media/${mediaId}`, formData, {
      headers: { "content-type": "multipart/form-data" },
      params: { userId }
    });
  }

  public async getAllListings(params: {
    status?: string;
    page?: number;
    search?: string;
  }): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/listings', { params });
  }

  public async getBlockedListingDates(listingId: number): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/block-listings', {
      params: { listingId }
    });
  }

  public async getListingDetails(id: number): Promise<ApiResponse> {
    return this.api.get(`/booking/api/v1/listings/by/id/${id}`);
  }

  public async deleteListing(id: number, userId: number): Promise<ApiResponse> {
    return this.api.delete(`/booking/api/v1/listings/${id}`, {
      params: { userId }
    });
  }

  public async unpublishListing(id: number, userId: number): Promise<ApiResponse> {
    return this.api.patch(`/booking/api/v1/listings/${id}/unpublish`, null, {
      params: { userId }
    });
  }

  public async publishListing(id: number, userId: number): Promise<ApiResponse> {
    return this.api.put(`/booking/api/v1/listings/${id}/publish`, null, {
      params: { userId }
    });
  }

  public async getRegulatoryCompliance(companyId: string | number, userId: string | number): Promise<ApiResponse> {
    return this.api.get(`/platform/company/v1/company/${companyId}/regulatory/compliance`, {
      params: { userId }
    });
  }

  public async createBlockedListing(payload: {
    listingId: number;
    startOn: string;
    endOn: string;
  }): Promise<ApiResponse> {
    return this.api.post('/booking/api/v1/block-listings', payload);
  }

  public async deleteBlockedListing(fakeBookingId: string | number): Promise<ApiResponse> {
    return this.api.delete(`/booking/api/v1/block-listings/${fakeBookingId}`);
  }

  // Add these methods to ApiService class

  public async getBookingsReceivedCount(params: {
    propertyOwnerId: number;
  }): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/booking/received/count', { params });
  }

  public async getTotalRevenue(userId: number): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/booking/total/revenue', {
      params: { userId }
    });
  }

  public async getActiveUsersCount(userId: number): Promise<ApiResponse> {
    return this.api.get(`/users/api/v1/users/${userId}/sub-accounts/count`, {
      params: { enabled: true }
    });
  }

  public async getInactiveUsersCount(userId: number): Promise<ApiResponse> {
    return this.api.get(`/users/api/v1/users/${userId}/sub-accounts/count`, {
      params: { enabled: false }
    });
  }

  public async getPendingUsers(ownerId: number): Promise<ApiResponse> {
    return this.api.get(`/users/api/v1/users/${ownerId}/sub-accounts`, {
      params: { sortBy: "createdDate", verified: false }
    });
  }

  public async getCancelledBookings(params: {
    hostId: number;
    status: string;
    from: string;
    to: string;
  }): Promise<ApiResponse> {
    return this.api.get('/reporting/api/v1/admin/bookings/daily/per/host', { params });
  }

  // Add these additional methods to ApiService class

  public async getTotalPropertiesCount(userId: number): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/listings/total/count', {
      params: { userId }
    });
  }

  public async getPublishedPropertiesCount(userId: number): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/listings/total/published/count', {
      params: { userId }
    });
  }

  public async getUnpublishedPropertiesCount(ownerId: number): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/listings/total/unpublished/count', {
      params: { ownerId }
    });
  }

  public async getBookingsMadeCount(userId: number): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/booking/made/count', {
      params: { userId }
    });
  }

  public async getBookingsForToday(params: {
    hostId: number;
    from: string;
    to: string;
  }): Promise<ApiResponse> {
    return this.api.get('/reporting/api/v1/admin/bookings/daily/per/host', { params });
  }

  public async getBundleMetrics(ownerId: number): Promise<ApiResponse<BundleMetricsResponse>> {
    return this.api.get('/users/api/v1/bundle/metrics', {
      params: { ownerId }
    });
  }

  public async getSubAccounts(ownerId: number, params: {
    sortBy?: string;
    verified?: boolean;
    page?: number;
    search?: string;
  }): Promise<ApiResponse> {
    return this.api.get(`/users/api/v1/users/${ownerId}/sub-accounts`, { params });
  }

  public async inviteUsers(payload: InviteUserPayload): Promise<ApiResponse> {
    return this.api.post('/users/api/v1/users/invite', payload);
  }

  public async bulkInviteUsers(formData: FormData): Promise<ApiResponse> {
    return this.api.post('/users/api/v1/users/invite/bulk', formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
  }

  public async deleteUserById(userId: number, ownerId: number): Promise<ApiResponse> {
    return this.api.delete(`/users/api/v1/users/delete-by-id/${userId}`, {
      params: { ownerId }
    });
  }

  public async updateUserStatus(userId: number, active: boolean, ownerId: number): Promise<ApiResponse> {
    return this.api.patch(`/users/api/v1/users/${userId}/status`, null, {
      params: { active, ownerId }
    });
  }

  public async resetUserPassword(username: string): Promise<ApiResponse> {
    return this.api.post(`/users/api/v1/users/reset-password`, null, {
      params: { username: encodeURIComponent(username) }
    });
  }

  public async submitEnquiry(enquiry: Enquiry): Promise<ApiResponse> {
    return this.api.post('/platform/company/api/v1/enquiry', enquiry);
  }

  public async subscribeToNewsletter(email: string): Promise<ApiResponse> {
    return this.api.post('/platform/company/api/v1/newsletter/subscribe', { email });
  }

  public async savePassword(payload: SavePasswordPayload): Promise<ApiResponse> {
    return this.api.post('/users/api/v1/users/save-password', payload);
  }

  public async assignBundle(payload: BundleAssignPayload): Promise<ApiResponse> {
    return this.api.post('/users/api/v1/bundle/package/assign/or/remove', payload, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  public async getAnnouncements(page: number, size: number): Promise<ApiResponse> {
    return this.api.get(`/messaging/api/v1/push-notifications`, {
      params: { page, size }
    });
  }

  public async getSupportedRoles(): Promise<ApiResponse<{ data: Role[] }>> {
    return this.api.get('/users/api/v1/users/supported-roles');
  }

  public async createAnnouncement(payload: AnnouncementPayload): Promise<ApiResponse> {
    return this.api.post('/messaging/api/v1/push-notifications/add', payload);
  }

  public async updateAnnouncement(id: number, payload: AnnouncementUpdatePayload): Promise<ApiResponse> {
    return this.api.put(`/messaging/api/v1/push-notifications/${id}`, payload);
  }

  public async searchUsers(searchTerm: string): Promise<ApiResponse> {
    return this.api.get('/messaging/api/v1/push-notifications/users/search', {
      params: { searchTerm: encodeURIComponent(searchTerm) }
    });
  }

  public async googleLogin(payload: {
    accessToken: string;
    companyId: number;
    provider: string;
  }): Promise<ApiResponse> {
    return this.publicApi.post('/users/api/v1/utils/exchange-token', payload, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  public async updateUserProfileWithFCM(userId: number, payload: UserProfileUpdateWithFCM): Promise<ApiResponse> {
    return this.api.put(`/users/api/v1/users/${userId}`, payload);
  }

  public async checkInOrOut(bookingId: number, userId: number, username: string): Promise<CheckInOutResponse> {
    const stringed = `userId=${userId}&username=${username}`;
    const encodedUser = btoa(stringed);
    
    return this.api.get(`/booking/booking/${bookingId}/checkInOrOut`, {
      params: { loggedInUser: encodedUser }
    });
  }

  public async makeImmediatePaymentBooking(payload: {
    userId: number;
    listingId: number;
    startOn: string;
    endOn: string;
    orderId: number;
  }): Promise<ApiResponse> {
    return this.api.post('/booking/api/v1/booking/make/immediate/payment', payload);
  }

  public async updateCompanyStatus(companyIds: string, userId: number, enable: boolean, declineReason?: string): Promise<ApiResponse> {
    return this.api.patch('/platform/company/v1/company/enable', null, {
      params: { 
        companyIds, 
        userId, 
        enable,
        ...(declineReason ? { declineReason } : {})
      }
    });
  }

  public async getRevenueShareRate(companyId: number, userId: number): Promise<ApiResponse> {
    return this.api.get(`/users/v1/settings/revenue/share/by/corporate/customer/company/${companyId}`, {
      params: { userId }
    });
  }

  public async deleteRevenueShareRate(rateId: number, userId: number): Promise<ApiResponse> {
    return this.api.delete(`/users/v1/settings/revenue/share/default/${rateId}`, {
      params: { userId }
    });
  }

  public async createRevenueShareRate(payload: {
    companyId: number;
    rate: number;
    userId: number;
    corporateCustomerIds: number[];
  }): Promise<ApiResponse> {
    return this.api.post('/users/v1/settings/revenue/share', payload);
  }

  public async getApprovedHosts(page: number, search?: string): Promise<ApiResponse> {
    return this.api.get('/platform/company/v1/company/corporate/customer/status', {
      params: { enabled: true, page, search }
    });
  }

  public async getPendingHosts(page: number, search?: string): Promise<ApiResponse> {
    return this.api.get('/platform/company/v1/company/corporate/customer/status', {
      params: { enabled: false, page, search }
    });
  }

  public async getAllBookings(params: {
    sortBy: string;
    status: string;
    page?: number;
    search?: string;
  }): Promise<ApiResponse> {
    return this.api.get('/booking/api/v1/booking', { params });
  }

  // Add method to ApiService class
  public async getAllBundles(): Promise<BundleResponse> {
    return this.api.get('/users/api/v1/bundle/all');
  }

  // Add methods to ApiService class
  public async getNotifications(userId: number, page: number = 1, size: number = 220): Promise<ApiResponse<NotificationResponse>> {
    return this.api.get('/messaging/api/v1/push-notifications', {
      params: {
        page,
        size,
        userId
      }
    });
  }

  public async markNotificationAsRead(notificationId: string, userId: number): Promise<ApiResponse> {
    return this.api.patch(
      `/messaging/api/v1/push-notifications/${notificationId}/users/${userId}/read-status`,
      {},
      {
        params: { readStatus: true }
      }
    );
  }

  // Add method to ApiService class
  public async getCheckInActivities(ownerId: number): Promise<ApiResponse<{data: CheckInActivity[]}>> {
    return this.api.get('/booking/api/v1/check/in/activities', {
      params: { ownerId }
    });
  }

  // Add methods to ApiService class
  public async createBundlePurchaseOrder(bundleRequest: BundlePurchaseRequest): Promise<ApiResponse> {
    return this.api.post('/users/api/v1/bundle/purchase/order/summary', bundleRequest);
  }

  // Add method to ApiService class
  public async reportIssue(issueDetails: IssueDetails): Promise<ApiResponse> {
    return this.api.post('/platform/company/api/v1/issue/report', issueDetails);
  }

  public async getCompanyDetails(id: string, userId: string): Promise<ApiResponse> {
    return this.api.get(`${Constants.BASE_URL}/platform/company/v1/company/` + id + "?userId="+ userId);
  }

}

export const apiService = new ApiService();
export default apiService;