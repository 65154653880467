import { ToastContainer } from "react-toastify";
import { toAbsoluteUrl } from "../../../../layout/helpers";
import { Card } from "react-bootstrap";
import { UserFAQPayload as userFAQs } from "../../../../components/FAQs/UserFAQPayload";
import { v4 as uuid, v4 as uuidv4 } from "uuid";
import hostFAQs from "../../../../components/FAQs/HostFAQPayload";
import { CloseCircle, LogoFacebook, LogoInstagram, LogoTwitter } from "react-ionicons";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { User } from "../../../../utils/interfaces";
import SERVICES from '../../../../services';
import apiService from "../../../../services/apiService";
import { Bundle, Package, packageSelectData, UpdatedBundle } from "../../../../data/package-select-data";
import PackageSelectCard from "../../../../components/bundles/package-select-card";
import PackageSelectOffersCard from "../../../../components/bundles/package-select-offers-card";
import PricingCard from "../../../../components/bundles/pricing-card";

interface BundleResponse {
  data: {
    data: Bundle[];
    message?: string;
  };
  status: number;
}

const PricingPage = () => {
    const history = useHistory();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [msisdn, setMsisdn] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [isEmptyFirstname, setIsEmptyFirstname] = useState(false);
    const [isEmptyLastname, setIsEmptyLastname] = useState(false);
    const [isEmptyPhoneNumber, setIsEmptyPhoneNumber] = useState(false);
    const [isEmptyEmail, setIsEmptyEmail] = useState(false);
    const [isEmptyMessage, setIsEmptyMessage] = useState(false);

    const [bundlePackages, setBundlePackages] = useState<Array<Package>>([] as Array<Package>);
    const [bundles, setBundles] = useState<Array<Bundle>>([] as Array<Bundle>);
    const [updatedBundles, setUpdatedBundles] = useState<Array<UpdatedBundle>>([] as Array<UpdatedBundle>);

    // get all bundles on system
    useEffect(() => {
        apiService.getAllBundles()
            .then((res: BundleResponse) => {
                console.log('bundle response:', res.data);
                const response = res.data;
                const responseData = response?.data;

                setBundles(responseData);
            });
    }, []);

    useEffect(() => {
        if ((bundles && bundles.length > 0)
            && (bundlePackages && bundlePackages.length > 0)) {

            const updatedBundles = bundles.map(bundle => {
                const orig = bundlePackages.find(bundlePackage => bundlePackage.title === bundle.bundleType.label)!;

                return { ...bundle, packageFromInterface: orig };
            });

            console.log('updated bundles:', updatedBundles);
            setUpdatedBundles(updatedBundles);
        }
    }, [bundles, bundlePackages]);

    useEffect(() => {
        setBundlePackages(packageSelectData.packages);
    }, []);

    const isLoggedIn = (user: User) => {
        return user != null && Object.keys(user).length !== 0;
    };

    const redirectBasedOnRole = () => {
        const currentUser = SERVICES.getUser() as User;
        let destination = "/startBooking";

        if (!isLoggedIn(currentUser)) {
            destination = "/signin";
        } else {
            const role = currentUser.role;

            switch (role) {
                case 'ROLE_ADMINISTRATOR':
                    destination = "/dashboard";
                    break;

                case 'ROLE_SUBSCRIBER_OWNER':
                    destination = "/startBooking";
                    break;

                default:
                    destination = "/properties";
            }
        }

        return destination;
    };

    const goToLogin = () => {
        history.push('/signin');
    };

    const goToSignUp = () => {
        history.push('/signup');
    };

    const goToAboutPage = () => {
        history.push('/about');
    };

    return (
        <div id='appCapsule' className='pricing-page'>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{ minWidth: '350px' }}
            />
            <header style={{ minHeight: '90vh' }}>
                <div className="container top-compartment">
                    <div className="row align-items-center">
                        <div className="col">
                            <figure style={{ width: '75%' }}>
                                <img
                                    alt={"logo"}
                                    decoding={"async"}
                                    className={"w100 img-fluid"}
                                    src={toAbsoluteUrl("/img/logo-black.png")}
                                />
                            </figure>
                        </div>
                        <div className="col-8" style={{ paddingLeft: '10%' }}>
                            <div className="row rounded-pill bg-white w-75 align-items-center" style={{ height: '36px' }}>
                                <p className="col text-center text-black" style={{ paddingLeft: '50px' }}>Become A Host</p>
                                <p className="col text-center text-black">Find A Space</p>
                                <p className="col text-center text-black">FAQ</p>
                            </div>
                        </div>
                        <div className="col">

                        </div>
                    </div>
                </div>
                <div className="container bottom-compartment" style={{ marginTop: '80px' }}>
                    <div className='row'>
                        <div className="bg-white text-black d-inline-block" style={{ padding: '40px', borderRadius: '20px', width: '605px' }}>
                            <p className="lh-1" style={{ fontSize: '6.5rem', lineHeight: '116px' }}>
                                Simple Transparent <span className="font-weight-bolder">Pricing</span>
                            </p>
                        </div>
                    </div>
                </div>
            </header>
            <div className="container pricing-cards" style={{ paddingBottom: '150px' }}>
                {
                    updatedBundles && updatedBundles.map(bundle => {
                        const bundlePackage = bundle.packageFromInterface;
                        const offers = bundlePackage?.bundleOffer;

                        return (
                            <PricingCard key={uuid()} />
                        );
                    })
                }
            </div>
            <div className={"d-flex flex-column"}>
                <section
                    className={"section px-lg-5 mb-lg-5  px-md-5 pt-3 background-size-position row"}
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                            "/img/diverse-women-pc.jpeg"
                        )}),linear-gradient(89.83deg, rgb(255, 255, 255) 10.24%, rgb(255, 255, 255) 10.25%, rgba(255, 255, 255, 0) 99.55%)`,
                        height: "min(100vh,901px)"
                    }}
                >
                    <div
                        className={"col-lg-6 py-lg-5"}
                    // style={{ width: "clamp(350px,340px + 0.1vw , 384px)" }}
                    >
                        <h1
                            className={"text-start me-auto mb-lg-4"}
                            style={{
                                fontSize: "25px",
                                lineHeight: "53px",
                                // fontFamily: "Monserrat",
                                fontWeight: 500,
                            }}
                        >
                            Get in touch with our Sales Team
                        </h1>
                        <div className={"d-flex flex-row w-100 justify-content-between"}>
                            <div className="form-group basic  me-lg-3">
                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        // className="form-control floating-input form-label-contact"
                                        className={isEmptyFirstname ? "form-control is-invalid floating-input form-label-contact" : "form-control floating-input form-label-contact"}
                                        id="firstName"
                                        placeholder=" "
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                        style={{
                                            background: "#F9F9F9",
                                            border: "1px solid #E6E6E6",
                                        }}
                                    />
                                    <label style={{ color: "#737373", background: "rgba(0,0,0,0)" }} className="floating-label  form-label-contact" htmlFor="firstName">
                                        First Name
                                    </label>
                                    <i className="clear-input">
                                        <CloseCircle color={"#00000"} title={""} />
                                    </i>
                                    <div className="invalid-feedback">This field can't be empty</div>
                                </div>
                            </div>

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        className={isEmptyLastname ? "form-control is-invalid floating-input form-label-contact" : "form-control floating-input form-label-contact"}
                                        id="lastName"
                                        placeholder=" "
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                        style={{
                                            background: "#F9F9F9",
                                            border: "1px solid #E6E6E6",
                                        }}
                                    />
                                    <label style={{ color: "#737373", background: "rgba(0,0,0,0)" }} className="floating-label  form-label-contact" htmlFor="lastName">
                                        Last Name
                                    </label>
                                    <i className="clear-input">
                                        <CloseCircle color={"#00000"} title={""} />
                                    </i>
                                    <div className="invalid-feedback">This field can't be empty</div>
                                </div>
                            </div>
                        </div>
                        <div className={"d-flex flex-row w-100 justify-content-between"}>
                            <div className="form-group basic  me-lg-3 ">
                                <div className="input-wrapper">
                                    {/*<span className="did-input-group-prepend">*/}
                                    {/*	<div className="did-input-group-text">*/}
                                    {/*		<img*/}
                                    {/*			src={toAbsoluteUrl("/img/ghana.svg")}*/}
                                    {/*			alt={"gh"}*/}
                                    {/*			width={15}*/}
                                    {/*		/>{" "}*/}
                                    {/*		&nbsp; +233*/}
                                    {/*	</div>*/}
                                    {/*</span>*/}
                                    <input
                                        type="number"
                                        min={0}
                                        minLength={9}
                                        maxLength={10}
                                        className={isEmptyPhoneNumber ? "form-control is-invalid floating-input form-label-contact" : "form-control floating-input form-label-contact"}
                                        id="phone"
                                        placeholder=" "
                                        value={msisdn}
                                        onChange={e => setMsisdn(e.target.value)}
                                        style={{
                                            background: "#F9F9F9",
                                            border: "1px solid #E6E6E6",
                                        }}

                                    />
                                    <label style={{ background: "rgba(0,0,0,0)", color: "#737373" }} className="floating-label form-label-contact" htmlFor="phone">
                                        Phone Number
                                    </label>
                                    <i className="clear-input">
                                        <CloseCircle color={"#00000"} title={""} />
                                    </i>
                                    <div className="invalid-feedback">Kindly enter your phone number</div>
                                </div>
                                {/*<div className="input-info">e.g 555xxxxxx</div>*/}
                            </div>

                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <input
                                        type="email"
                                        className={isEmptyEmail ? "form-control is-invalid floating-input form-label-contact" : "form-control floating-input form-label-contact"}
                                        id="email1"
                                        placeholder=" "
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        style={{
                                            background: "#F9F9F9",
                                            border: "1px solid #E6E6E6",
                                        }}
                                    />
                                    <label style={{ background: "rgba(0,0,0,0)", color: "#737373" }} className="floating-label form-label-contact form-label-email" htmlFor="email1">
                                        E-mail
                                    </label>
                                    <i className="clear-input">
                                        <CloseCircle color={"#00000"} title={""} />
                                    </i>
                                    <div className="invalid-feedback">Please enter your email address</div>
                                </div>
                            </div>
                            {/*<div className="form-group basic">*/}
                            {/*  <div className="input-wrapper">*/}
                            {/*    <select*/}
                            {/*        className="form-label-contact form-label form-control floating-select"*/}
                            {/*        id={"availability"}*/}
                            {/*        style={{background: "#F9F9F9",*/}
                            {/*          border:"1px solid #E6E6E6",color: "#737373"}}*/}
                            {/*    >*/}
                            {/*      <option value="4:00 Available">4:00 Available</option>*/}
                            {/*    </select>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <textarea
                                    // className=" ps-1 pt-2 form-control floating-input form-label-contact"
                                    className={isEmptyMessage ? "ps-1 pt-2 form-control is-invalid floating-input form-label-contact" : "ps-1 pt-2 form-control floating-input form-label-contact"}
                                    id="message"
                                    placeholder=" "
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                    style={{
                                        background: "#F9F9F9", height: 140,
                                        border: "1px solid #E6E6E6",
                                    }}
                                />
                                <label style={{ background: "rgba(0,0,0,0)", color: "#737373" }} className="floating-label form-label-contact" htmlFor="message">
                                    Message
                                </label>
                                <i className="clear-input">
                                    <CloseCircle color={"#00000"} title={""} />
                                </i>
                                <div className="invalid-feedback">Message field cannot be empty</div>
                            </div>
                        </div>

                        <div className="mt-4 w-75 me-auto">
                            <button
                                type="button"
                                className={
                                    "btn btn-primary btn-block btn-lg active mb-4 w-50  me-auto"
                                }
                                style={{ borderRadius: "50rem" }}
                            >
                                Book Appointment
                            </button>
                        </div>
                    </div>
                    <div className={"col-lg-6"} />
                </section>
                <section
                    className={"section px-lg-5 px-md-5 px-sm-3 pt-5 pb-5 row"}
                    style={{ backgroundColor: "#2EC5CE" }}
                >
                    <div className={"col-lg-6"}>
                        <h2
                            className={"mt-4 pb-2 mb-4"}
                            style={{
                                fontSize: "48px",
                                color: 'black'
                            }}
                        >
                            Coming <span style={{ color: "white" }}>Soon</span>
                        </h2>

                        <p
                            className={"fontsize-heading pb-5 mb-5"}
                            style={{
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "18px",
                                lineHeight: "43px",
                                letterSpacing: "0.8px",
                                color: "#18191F"
                            }}
                        >
                            Search, compare & book flexible workspaces, accommodation and other
                            lifestyle services from the convenience of the Spacia app
                        </p>
                        <div
                            className={"me-auto"}
                        // style={{ width: "clamp(350px,340px + 0.1vw , 384px)" }}
                        >
                            <div className={"px-2"}>
                                <h3
                                    className={
                                        "fontsize-sub-footer fontsize-heading mb-2 mobile-show"
                                    }
                                    style={{ fontWeight: 500 }}
                                >
                                    Get the App
                                </h3>
                                <div
                                    className={"d-flex flex-row w-100 justify-content-start pb-5"}
                                >
                                    <button
                                        className={"btn me-1 py-1"}
                                        style={{ backgroundColor: "#000000", height: 44 }}
                                    >
                                        <div className={"d-flex flex-row align-items-center"}>
                                            <div
                                                className={
                                                    "imaged d-flex flex-row justify-content-center"
                                                }
                                            >
                                                <img
                                                    decoding={"async"}
                                                    className={"w16"}
                                                    alt={"g-play logo"}
                                                    src={toAbsoluteUrl("/img/google-play.png")}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    "d-flex flex-column ms-1 justify-content-between"
                                                }
                                            >
                                                <span
                                                    className={
                                                        "text-uppercase fs-10 fw-bold text-white"
                                                    }
                                                >
                                                    coming soon on
                                                </span>
                                                <span
                                                    className={"text-capitalize fontsize-normal text-white"}
                                                >
                                                    Google play
                                                </span>
                                            </div>
                                        </div>
                                    </button>
                                    <button
                                        className={"btn me-1 py-1"}
                                        style={{ backgroundColor: "#000000", height: 44 }}
                                    >
                                        <div className={"d-flex flex-row align-items-center"}>
                                            <img
                                                decoding={"async"}
                                                className={"w16"}
                                                alt={"g-play logo"}
                                                src={toAbsoluteUrl("/img/apple-logo.png")}
                                            />
                                            <div
                                                className={
                                                    "d-flex flex-column ms-1 justify-content-between"
                                                }
                                            >
                                                <span className={"text-white fs-10 fw-bold"}>
                                                    Coming Soon on the
                                                </span>
                                                <span
                                                    className={
                                                        "text-capitalize fw-bold fontsize-normal text-white"
                                                    }
                                                >
                                                    App Store
                                                </span>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            "col-lg-6 position-relative d-flex flex-row align-items-center mt-md-5"
                        }
                    >
                        <svg
                            width="232"
                            height="464"
                            className={"position-relative mb-lg-5 pb-lg-5 image-up"}

                        >
                            <image
                                xlinkHref={toAbsoluteUrl("/img/phone-mockup-1.svg")}
                                width="232"
                                height="464"
                            />
                        </svg>
                        <svg width="232" height="464">
                            <image
                                xlinkHref={toAbsoluteUrl("/img/phone-mockup-2.svg")}
                                width="232"
                                height="464"
                            />
                        </svg>
                    </div>
                </section>
                {/*Footer*/}
                <footer
                    className={"pt-5 pb-200 px-4 d-flex flex-row px-lg-5 flex-wrap"}
                    style={{ backgroundColor: "#000000", paddingBottom: "100px" }}
                >
                    <div
                        className={
                            "d-flex flex-row flex-wrap pt-lg-5 justify-content-between w-75"
                        }
                    >
                        <div className={"d-flex flex-column align-items-start"}>
                            <span
                                className={
                                    "text-capitalize fontsize-sub-footer text-start  fw-bold text-white my-2"
                                }
                            >
                                get started
                            </span>
                            <Link
                                to={redirectBasedOnRole()}
                                className={
                                    "text-white fontsize-sub-footer text-start text-capitalize my-2"
                                }
                            >
                                Find a space
                            </Link>
                            <Link
                                to={"/host"}
                                className={
                                    "text-white fontsize-sub-footer  text-start mb-5 text-capitalize"
                                }
                            >
                                Become A host
                            </Link>
                        </div>
                        <div className={"d-flex flex-column align-items-start"}>
                            <span
                                className={
                                    "text-capitalize fontsize-sub-footer text-start  fw-bold text-white my-lg-2"
                                }
                            >
                                Solutions
                            </span>
                            <span
                                className={
                                    "text-white fontsize-sub-footer text-start text-capitalize my-lg-2"
                                }
                            >
                                Enterprise
                            </span>
                            {/*<span*/}
                            {/*  className={*/}
                            {/*    "text-white fontsize-sub-footer  text-start mb-5 text-capitalize"*/}
                            {/*  }*/}
                            {/*>*/}
                            {/*  Become A host*/}
                            {/*</span>*/}
                        </div>
                        <div className={"d-flex flex-column align-items-start"}>
                            <span
                                className={
                                    "text-capitalize fontsize-sub-footer text-start  fw-bold text-white my-lg-2"
                                }
                            >
                                community
                            </span>
                            <a
                                className={
                                    "text-white fontsize-sub-footer text-start text-capitalize my-lg-2"
                                }
                                href="https://blog.spacia.africa"
                                target="_blank" rel='noopener noreferrer'>blog</a>
                            <Link
                                to={'/frequently-asked-questions'}
                                className={
                                    "text-white fontsize-sub-footer  text-start mb-lg-2  text-capitalize"
                                }
                            >
                                FAQ
                            </Link>
                            {/*<span*/}
                            {/*  className={*/}
                            {/*    "text-white fontsize-sub-footer  text-start mb-5 text-capitalize"*/}
                            {/*  }*/}
                            {/*>*/}
                            {/*  Become A host*/}
                            {/*</span>*/}
                        </div>
                        <div className={"d-flex flex-column align-items-start"}>
                            <span
                                className={
                                    "text-capitalize fontsize-sub-footer text-start  fw-bold text-white my-lg-2"
                                }
                            >
                                Help & Support
                            </span>
                            <span
                                className={
                                    "text-white fontsize-sub-footer  text-start mb-lg-2  text-capitalize"
                                }
                            >
                                terms & conditions
                            </span>
                            <span
                                className={
                                    "text-white fontsize-sub-footer  text-start mb-5 text-capitalize"
                                }
                            >
                                privacy policy
                            </span>
                        </div>
                    </div>
                    <div
                        className={
                            "imaged desktop-show mx-auto d-flex flex-row justify-content-center"
                        }
                    >
                        <img
                            decoding={"async"}
                            className={"mt-5 mx-auto"}
                            src={toAbsoluteUrl("/img/logo-black-alt.png")}
                            alt={"logo"}
                            style={{
                                width: "154px",
                                height: "154px"
                            }}
                        />
                    </div>
                    <div className={"d-flex flex-row mt-auto mb-3 w-100"}>
                        <div role="presentation" style={{ marginRight: '100px' }}>
                            <a
                                href="https://www.facebook.com/Spacia-Africa-185163956487562"
                                target='_blank' rel={'noreferrer noopener nofollow'}>
                                <LogoFacebook cssClasses={"me-3"} color={"#ffffff"} />
                            </a>
                            <a
                                href="https://twitter.com/SpaciaAfrica_"
                                target='_blank' rel={'noreferrer noopener nofollow'}>
                                <LogoTwitter cssClasses={"me-3"} color={"#ffffff"} />
                            </a>
                            <a
                                href="https://www.instagram.com/spaciaafrica/"
                                target='_blank' rel={'noreferrer noopener nofollow'}>
                                <LogoInstagram cssClasses={"me-3"} color={"#ffffff"} />
                            </a>
                        </div>
                        <div className={"d-flex justify-content-between"} style={{ flexBasis: '61%' }}>
                            <p className={'text-white'} style={{ fontSize: '1.03rem', fontWeight: 'bolder' }}>
                                The Address 15th Floor, Muthangari Dr
                            </p>
                            <p className={'text-white'} style={{ fontSize: '1.03rem', fontWeight: 'bolder' }}>
                                Email: <a href="mailto:sales@spacia.africa">sales@spacia.africa</a>
                            </p>
                            <p className={'text-white'} style={{ fontSize: '1.03rem', fontWeight: 'bolder' }}>
                                Phone: <a href="tel:+254732188105">+254 732 188 105</a>
                            </p>
                        </div>
                    </div>
                    <div
                        className={
                            "imaged mobile-show mx-auto d-flex flex-row justify-content-center"
                        }
                    >
                        <img
                            decoding={"async"}
                            className={"w140 mx-auto"}
                            src={toAbsoluteUrl("/img/logo-black-alt.png")}
                            alt={"logo"}
                        />
                    </div>
                    <div
                        className={
                            "d-flex flex-row my-4 border-1 dropdown-divider border-white w-100 mx-lg-4"
                        }
                    />
                    <span
                        style={{ color: "#D1D5DB" }}
                        className={"w-75 text-center fontsize-sub-footer mx-auto pb-5 mt-2"}
                    >
                        &copy; 2022 SPACIA. All rights reserved
                    </span>
                </footer>
            </div>
        </div>
    );
};

export default PricingPage;