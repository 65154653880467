// @ts-nocheck

/**
 * Project: spaces
 * File: Pending
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import React, {FormEvent, useEffect, useState} from "react";
import { SearchOutline} from "react-ionicons";
import Items  from '../../data/reservations'
import ReservationCard from "../../components/ReservationCard";
import {PageTitle} from "../../layout/PageData";
import SERVICES from "../../services";
import apiService from "../../services/apiService";
import {PendingApproval, User} from "../../utils/interfaces";
import {v4 as uuidv4} from "uuid";
import PendingApprovalsCard from "../../components/PendingApprovalsCard";
import {useHistory} from "react-router-dom";

const Pending: React.FC =(props) => {
    const [pendingApprovals, setPendingApprovals] = useState([] as Array<PendingApproval>);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageSize, setTotalPageSize] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    const history = useHistory();

    const isLoggedIn = (user: User) => {
        return user != null && Object.keys(user).length !== 0;
    }

    useEffect(() => {
        // redirect to sign in page when user is not logged in

        const user = SERVICES.getUser() as User;

        if (!isLoggedIn(user)) {
            history.push('/signin');
        }
    }, []);

    const declineChanges = () => {
        const orderIds = [] as number[];
        const currentUser = SERVICES.getUser();
        const id = currentUser.id;

        apiService.declineApprovals(id, orderIds.join(','))
            .then(res => {
                loadApprovals();
            }).finally(() => {
                // setShowDeclineModal(false)
                // setdeclinefeedbackModal(true)
            });
    }

    const [allApprovals, addToApprovals] = useState([]);

    const loadApprovals = () => {
        const currentUser = SERVICES.getUser() as User;

        if (currentUser != null) {
            const userRole = currentUser.role;
            const ownerOrOtherId = currentUser.inviterId || currentUser.id;
            const id = userRole === 'ROLE_SUBSCRIBER_INDIVIDUAL' ? currentUser.id : ownerOrOtherId;

            const params = {
                ownerId: id
            };

            const apiCall = userRole === 'ROLE_SUBSCRIBER_INDIVIDUAL' 
                ? apiService.getUserPendingApprovals(params)
                : apiService.getPendingApprovals(params);

            apiCall.then(res => {
                if (res.status === 200) {
                    console.log('total count:', res.data.totalCount);
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);
                    setPendingApprovals(res.data['data']);
                }
            });
        }
    };

    useEffect(loadApprovals, []);

    useEffect(() => {
        setCurrentPage(0);
    }, []);

    const changePage = (selected: number) => {
        console.log('selected page number is', selected);
        setCurrentPage(selected);

        const currentUser = SERVICES.getUser();
        const ownerOrOtherId = currentUser.inviterId || currentUser.id;
        const id = currentUser.role === 'ROLE_SUBSCRIBER_INDIVIDUAL' ? currentUser.id : ownerOrOtherId;

        const params = {
            ownerId: id,
            page: (selected - 1)
        };

        const apiCall = currentUser.role === 'ROLE_SUBSCRIBER_INDIVIDUAL'
            ? apiService.getUserPendingApprovals(params)
            : apiService.getPendingApprovals(params);

        apiCall.then(res => {
            if (res.status === 200) {
                setTotalPageSize(res.data.totalCount);
                setItemsPerPage(res.data.pageSize);
                setPendingApprovals(res.data['data']);
            }
        });
    }

    const performSearch = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const currentUser = SERVICES.getUser();
        const ownerOrOtherId = currentUser.inviterId || currentUser.id;
        const id = currentUser.role === 'ROLE_SUBSCRIBER_INDIVIDUAL' ? currentUser.id : ownerOrOtherId;

        const params = {
            ownerId: id,
            page: currentPage,
            search: searchValue
        };

        const apiCall = currentUser.role === 'ROLE_SUBSCRIBER_INDIVIDUAL'
            ? apiService.getUserPendingApprovals(params)
            : apiService.getPendingApprovals(params);

        apiCall.then(res => {
            if (res.status === 200) {
                setTotalPageSize(res.data.totalCount);
                setItemsPerPage(res.data.pageSize);
                setPendingApprovals(res.data['data']);
            }
        });
    }

    return (
        <div className='mobile-view' style={{background: "#FCFCFC"}}>
            <div className="section mt-5 p-3">
                <PageTitle>Pending Approvals</PageTitle>
                <div className="">
                    <form className="search-form" onSubmit={performSearch}>
                        <div className="form-group searchbox">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={'Search Pending Approvals'}
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                            />
                            <i className="input-icon">
                                <SearchOutline
                                />
                            </i>
                        </div>
                    </form>
                </div>
                <div className={'mt-5'}>
                    {
                        (Array.isArray(pendingApprovals) && pendingApprovals.length > 0)
                        && pendingApprovals.map(pendingApproval =>
                            <PendingApprovalsCard key={uuidv4()} pendingApproval={pendingApproval} />
                        )
                    }
                </div>
            </div>

        </div>
    )
}

export default Pending
