import { BehaviorSubject } from 'rxjs';
import apiService from '../../../services/apiService';

const companyDetailsSubject = new BehaviorSubject({});
const updateProfileSubject = new BehaviorSubject({});
const supportedCountriesSubject = new BehaviorSubject({});
const supportedBusinessDocumentsSubject = new BehaviorSubject({});
const regulatoryDocumentComplianceSubject = new BehaviorSubject({});
const uploadCompanyRegulatoryDocumentSubject = new BehaviorSubject({});
const deleteCompanyRegulatoryDocumentSubject = new BehaviorSubject({});

export const companyServiceRxJS = {
    companyDetailsSubject: companyDetailsSubject.asObservable(),
    updateProfileSubject: updateProfileSubject.asObservable(),
    supportedCountriesSubject: supportedCountriesSubject.asObservable(),
    supportedBusinessDocumentsSubject: supportedBusinessDocumentsSubject.asObservable(),
    regulatoryDocumentComplianceSubject: regulatoryDocumentComplianceSubject.asObservable(),
    uploadCompanyRegulatoryDocumentSubject: uploadCompanyRegulatoryDocumentSubject.asObservable(),
    deleteCompanyRegulatoryDocumentSubject: deleteCompanyRegulatoryDocumentSubject.asObservable(),
    getCompanyDetailsSubject,
    updateProfile,
    getSupportedCountries,
    getSupportedBusinessDocuments,
    getRegulatoryDocumentCompliance,
    uploadCompanyRegulatoryDocument,
    deleteCompanyRegulatoryDocument,
    resetCompanyDetailsSubject,
    resetGetCompanyDetailsSubject
};

function getCompanyDetailsSubject(id, userId) {
    if (id && userId) {
        apiService.getCompanyDetails(id, userId)
            .then(response => {
                companyDetailsSubject.next(response.data);
            })
            .catch(err => {
                console.log("error : " + err);
                let errStatus = err?.response?.status;
                errStatus = (errStatus === undefined) ? 400 : errStatus;
                const errorObj = { status: errStatus, message: err?.response?.data?.message };
                companyDetailsSubject.next(errorObj);
            });
    }
}

function updateProfile(userId, companyId, payload, token) {
    if (userId && companyId && payload) {
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        };

        apiService.updateCompanyProfile(companyId, payload, headers)
            .then(response => {
                updateProfileSubject.next(response.data);
            })
            .catch(err => {
                console.log("error : " + err);
                let status = err?.response?.status;
                status = (status === undefined) ? 400 : status;
                const error = { status: status, message: err?.response?.data?.message };
                updateProfileSubject.next(error);
            });
    }
}

function resetCompanyDetailsSubject() {
    companyDetailsSubject.next({});
}

function resetGetCompanyDetailsSubject() {
    updateProfileSubject.next({});
}

function getSupportedCountries(token) {
    const headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
    };

    apiService.getSupportedCountries(headers)
        .then(response => {
            supportedCountriesSubject.next(response.data);
        })
        .catch(err => {
            console.log("error : " + err);
            let errStatus = err?.response?.status;
            errStatus = (errStatus === undefined) ? 400 : errStatus;
            const errorObj = { status: errStatus, message: err?.response?.data?.message };
            supportedCountriesSubject.next(errorObj);
        });
}

function getSupportedBusinessDocuments(isRequired, userId) {
    apiService.getSupportedBusinessDocuments(isRequired, userId)
        .then(response => {
            supportedBusinessDocumentsSubject.next(response.data);
        })
        .catch(err => {
            console.error("Error getting supported business documents:", err);
            supportedBusinessDocumentsSubject.next({
                status: err?.response?.status || 400,
                message: err?.response?.data?.message
            });
        });
}

function getRegulatoryDocumentCompliance(companyId, userId) {
    apiService.getRegulatoryCompliance(companyId, userId)
        .then(response => {
            regulatoryDocumentComplianceSubject.next(response.data);
        })
        .catch(err => {
            console.error("Error getting regulatory compliance:", err);
            regulatoryDocumentComplianceSubject.next({
                status: err?.response?.status || 400,
                message: err?.response?.data?.message
            });
        });
}

function uploadCompanyRegulatoryDocument(userId, supportRegDocId, formData) {
    apiService.uploadCompanyRegulatoryDocument(userId, supportRegDocId, formData)
        .then(response => {
            uploadCompanyRegulatoryDocumentSubject.next(response.data);
        })
        .catch(err => {
            console.error("Error uploading regulatory document:", err);
            uploadCompanyRegulatoryDocumentSubject.next({
                status: err?.response?.status || 400,
                message: err?.response?.data?.message
            });
        });
}

function deleteCompanyRegulatoryDocument(id, userId) {
    apiService.deleteCompanyRegulatoryDocument(id, userId)
        .then(response => {
            deleteCompanyRegulatoryDocumentSubject.next(response.data);
        })
        .catch(err => {
            console.error("Error deleting regulatory document:", err);
            deleteCompanyRegulatoryDocumentSubject.next({
                status: err?.response?.status || 400,
                message: err?.response?.data?.message
            });
        });
}