import { toAbsoluteUrl } from "../../../../../../layout/helpers";
import React, { FormEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Constants } from "../../../../../../utils/constants";
import axios from "axios";
import { useDispatch } from "react-redux";
// import { persistUserForBundles } from "../../../../../../redux/actions/bundleActions";
import { Spinner } from "react-bootstrap";
import queryString from "query-string";
import { ErrorAlert } from "../../../../../../components/Settings/alerts/ErrorAlert";
import { toast, ToastContainer, cssTransition } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { colors } from "../../../../../../styles/index";
import { FaRegWindowClose } from "react-icons/fa";
import { CloseCircle } from "react-ionicons";
import { SnackbarProvider, useSnackbar , OptionsObject, closeSnackbar} from 'notistack';
import apiService from "../../../../../../services/apiService";

interface ApiError {
    response?: {
        data?: {
            errors?: Array<{ message: string }>;
        };
    };
}

const OTPConfirmation = () => {

    
    const [otpCode, setOtpCode] = useState("");
    const [username, setUsername] = useState("");
    const [requestCompleted, setRequestCompleted] = useState(true);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    
    // toast to show either success or error message
    
    useEffect(() => {
        // console.log('payload from package signup:', payload);
        
        const search = queryString.parse(location.search);
        const params = search ? search.payload : "";
        
        // @ts-ignore
        // const payload = JSON.parse(atob(params));
        const payload = atob(params);
        console.log("payload on otp confirmation page:", payload);
        const user = payload ? JSON.parse(payload) : null;
        const username = user ? user.username : "";
        setUsername(username);
        
        console.log("params:", payload);
    }, []);
    
    let localUrl = `${Constants.BASE_URL}/users/api/v1/users/otp`;
    
    const { enqueueSnackbar } = useSnackbar();

  const callErrorToast = (message: string) => {
        const options: OptionsObject = {
          variant: 'default',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 3000,
          action: (key) => (
            <div style={{ display: 'flex', alignItems: 'center', paddingTop:"20px", paddingRight:"20px", paddingBottom:"20px" , fontSize: "16px", minWidth: "300px", justifyContent: "space-between"}}>
              <span style={{ marginRight: '10px', color: 'red' }}>{message}</span>
                <FaRegWindowClose
                 onClick={() => {
                    // Close the snackbar using the key
                    closeSnackbar(key);
                  }}
                  style={{
                    width: '20px',
                    height: '20px',
                    fontSize: '20px',
                    color: 'red',
                    cursor: 'pointer',
                  }}
                />
            </div>
          ),
          style: {
            backgroundColor: '#FFDDDD', // Light red background
          },
        };
    
        enqueueSnackbar('', options);
      };
      
      

    const verifyAndCompleteRegistration = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setRequestCompleted(false);

        try {
            const response = await apiService.verifyOTP({ otp: otpCode, username });
            const user = response.data?.data?.[0] || {};

            // persist user in session for use in package selection page
            dispatch(persistUserForBundles(user));
            setRequestCompleted(true);
            history.push("/select-package");
        } catch (err) {
            const error = err as ApiError;
            const errors = error.response?.data?.errors || [];
            const message = errors.length > 0 ? errors[0].message : "";
            
            setRequestCompleted(true);
            setErrorModal(true);
            setErrorMsg(message);
            callErrorToast(message);
        }
    };

    return (
        <section className="otp-confirmation">
            <ToastContainer />
            <div>
                <figure style={{ width: "10%", marginBottom: "50px" }}>
                    <img
                        alt={"logo"}
                        decoding={"async"}
                        className={"w100 img-fluid"}
                        src={toAbsoluteUrl("/img/logo-black-alt.png")}
                    />
                </figure>
                <section className={"otp-form"}>
                    {/* <div className={"w-100"}>
                        <ErrorAlert
                            show={errorModal}
                            message={errorMsg || "Please try again"}
                            close={() => setErrorModal(false)}
                        />
                    </div> */}
                    <div>
                        <p
                            style={{
                                color: "#333333",
                                fontSize: "1.7rem",
                                paddingTop: "25px",
                                marginBottom: "20px",
                            }}
                        >
                            Verification code
                        </p>
                        <form onSubmit={verifyAndCompleteRegistration}>
                            <label style={{ marginBottom: "20px" }}>
                                Enter your email verification code <br />
                                <input
                                    type="text"
                                    className="form-control form-control-md border border-dark"
                                    autoComplete="off"
                                    value={otpCode}
                                    onChange={(e) => setOtpCode(e.target.value)}
                                />
                            </label>
                            <div className="sign-in-cta">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    disabled={!otpCode || otpCode.length < 4}
                                >
                                    Sign in
                                    <Spinner
                                        size="sm"
                                        animation="border"
                                        role="status"
                                        style={{
                                            display: !requestCompleted
                                                ? "inline-block"
                                                : "none",
                                            marginLeft: "10px",
                                            width: "15px",
                                            height: "15px",
                                        }}
                                    >
                                        <span className="visually-hidden"></span>
                                    </Spinner>{" "}
                                </button>
                            </div>
                            {/*<div style={{marginBottom: '40px'}}>*/}
                            {/*    <input type="checkbox"/> Remember me*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    <p style={{color: '#666666', marginBottom: '10px'}}>*/}
                            {/*        Resend Verification Code? <span style={{textDecoration: 'underline', color: 'black'}}>Click here</span>*/}
                            {/*    </p>*/}
                            {/*    <p style={{color: '#666666'}}>*/}
                            {/*        This page is protected by Google reCAPTCHA to ensure you're not a bot. <span style={{color:*/}
                            {/*        "black"}}>Learn more.</span>*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        </form>
                    </div>
                </section>
            </div>
        </section>
    );
};

export default OTPConfirmation;
function persistUserForBundles(user: any): any {
    throw new Error("Function not implemented.");
}

