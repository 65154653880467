import React, {useEffect, useState} from "react";

function HubspotEmbed() {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        loadScheduler();
    }, []);


    function loadScheduler() {
        const script = document.createElement("script")
        script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {setScriptLoaded(true)}
    }

    if (scriptLoaded) {
        return(<div>
            <iframe src="https://meetings-eu1.hubspot.com/spacia?embed=true" width="600" height="600"/>
        </div>)
    } else {
        return (
            <></>
        )
    }

}

export default HubspotEmbed
