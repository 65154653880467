import React, { useState, useEffect } from 'react';
import { Container, Table } from 'react-bootstrap';
import InformationModal from '../../components/informationModal';
import FeedbackModall from '../../components/FeedbackModall';
import check from '../../assets/img/Web/Spacia/check.png';
import axios from 'axios';
import Row from './PendingApprovalRow';
import { PendingApprovalStyles } from './PendingApprovalStyles';
import SERVICES from '../../services';
import { Constants } from '../../utils/constants';
import { v4 as uuidv4 } from 'uuid';
import ReactPaginate from "react-paginate";
import { resetTotalAmount, saveCartDetails, setCartEntries } from "../../redux/actions/dashboard";
import { FaSearch } from "react-icons/fa";
import Pagination from "../../components/Pagination";
import apiService from '../../services/apiService';

const EditBooking = () => {
    const [pendingApprovals, setPendingApprovals] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageSize, setTotalPageSize] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    // Accept Changes Modal
    const [showModal, setShowModal] = useState(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);


    // Decline Changes Modal
    const [showDeclineModal, setShowDeclineModal] = useState(false);

    const openDeclineModal = () => setShowDeclineModal(true);
    const closeDeclineModal = () => setShowDeclineModal(false);


    // Show Feedback M
    const [showFeedbackModal, setshowFeedbackModal] = useState(false);
    const [showApproveFeedbackModal, setshowApproveFeedbackModal] = useState(false);

    const openDeclineModalFeedback = () => {
        // { state ? setShowFeedbackModal(true) : setShowFeedbackModal(false) }
        setShowDeclineModal(false);
        setshowFeedbackModal(true);
    };
    const openAcceptModalFeedback = () => {
        const orderIds = pendingApprovals.filter(o => o.checked === true).map(o => o.orderId);
        const currentUser = SERVICES.getUser();
        const id = currentUser.id;

        apiService.confirmApprovals(id, orderIds.join(','))
            .then(res => {
                if (res.status === 200) {
                    const response = res.data.data;
                    const entryIds = response.map(value => value.cartEntryid);

                    setPendingApprovals(pendingApprovals.filter(approval =>
                        !entryIds.includes(approval.entryId)
                    ));

                    loadApprovals();
                    setshowFeedbackModal(true);

                    const bookingResponse = res.data.data;
                    const bookingIdsArray = bookingResponse.map(booking => booking.id);

                    // Send confirmation email
                    if (bookingIdsArray.length > 0) {
                        apiService.sendBookingConfirmationEmail(bookingIdsArray.join(','))
                            .then(res => console.log(res))
                            .catch(err => console.log(err));
                    }
                }
            }).finally(() => {
                setShowModal(false);
                setshowFeedbackModal(true);
            });
    };

    const [declinefeedbackModal, setdeclinefeedbackModal] = useState(false);

    const declineChanges = () => {
        const orderIds = pendingApprovals.filter(o => o.checked === true).map(o => o.orderId);
        const currentUser = SERVICES.getUser();
        const id = currentUser.id;

        apiService.declineApprovals(id, orderIds.join(','))
            .then(res => {
                setshowFeedbackModal(true);
                loadApprovals();
            }).finally(() => {
                setShowDeclineModal(false);
            });
    };



    const closeDeclineFeedbackModal = () => {

    };


    const closeApproveFeedbackModal = () => {
        setshowApproveFeedbackModal(false);
    };


    const closeFeedbackDeclineModal = () => setshowFeedbackModal(false);

    const [allApprovals, addToApprovals] = useState([]);

    const loadApprovals = () => {
        const currentUser = SERVICES.getUser();
        const ownerOrOtherId = currentUser.inviterId || currentUser.id;
        const id = currentUser.role === 'ROLE_SUBSCRIBER_INDIVIDUAL' ? currentUser.id : ownerOrOtherId;

        const apiCall = currentUser.role === 'ROLE_SUBSCRIBER_INDIVIDUAL'
            ? apiService.getUserPendingApprovals
            : apiService.getPendingApprovals;

        apiCall({ ownerId: id })
            .then(res => {
                if (res.status === 200) {
                    setTotalPageSize(res.data.totalCount);
                    setItemsPerPage(res.data.pageSize);
                    setPendingApprovals(res.data['data']);
                }
            });
    };

    useEffect(loadApprovals, []);

    useEffect(() => {
        setCurrentPage(0);
    }, []);

    // useEffect(() => {
    //     let all = [];
    //
    //     if (pendingApprovals) {
    //         console.log('pending approvals length:', pendingApprovals.length);
    //         // loop the outer array
    //         for (let i = 0; i < pendingApprovals.length; i++) {
    //             console.log('inside pending approvals loop');
    //             // get the size of the inner array
    //             const entries = pendingApprovals[i].cartEntries;
    //             const entriesLength = entries.length;
    //             console.log('the entries are', entries);
    //             // loop the inner array
    //             for (let j = 0; j < entriesLength; j++) {
    //                 console.log('about to add', entries[j]);
    //                 all = [...all, entries[j]];
    //             }
    //         }
    //
    //         addToApprovals(all);
    //     }
    // }, [pendingApprovals]);

    const pageCount = Math.ceil(totalPageSize / itemsPerPage);

    const changePage = (selected) => {
        setCurrentPage(selected);

        const currentUser = SERVICES.getUser();
        const ownerOrOtherId = currentUser.inviterId || currentUser.id;
        const id = currentUser.role === 'ROLE_SUBSCRIBER_INDIVIDUAL' ? currentUser.id : ownerOrOtherId;

        const apiCall = currentUser.role === 'ROLE_SUBSCRIBER_INDIVIDUAL'
            ? apiService.getUserPendingApprovals
            : apiService.getPendingApprovals;

        apiCall({
            ownerId: id,
            page: (selected - 1)
        }).then(res => {
            if (res.status === 200) {
                setTotalPageSize(res.data.totalCount);
                setItemsPerPage(res.data.pageSize);
                setPendingApprovals(res.data['data']);
            }
        });
    };

    const performSearch = (e) => {
        e.preventDefault();

        const currentUser = SERVICES.getUser();
        const ownerOrOtherId = currentUser.inviterId || currentUser.id;
        const id = currentUser.role === 'ROLE_SUBSCRIBER_INDIVIDUAL' ? currentUser.id : ownerOrOtherId;

        const apiCall = currentUser.role === 'ROLE_SUBSCRIBER_INDIVIDUAL'
            ? apiService.getUserPendingApprovals
            : apiService.getPendingApprovals;

        apiCall({
            ownerId: id,
            page: currentPage,
            search: searchValue
        }).then(res => {
            if (res.status === 200) {
                setTotalPageSize(res.data.totalCount);
                setItemsPerPage(res.data.pageSize);
                setPendingApprovals(res.data['data']);
            }
        });
    };


    return (
        <PendingApprovalStyles>
            <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <h4><b>Pending Approvals</b></h4>
                </div>
                <form className="form-inline my-2 my-xl-0" style={{ width: "35vw" }} onSubmit={performSearch}>
                    {/*<input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />*/}
                    <input style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, marginRight: '0!important' }} className="form-control mr-sm-2 search" placeholder='Search' type="search" aria-label="Search" value={searchValue} onChange={e => setSearchValue(e.target.value)} />
                    <button style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} className="btn btn-outline-dark my-2 my-sm-0" type="submit"><FaSearch /></button>
                </form>
                {/* <form class="form-inline my-2 my-xl-0" style={{width:"30vw"}}>
                    <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                </form>

                     <div class="dropdown" style={{margin:'auto 30px'}}>
                                                    <Dropdown>
                            <Dropdown.Toggle style={{backgroundColor:'#ECECEC', border:'none', color:'#848484'}} variant="success" id="dropdown-basic">
                                All Properties 
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                            </Dropdown>
                    </div> */}
                {SERVICES.getUser().role !== 'ROLE_SUBSCRIBER_INDIVIDUAL' &&
                    <div>

                        <button className="button-calm" onClick={openDeclineModal}>Decline</button>
                        <button className="button" style={{ padding: '10px 20px', width: '150px' }} onClick={openModal}>Approve </button>
                    </div>
                }

            </div>


            {/* Table */}
            <Container>
                <Table>
                    <tr>
                        <td><h6>Type Of Space</h6></td>
                        <td><h6>Description</h6></td>
                        <td><h6>Address</h6></td>
                        <td><h6>Start</h6></td>
                        <td><h6>End</h6></td>
                        <td><h6>User</h6></td>
                        <td><h6>Price</h6></td>
                        {SERVICES.getUser().role !== 'ROLE_SUBSCRIBER_INDIVIDUAL' &&
                            <td>
                                {/* <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            Check
                            <input class="form-check-input" type="checkbox" name="" id="" value="checkedValue" /> <h6>
                            </h6>
                        </label>
                    </div> */}<h6>Check</h6>
                            </td>
                        }
                    </tr>
                    {
                        Array.isArray(pendingApprovals) && pendingApprovals.length > 0 &&
                        pendingApprovals.map(row => {
                            console.log('row values:', row);
                            const owner = row.cartOwner;

                            const fullName = owner && `${owner.firstName} ${owner.lastName}`;

                            const property = row.itemInEntry;
                            const description = property && property.description;
                            const typeOfSpace = property && property.subCategory;

                            const location = (row.itemInEntry && row.itemInEntry.location) ? row.itemInEntry.location : '';
                            const address = location && location.address;

                            return <Row key={uuidv4()}
                                start={row.startOn}
                                end={row.endOn}
                                user={fullName}
                                typeOfSpace={typeOfSpace}
                                description={description}
                                toggleBooking={(e) => {
                                    pendingApprovals.find(o => o.entryId === row.entryId).checked = e.target.checked;
                                }}
                                address={address} price={row.subTotal} />;
                        })
                    }

                    {
                        Array.isArray(pendingApprovals) && pendingApprovals.length > 0 &&
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage || 1}
                            totalCount={totalPageSize}
                            pageSize={10}
                            onPageChange={changePage}
                        />
                        // <ReactPaginate
                        //     previousLabel={'<'}
                        //     nextLabel={'>'}
                        //     pageCount={pageCount}
                        //     marginPagesDisplayed={0}
                        //     pageRangeDisplayed={6}
                        //     onPageChange={changePage}
                        //     containerClassName={'whole-block'}
                        //     activeClassName={'paginate-active'}
                        //     disabledClassName={'paginate-disabled'}
                        //     previousLinkClassName={'previous-link'}
                        //     nextLinkClassName={'next-link'}/>
                    }

                    {/* <BookingTableRow /> */}


                    {/* Figured Out How To Pass Images As Propssssss */}


                    {/* <hr style={{width:'100%'}}></hr> */}


                </Table>
                {/* 
            <Modal show={show} onHide={handleClose}>
<Modal.Header closeButton>
<Modal.Title>Invite New Users</Modal.Title>
</Modal.Header>
<Modal.Body>
<div class="form-group">
<label for="">Email</label>
<input type="email" class="form-control" name="" id="" aria-describedby="emailHelpId" placeholder="" />
<br/>
 <label for="">Role</label>
 <select class="form-control" name="" id="">
   <option>Admin</option>
   <option>Employee</option>
   <option>Approver</option>
 </select>
<small id="emailHelpId" class="form-text text-muted">Help text</small>
</div>
</Modal.Body>
<Modal.Footer>
<button onClick={handleClose} className="button">Invite User</button>
</Modal.Footer>
</Modal> */}

            </Container>
            <InformationModal body="Are you sure you want to approve these requests?" title="Approve Pending Reservations" isOpen={showModal} isClose={closeModal} onSubmit={openAcceptModalFeedback} acceptButton="Approve" declineButton="Cancel" >
                <h6>Are you sure you want to approve these requests?</h6>
            </InformationModal>
            {/* Decline Changes Modal */}
            <InformationModal body="Are you sure you want to decline these requests?" title="Decline Pending Reservations" isOpen={showDeclineModal} isClose={closeDeclineModal} onSubmit={declineChanges} acceptButton="Decline" declineButton="Cancel">
                <h6>Are you sure you want to decline these requests?</h6>
            </InformationModal>

            {/* Feedback Modal */}
            <FeedbackModall body="Your requests have been implemented" title="Success" isOpen={showFeedbackModal} isClose={closeFeedbackDeclineModal} onSubmit={closeFeedbackDeclineModal} acceptButton="Okay" doneButton="Okay" declineButton="Cancel">
                <img src={check} alt="check" />
                <h6 style={{ paddingTop: 10 }}>Order approval completed successfully</h6>
            </FeedbackModall>

            <FeedbackModall body="Your requests have been implemented" title="Success" isOpen={declinefeedbackModal} isClose={() => setdeclinefeedbackModal(false)} onSubmit={declineChanges} acceptButton="Okay" doneButton="Okay" declineButton="Cancel">
                <img src={check} alt="check" />
                <h6 style={{ paddingTop: 10 }}>Order decline successfully completed</h6>
            </FeedbackModall>



        </PendingApprovalStyles>
    );
};

export default EditBooking;
