import React, { useState, useEffect } from "react";
import {
    Container,
    Toast,
    Form,
    Button,
    Table,
    Dropdown
} from "react-bootstrap";
import { FaEllipsisV } from "react-icons/fa";
import apiService from "../../../../services/apiService";
import SERVICES from '../../../../services';
import { ErrorAlert } from "../../../../components/Settings/alerts/ErrorAlert";
import SuccessAlert from "../../../../components/Settings/alerts/SuccessAlert";
import moment from 'moment';
import AnnouncementFormModal from "../../../../components/AnnouncementFormModal";
import { FaEllipsisH } from "react-icons/fa";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { TextField } from '@mui/material';
import Pagination from '../../../../components/Pagination';
import { Select, MenuItem, Chip, Box, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import EditAnnouncementModal from "../../../../components/EditAnnouncementModal";
import { Autocomplete } from '@mui/material';
import uploadActivityIndicator from '../../../../assets/img/loading.gif';

const Announcements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [newAnnouncement, setNewAnnouncement] = useState({
        title: '',
        message: '',
        selectedRoles: [],
        userIds: [],
        datetimeToSend: new Date(moment().add(1, 'hour').toDate())
    });
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [editingAnnouncement, setEditingAnnouncement] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const pageSize = 10;
    const [expandedRow, setExpandedRow] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [announcementToConfirm, setAnnouncementToConfirm] = useState(null);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    const menuItemStyle = {
        fontSize: '0.8125rem',
    };

    const checkboxStyle = {
        padding: 0,
        marginRight: 8,
        '& svg': {
            width: '1rem',
            height: '1rem',
        },
    };

    useEffect(() => {
        fetchAnnouncements();
        fetchRoles();
    }, [currentPage]);

    const fetchAnnouncements = () => {
        console.log("Fetching announcements...");
        apiService.getAnnouncements(currentPage + 1, pageSize)
            .then(response => {
                console.log("Raw response:", response);
                if (response.data && Array.isArray(response.data)) {
                    console.log("Parsed announcements:", response.data);
                    setAnnouncements(response.data);
                    // Assuming the API returns total count in headers or response
                    const totalCount = response.headers['x-total-count'] || response.data.length * 10; // Fallback calculation
                    setTotalPages(Math.ceil(totalCount / pageSize));
                } else {
                    console.error("Invalid response format:", response.data);
                    setErrorMessage("Invalid data received from server");
                }
            })
            .catch(error => {
                console.error("Error fetching announcements:", error);
                setErrorMessage("Error fetching announcements. Please try again later.");
            });
    };

    const fetchRoles = () => {
        console.log("Fetching roles...");
        apiService.getSupportedRoles()
            .then(response => {
                if (response.data && response.data.data) {
                    // Map the new role structure to what the component expects
                    const formattedRoles = response.data.data.map(role => ({
                        value: role.value,  // This is already in the correct format (e.g. "ROLE_ADMINISTRATOR")
                        label: role.name,   // Use the human-readable name (e.g. "Platform Administrator")
                        description: role.description // Keep description if needed
                    }));
                    setAvailableRoles(formattedRoles);
                }
            })
            .catch(error => {
                console.error("Error fetching roles:", error);
                setErrorMessage("Error fetching roles. Please try again later.");
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAnnouncement(prev => ({ ...prev, [name]: value }));
    };

    const handleRoleChange = (event) => {
        const {
            target: { value },
        } = event;
        setNewAnnouncement(prev => ({
            ...prev,
            selectedRoles: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const handleDeleteRole = (roleToDelete) => {
        setNewAnnouncement(prev => ({
            ...prev,
            selectedRoles: prev.selectedRoles.filter(role => role !== roleToDelete),
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const localTime = moment(newAnnouncement.datetimeToSend);

        setAnnouncementToConfirm({
            ...newAnnouncement,
            title: newAnnouncement.title,
            message: newAnnouncement.message,
            datetimeToSend: localTime.format('YYYY-MM-DDTHH:mm:ss')
        });
        setShowConfirmModal(true);
    };

    const handleConfirmSubmit = () => {
        console.log("Submitting new announcement...");
        // Keep the local time as is for display
        const displayTime = moment(announcementToConfirm.datetimeToSend);
        console.log("Selected local time:", displayTime.format('YYYY-MM-DD HH:mm:ss'));

        // Convert to UTC for the server
        const utcTime = moment(announcementToConfirm.datetimeToSend).utc();
        console.log("UTC time for server:", utcTime.format('YYYY-MM-DD HH:mm:ss'));

        const pushNotificationPayload = {
            title: announcementToConfirm.title,
            message: announcementToConfirm.message,
            datetimeToSend: utcTime.format('YYYY-MM-DDTHH:mm:ss'),
            actionType: "newShipmentRequest",
            data: {},
            hasCTA: true,
            token: "",
            topic: "all_users"
        };

        if (announcementToConfirm.selectedRoles.length > 0) {
            pushNotificationPayload.roles = announcementToConfirm.selectedRoles;
        }

        if (announcementToConfirm.userIds.length > 0) {
            pushNotificationPayload.userIds = announcementToConfirm.userIds.map(id => parseInt(id));
        }

        return apiService.createAnnouncement(pushNotificationPayload)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    setShowSuccessAlert(true);
                    setNewAnnouncement({
                        title: '',
                        message: '',
                        selectedRoles: [],
                        userIds: [],
                        datetimeToSend: new Date(moment().add(1, 'hour').toDate())
                    });
                    setSelectedUsers([]);
                    setSearchResults([]);
                    setSearchTerm('');
                    setShowConfirmModal(false);
                    fetchAnnouncements();
                }
                return response;
            })
            .catch(error => {
                console.error("Error sending announcement:", error);
                setErrorMessage(error.response?.data?.message || "Failed to send announcement");
                setShowErrorAlert(true);
                throw error;
            });
    };

    const handleEdit = (announcement) => {
        setEditingAnnouncement(announcement);
        setShowEditModal(true);
    };

    const handleUpdateAnnouncement = (formData) => {
        return apiService.updateAnnouncement(formData.id, {
            id: formData.id,
            title: formData.title,
            message: formData.message,
            datetimeToSend: formData.datetimeToSend
        })
            .then(response => {
                if (response.status === 200) {
                    setShowSuccessAlert(true);
                    fetchAnnouncements();
                }
                return response;
            })
            .catch(error => {
                console.error("Error updating announcement:", error);
                setErrorMessage("Failed to update announcement");
                setShowErrorAlert(true);
                throw error;
            });
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const truncateText = (text, maxLength) => {
        if (text && text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const formatCreatedAt = (createdAt) => {
        if (!createdAt) return 'N/A';
        const date = moment({
            year: createdAt.year,
            month: createdAt.monthValue - 1,
            date: createdAt.dayOfMonth,
            hour: createdAt.hour,
            minute: createdAt.minute,
            second: createdAt.second
        });
        return date.format('MMMM D, YYYY h:mm A');
    };

    const formatDateTime = (dateTime) => {
        if (!dateTime) return 'N/A';
        const date = moment.utc({
            year: dateTime.year,
            month: dateTime.monthValue - 1,
            date: dateTime.dayOfMonth,
            hour: dateTime.hour,
            minute: dateTime.minute,
            second: dateTime.second
        });
        return date.local().format('MMMM D, YYYY h:mm A');
    };

    const isTimeInFuture = (dateTime) => {
        if (!dateTime) return false;

        // Create a moment object from the datetime components
        const publishDate = moment.utc({
            year: dateTime.year,
            month: dateTime.monthValue - 1,  // Moment months are 0-based
            date: dateTime.dayOfMonth,
            hour: dateTime.hour,
            minute: dateTime.minute,
            second: dateTime.second
        });

        // Get current time in UTC
        const now = moment.utc();

        // Debug logs to help verify the comparison
        console.log('Publish date:', publishDate.format());
        console.log('Current time:', now.format());
        console.log('Is future?', publishDate.isAfter(now));

        return publishDate.isAfter(now);
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: 'black' }}
        >
            {children}
        </a>
    ));

    const handleDateTimeChange = (newValue) => {
        setNewAnnouncement(prev => ({
            ...prev,
            datetimeToSend: newValue
        }));
    };

    // When displaying selected roles in chips
    const getDisplayLabel = (roleValue) => {
        const role = availableRoles.find(r => r.value === roleValue);
        return role ? role.label : roleValue;
    };

    const searchUsers = async (term) => {
        if (!term || term.length < 2) {
            setSearchResults([]);
            return;
        }
        
        setIsSearching(true);
        try {
            const response = await apiService.searchUsers(term);
            if (Array.isArray(response.data)) {
                const filteredResults = response.data.filter(user => 
                    !selectedUsers.some(selectedUser => selectedUser.id === user.id)
                );
                setSearchResults(filteredResults);
            }
        } catch (error) {
            console.error('Error searching users:', error);
            setSearchResults([]);
        } finally {
            setIsSearching(false);
        }
    };

    const handleDeleteUser = (userToDelete) => {
        setNewAnnouncement(prev => ({
            ...prev,
            userIds: prev.userIds.filter(id => id !== userToDelete.id.toString())
        }));
        setSelectedUsers(prev => prev.filter(user => user.id !== userToDelete.id));
    };

    return (
        <div>
            <SuccessAlert show={showSuccessAlert} message="Operation successful" close={() => setShowSuccessAlert(false)} />
            <ErrorAlert show={showErrorAlert} message={errorMessage} close={() => setShowErrorAlert(false)} />
            <div className="header">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                    <div>
                        <h4>
                            <b>Announcements</b>
                        </h4>
                    </div>
                </div>
            </div>

            <Container>
                <Toast style={{ width: "100%", padding: 20, marginBottom: 50, maxWidth: '100%' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 20
                    }}>
                        <h6 style={{ fontWeight: "bold", margin: 0 }}>
                            Create New Announcement
                        </h6>
                        <a
                            href="#recent-announcements"
                            style={{
                                color: '#007bff',
                                textDecoration: 'none',
                                fontSize: '0.9rem'
                            }}
                        >
                            Jump to Recent Announcements →
                        </a>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={newAnnouncement.title}
                                onChange={handleInputChange}
                                placeholder="Enter announcement title"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="message"
                                value={newAnnouncement.message}
                                onChange={handleInputChange}
                                placeholder="Enter announcement message"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Roles</Form.Label>
                            <Select
                                multiple
                                value={newAnnouncement.selectedRoles}
                                onChange={handleRoleChange}
                                input={<OutlinedInput />}
                                renderValue={(selected) => selected.length > 0 ? 'Roles selected' : 'Select roles'}
                                style={{
                                    width: '100%',
                                    backgroundColor: '#ECECEC',
                                    borderRadius: '4px',
                                    height: '38px',
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5 + 8,
                                            width: 250,
                                        },
                                    },
                                }}
                            >
                                {availableRoles.map((role) => (
                                    <MenuItem key={role.value} value={role.value} style={menuItemStyle}>
                                        <Checkbox
                                            checked={newAnnouncement.selectedRoles.indexOf(role.value) > -1}
                                            style={checkboxStyle}
                                        />
                                        <ListItemText
                                            primary={role.label}
                                            primaryTypographyProps={{ style: menuItemStyle }}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, marginTop: 1 }}>
                                {newAnnouncement.selectedRoles.map((value) => (
                                    <Chip
                                        key={value}
                                        label={getDisplayLabel(value)}
                                        onDelete={() => handleDeleteRole(value)}
                                        style={{
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            margin: '2px',
                                            fontSize: '0.8125rem',
                                        }}
                                    />
                                ))}
                            </Box>
                            <Form.Text className="text-muted">
                                Select one or more roles for the announcement
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Search Users (Optional)</Form.Label>
                            <Autocomplete
                                multiple
                                options={searchResults}
                                getOptionLabel={(option) => 
                                    option ? `${option.firstName} ${option.lastname} (${option.email})` : ''
                                }
                                value={selectedUsers}
                                onChange={(event, newValue) => {
                                    setSelectedUsers(newValue);
                                    setNewAnnouncement(prev => ({
                                        ...prev,
                                        userIds: newValue.map(user => user.id.toString())
                                    }));
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setSearchTerm(newInputValue);
                                    searchUsers(newInputValue);
                                }}
                                renderTags={() => null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={selectedUsers.length === 0 ? "Search by email, name..." : ""}
                                        fullWidth
                                        style={{
                                            backgroundColor: "#ECECEC",
                                            borderRadius: "4px",
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: { 
                                                height: '38px',
                                                padding: '0 14px', // padding to align cursor
                                            },
                                            endAdornment: (
                                                <>
                                                    {isSearching && (
                                                        <img 
                                                            src={uploadActivityIndicator} 
                                                            alt="Searching..." 
                                                            style={{ width: 20, height: 20, marginRight: 8 }}
                                                        />
                                                    )}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                noOptionsText="No users found"
                                loading={isSearching}
                                loadingText="Searching..."
                                style={{ height: '38px' }}
                            />
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, marginTop: 1 }}>
                                {selectedUsers.map((user) => (
                                    <Chip
                                        key={user.id}
                                        label={`${user.firstName} ${user.lastname}`}
                                        onDelete={() => handleDeleteUser(user)}
                                        style={{
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            margin: '2px',
                                            fontSize: '0.8125rem',
                                        }}
                                    />
                                ))}
                            </Box>
                            <Form.Text className="text-muted">
                                Search and select specific users to send this announcement to
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            {/* <Form.Label>Date and Time to Send</Form.Label> */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    label="Select Date and Time to Send"
                                    value={newAnnouncement.datetimeToSend}
                                    onChange={handleDateTimeChange}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            style={{
                                                backgroundColor: "#ECECEC",
                                                borderRadius: "4px",
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#DDDDDD',
                                                    },
                                                },
                                            }}
                                        />
                                    }
                                    minDateTime={new Date()}
                                />
                            </LocalizationProvider>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Send Announcement
                        </Button>
                    </Form>
                </Toast>

                <Toast id="recent-announcements" style={{ width: "100%", padding: 20, marginBottom: 50, maxWidth: '100%' }}>
                    <h6 style={{ fontWeight: "bold", marginBottom: 20 }}>
                        Recent Announcements
                    </h6>
                    <Table striped bordered hover responsive>
                        <colgroup>
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '35%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Message</th>
                                <th>Created At</th>
                                <th>Time to Publish</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {announcements.map((announcement, index) => (
                                <tr key={announcement.id}>
                                    <td
                                        onMouseEnter={() => setExpandedRow(index)}
                                        onMouseLeave={() => setExpandedRow(null)}
                                        style={{
                                            cursor: 'pointer',
                                            maxWidth: '150px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: expandedRow === index ? 'normal' : 'nowrap',
                                            transition: 'all 0.3s ease'
                                        }}
                                    >
                                        {expandedRow === index ? announcement.title : truncateText(announcement.title, 30)}
                                    </td>
                                    <td
                                        onMouseEnter={() => setExpandedRow(index)}
                                        onMouseLeave={() => setExpandedRow(null)}
                                        style={{
                                            cursor: 'pointer',
                                            maxWidth: '300px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: expandedRow === index ? 'normal' : 'nowrap',
                                            transition: 'all 0.3s ease'
                                        }}
                                    >
                                        {expandedRow === index ? (announcement.body || 'No message') : truncateText(announcement.body || 'No message', 100)}
                                    </td>
                                    <td>{formatCreatedAt(announcement.createdAt)}</td>
                                    <td style={{
                                        color: isTimeInFuture(announcement.datetimeToSend) ? '#28a745' : '#dc3545',
                                        fontWeight: '500'
                                    }}>
                                        {formatDateTime(announcement.datetimeToSend)}
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} id={`dropdown-${announcement.id}`}>
                                                <FaEllipsisH style={{ color: 'black' }} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleEdit(announcement)}>
                                                    Edit
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage + 1}
                            totalCount={totalPages * pageSize}
                            pageSize={pageSize}
                            onPageChange={page => handlePageChange(page - 1)}
                        />
                    </div>
                </Toast>
            </Container>

            <EditAnnouncementModal
                isOpen={showEditModal}
                isClose={() => setShowEditModal(false)}
                onSubmit={handleUpdateAnnouncement}
                announcement={editingAnnouncement}
            />

            <AnnouncementFormModal
                isOpen={showConfirmModal}
                isClose={() => setShowConfirmModal(false)}
                onSubmit={handleConfirmSubmit}
                title="Confirm Announcement"
                declineButton="Cancel"
                acceptButton="Send"
                initialTitle={announcementToConfirm?.title}
                initialMessage={announcementToConfirm?.message}
                initialSelectedRoles={announcementToConfirm?.selectedRoles}
                initialUserIds={announcementToConfirm?.userIds}
                initialDatetimeToSend={announcementToConfirm?.datetimeToSend}
                readOnly={true}
                showRoles={announcementToConfirm?.selectedRoles?.length > 0}
                showUserIds={announcementToConfirm?.userIds?.length > 0}
                availableRoles={availableRoles}
                selectedUsers={selectedUsers}
            />
        </div>
    );
};

export default Announcements;
